import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { CallHistoryTable } from '../../../call-history-table/src';
import Input from '../../../Input';
import { Button } from '@material-ui/core';
import './style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    paddingBottom: '1.6rem !important',
  },
}));

function CallHistory(props) {
  const classes = useStyles();
  const [notes, setNotes] = useState('');

  const handleSubmit = () => {};

  return (
    <div className={'callhistory_main_div'}>
      <Typography className={classes.heading}>Notes</Typography>
      {props.applicationId === 8 && (
        <form onSubmit={handleSubmit}>
          <div className="notes_form_container">
            <div className="notes_form_input">
              <Input
                type="text"
                label="Add note"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                variant="outlined"
              />
            </div>
            <div className="notes_form_button">
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                onClick={() => setNotes('')}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
                // disabled={isSaveClicked}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      )}
      <CallHistoryTable
        rowInfo={props.rowInfo}
        isLogAdded={props.isLogAdded}
        applicationId={props.applicationId}
      />
    </div>
  );
}

export default CallHistory;
