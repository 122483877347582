import React from 'react';
import { Provider } from 'react-redux';
import store from '../../Redux/store';
import { PhysicianInfo } from './lib/index';

function app(props) {
  return (
    <Provider store={store}>
      <PhysicianInfo
        rowInfo={props.rowInfo}
        setRowInfo={props.setRowInfo}
        applicationId={props.applicationId}
      />
    </Provider>
  );
}

export const PhysicianInformation = app;
