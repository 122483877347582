import React, { useState, useEffect } from 'react';
// import { connect } from 'react-redux';
import './style.css';
import { Button, Grid, InputAdornment, Tooltip } from '@material-ui/core';
import PhoneFormat from '../../../PhoneFormat';
import Input from '../../../Input';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  coderList,
  getCoder,
  getCoderResponse,
  getProvider,
  mappingList,
  priorAuthList,
  providerSave,
} from '../../../Redux/CoderApis/main.actions';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Dropdown } from '../../../drop-down/src';
import { providerList } from '../../../Redux/Appointment/apt.actions';
import { toast } from 'react-hot-toast';
import ProviderSkeleton from './skeleton';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';

import MappingDialog from './MappingDialog';
import copy from 'copy-to-clipboard';
import { dateToyyymmdd } from '../../../helper-functions/src/lib/TimeDateTimestamp';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    paddingBottom: '1.6rem !important',
  },
}));

function PhysicianInformation(props) {
  // let provider = JSON.parse(localStorage.getItem('currentCoder'));
  const [provider, setProvider] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // console.log(provider);
  const [mode, setMode] = useState('view');
  const [form, setForms] = useState({
    clinicName: '',
    providerName: '',
    providerId: '',
    speciality: '',
    providerNpi: '',
    providerPhone: '',
    providerFax: '',
    groupNpi: '',
    taxId: '',
    locationAddress: '',
    locationCity: '',
    locationState: '',
    locationZip: '',
    locationType: '',
  });
  const [locationName, setLocationName] = useState('');
  const [locationId, setLocationId] = useState('');
  const [mapping, setMapping] = useState(false);
  const currentRow = JSON.parse(sessionStorage.getItem('currentRow'));
  const clinicId = currentRow.clinicId;

  const handleMapping = () => {
    let currentInfo = JSON.parse(localStorage.getItem('currentCoder'));
    setMapping(true);
    props.mappingList(currentInfo.patientDto.clinicId);
  };

  const handleProvider = (e) => {
    let providerId = e.target.value;
    let ob = provider.find((x) => x.id === providerId);
    setForms({
      ...form,
      providerName: ob.fullName,
      providerId: providerId,
      speciality: ob.speciality,
      providerNpi: ob.npi,
    });
  };

  const loadProvider = () => {
    props.providerList({
      start: 0,
      limit: 14,
      orderByParameter: 'fullName',
      orderBy: 'asc',
      clinicId: clinicId,
    });
    setMode('edit');
  };

  const handleSubmit = () => {
    let ob = provider.find((x) => x.id === form.providerId);
    let patientData = JSON.parse(sessionStorage.getItem('currentRow'));
    const patientName =
      patientData.patientFName +
      ' ' +
      patientData.patientMName +
      ' ' +
      patientData.patientLName;
    if (ob) {
      props.providerSave({
        info: {
          id: props.rowInfo.appointmentId
            ? props.rowInfo.appointmentId
            : props.rowInfo.visitId,
          data: {
            appointmentId: props.rowInfo.appointmentId
              ? props.rowInfo.appointmentId
              : 0,
            visitId: props.rowInfo.visitId ? props.rowInfo.visitId : 0,
            clinicId: clinicId,
            // clinicId: currentInfo.clinicId,
            locationId: locationId,
            locationName: locationName,
            patientId: props.rowInfo.patientId,
            patientName: patientName,
            providerId: ob.value,
            providerName: ob.fullName,
          },
        },
        applicationId: props.applicationId,
      });
    }
  };

  useEffect(() => {
    if (props.rowInfo) {
      setIsLoading(true);
      let data = props.rowInfo;
      props.getProvider({
        applicationId: props.applicationId,
        providerId: data.providerId,
        facilityId: data.facilityId,
      });
    }
  }, [props.rowInfo]);

  const updateCurrentSelection = (
    currentSelection,
    currentRowId,
    currentSelectionId,
    newProvider
  ) => {
    if (currentRowId === currentSelectionId) {
      currentSelection = {
        ...currentSelection,
        providerId: newProvider.id,
        providerFName: newProvider.firstName,
        providerMName: newProvider.middleName,
        providerLName: newProvider.lastName,
      };
    }
    console.log(currentSelection);
    sessionStorage.setItem(
      'currentSelection',
      JSON.stringify(currentSelection)
    );
  };

  useEffect(() => {
    if (
      props.coderData &&
      props.coderData.providerSaveResponse &&
      props.coderData.providerSaveResponse.response
    ) {
      let response = props.coderData.providerSaveResponse.response;
      let currentRow = JSON.parse(sessionStorage.getItem('currentRow'));
      let currentSelection = JSON.parse(
        sessionStorage.getItem('currentSelection')
      );
      if (response.responseCode === 0) {
        console.log('response:', response.data);
        console.log('rowInfo:', props.rowInfo);
        const newProvider = provider.find(
          (item) => item.id.toString() === response.data.id.toString()
        );
        console.log(newProvider);
        const newData = {
          providerId: newProvider.id,
          providerFName: newProvider.firstName,
          providerMName: newProvider.middleName,
          providerLName: newProvider.lastName,
        };
        props.setRowInfo({
          ...props.rowInfo,
          ...newData,
        });
        currentRow = {
          ...currentRow,
          ...newData,
        };
        sessionStorage.setItem('currentRow', JSON.stringify(currentRow));
        if (props.applicationId === 7 || props.applicationId === 8)
          updateCurrentSelection(
            currentSelection,
            currentRow.claimId,
            currentSelection.claimId,
            newProvider
          );
        if (props.applicationId === 4)
          updateCurrentSelection(
            currentSelection,
            currentRow.priorAuthId,
            currentSelection.priorAuthId,
            newProvider
          );
        toast.success('Physician details updated successfully');
      }
      const f = JSON.parse(localStorage.getItem('filterData'));
      const ownerId = localStorage.getItem('ownerId');
      if (f) {
        if (props.applicationId === 4) {
          props.priorAuthList({
            startDate: dateToyyymmdd(f.startDate),
            endDate: dateToyyymmdd(f.endDate),
            start: 0,
            limit: 50,
            ownerIds: ownerId.toString(),
          });
        }
      }
      setMode('view');
      props.coderData.providerSaveResponse = null;
    }
  }, [props.coderData.providerSaveResponse]);

  useEffect(() => {
    if (props.AppointmentData.providerList) {
      let Response = props.AppointmentData.providerList.response;
      if (Response.responseCode === 0) {
        let data = Response.data;
        console.log(data.results);
        let provider = [];
        data.results.forEach((x) => {
          provider.push({
            name: x.fullName,
            value: x.id,
            ...x,
          });
        });
        setProvider(provider);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppointmentData.providerList]);

  useEffect(() => {
    if (props.coderData.providerInfo && props.coderData.providerInfo.response) {
      setMode('view');
      let response = props.coderData.providerInfo.response;
      if (response.responseCode === 0) {
        setIsLoading(false);
        let data = response.data;
        let phyInfo = {
          clinicName: data.clinicName,
          providerName:
            data.providerFName +
            ' ' +
            data.providerMName +
            ' ' +
            data.providerLName,
          providerFName: data.providerFName,
          providerLName: data.providerLName,
          providerMName: data.providerMName,
          speciality: data.speciality,
          providerId: data.providerId,
          providerNpi: data.providerNpi,
          providerPhone: data.clinicPhone,
          providerFax: data.providerFax,
          groupNpi: data.groupNpi,
          locationName: data.locationName,
          taxId: data.taxId,
          locationAddress: data.locationAddress,
          locationCity: data.locationCity,
          locationState: data.locationState,
          locationZip: data.locationZip,
          locationType: data.locationType,
        };
        // setPhysicianInfo(phyInfo);
        setForms(phyInfo);
        setLocationName(data.locationName ? data.locationName : '');
        setLocationId(data.locationId ? data.locationId : '');
        // localStorage.setItem('currentCoder', JSON.stringify(data));
        // localStorage.setItem('claimId', data.claimId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.providerInfo]);

  const classes = useStyles();
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>
      {isLoading ? (
        <ProviderSkeleton />
      ) : (
        <div className={'physician_info_main'}>
          <MappingDialog open={mapping} close={() => setMapping(false)} />
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography className={classes.heading}>
                Physician Information
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <div className="physician_button">
                {mode === 'view' && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={loadProvider}
                  >
                    Edit
                  </Button>
                )}
                {mode === 'edit' && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
                {mode === 'edit' && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setMode('view')}
                  >
                    Cancel
                  </Button>
                )}
                {/* <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleMapping}
                >
                  External Mapping
                </Button> */}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              {/* <Input
                className="input_material_field disabled_section"
                required
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                type="text"
                label="Clinic Name"
                margin="normal"
                value={form.clinicName}
              /> */}
              <div className="input_field_view_mode">
                <span className="label">Clinic Name</span>
                <div className="input_field_content_wrap">
                  <Tooltip title={form.clinicName}>
                    <span className="input_field_content">
                      {form.clinicName}
                    </span>
                  </Tooltip>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              {mode === 'view' && (
                <div className="input_field_view_mode">
                  <span className="label">Physician Name</span>
                  <div className="input_field_content_wrap">
                    <span className="input_field_content">
                      {`${form.providerFName ? form.providerFName : ''} ${
                        form.providerMName ? form.providerMName : ''
                      }
                       ${form.providerLName ? form.providerLName : ''}`}
                    </span>
                  </div>
                </div>
              )}
              {mode === 'edit' && (
                <Dropdown
                  options={provider}
                  label="Provider"
                  variant="outlined"
                  value={form.providerId}
                  onChange={handleProvider}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {/* <Input
                className="input_material_field disabled_section"
                required
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                type="text"
                label="Specialty"
                margin="normal"
                value={form.speciality}
              /> */}
              <div className="input_field_view_mode">
                <span className="label">Speciality</span>
                <div
                  style={{ cursor: 'pointer' }}
                  className="input_field_content_wrap"
                >
                  <Tooltip title={form.speciality}>
                    <span className="input_field_content">
                      {form.speciality}
                    </span>
                  </Tooltip>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="input_field_view_mode">
                <span className="label">Provider NPI</span>
                <div className="input_field_content_wrap">
                  <span className="input_field_content">
                    {form.providerNpi}
                  </span>
                  <span
                    style={{
                      height: '25px',
                    }}
                  >
                    {form.providerNpi && (
                      <Tooltip title="Click here to Copy">
                        <FileCopyIcon
                          color="secondary"
                          fontSize="small"
                          className="copyIcon"
                          onClick={() => copy(form.providerNpi)}
                        />
                      </Tooltip>
                    )}
                  </span>
                </div>
              </div>
              {/* <Input
                className="input_material_field disabled_section"
                required
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Click here to Copy">
                        <FileCopyIcon
                          className="copyIcon"
                          onClick={() => copy(form.providerNpi)}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                type="text"
                label="Provider NPI"
                margin="normal"
                value={form.providerNpi}
              /> */}
            </Grid>
            <Grid item xs={4}>
              {/* <PhoneFormat
                className="disabled_section"
                label="Phone Number"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                value={form.providerPhone}
              /> */}
              <div className="input_field_view_mode">
                <span className="label">Phone Number</span>
                <div className="input_field_content_wrap">
                  <span className="input_field_content">
                    {/* {form.providerName ? form.providerName : ''} */}
                    {`${form.providerPhone ? form.providerPhone : ''}`}
                  </span>
                </div>
              </div>
            </Grid>

            <Grid item xs={4}>
              <div className="input_field_view_mode">
                <span className="label">Clinic Fax</span>
                <div className="input_field_content_wrap">
                  <span className="input_field_content">
                    {/* {form.providerName ? form.providerName : ''} */}
                    {`${form.providerFax ? form.providerFax : ''}`}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="input_field_view_mode">
                <span className="label">Group NPI</span>
                <div className="input_field_content_wrap">
                  <span className="input_field_content">{form.groupNpi}</span>
                  <span
                    style={{
                      height: '25px',
                    }}
                  >
                    {form.groupNpi && (
                      <Tooltip title="Click here to Copy">
                        <FileCopyIcon
                          className="copyIcon"
                          color="secondary"
                          fontSize="small"
                          onClick={() => copy(form.groupNpi)}
                        />
                      </Tooltip>
                    )}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="input_field_view_mode">
                <span className="label">Tax ID</span>
                <div className="input_field_content_wrap">
                  <span className="input_field_content">{form.taxId}</span>
                  <span
                    style={{
                      height: '25px',
                    }}
                  >
                    {form.taxId && (
                      <Tooltip title="Click here to Copy">
                        <FileCopyIcon
                          color="secondary"
                          fontSize="small"
                          className="copyIcon"
                          onClick={() => copy(form.taxId)}
                        />
                      </Tooltip>
                    )}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              {/* <Input
                className="input_material_field disabled_section"
                required
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Click here to Copy">
                        <FileCopyIcon
                          className="copyIcon"
                          onClick={() => copy(form.locationAddress)}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                type="text"
                label="Location Address"
                margin="normal"
                value={form.locationAddress}
              /> */}
              <div className="input_field_view_mode">
                <span className="label">Location Address</span>
                <div className="input_field_content_wrap">
                  <Tooltip title={form.locationAddress}>
                    <span className="input_field_content">
                      {form.locationAddress}
                    </span>
                  </Tooltip>
                  <span
                    style={{
                      height: '25px',
                    }}
                  >
                    {form.locationAddress && (
                      <Tooltip title="Click here to Copy">
                        <FileCopyIcon
                          color="secondary"
                          className="copyIcon"
                          fontSize='small'
                          onClick={() => copy(form.locationAddress)}
                        />
                      </Tooltip>
                    )}
                  </span>
                </div>
              </div>
            </Grid>

            <Grid item xs={4}>
              <div className="input_field_view_mode">
                <span className="label">City State Zip</span>
                <div className="input_field_content_wrap">
                  <span className="input_field_content">
                    {/* {form.providerName ? form.providerName : ''} */}
                    {`${form.locationCity ? form.locationCity : ''} ${
                      form.locationState ? form.locationState : ''
                    }
                       ${form.locationZip ? form.locationZip : ''}`}
                  </span>
                </div>
              </div>
            </Grid>

            <Grid item xs={4}>
              {/* <Input
                className="input_material_field disabled_section"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                type="text"
                label="Location"
                margin="normal"
                value={form.locationName}
              /> */}
              <div className="input_field_view_mode">
                <span className="label">Location</span>
                <div className="input_field_content_wrap">
                  <span className="input_field_content">
                    {/* {form.providerName ? form.providerName : ''} */}
                    {`${form.locationName ? form.locationName : ''}`}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              {/* <Input
                className="input_material_field disabled_section"
                required
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                type="text"
                label="Location Type"
                margin="normal"
                value={form.locationType}
              /> */}
              <div className="input_field_view_mode">
                <span className="label">Location Type</span>
                <div className="input_field_content_wrap">
                  <span className="input_field_content">
                    {/* {form.providerName ? form.providerName : ''} */}
                    {`${form.locationType ? form.locationType : ''}`}
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  coderData: state.coder,
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  getCoderResponse: (values) => dispatch(getCoderResponse(values)),
  getCoder: (values) => dispatch(getCoder(values)),
  coderList: (values) => dispatch(coderList(values)),
  priorAuthList: (values) => dispatch(priorAuthList(values)),
  mappingList: (values) => dispatch(mappingList(values)),
  providerSave: (values) => dispatch(providerSave(values)),
  getProvider: (values) => dispatch(getProvider(values)),
  providerList: (values) => dispatch(providerList(values)),
});

PhysicianInformation.propTypes = {
  getCoderResponse: PropTypes.func,
  getCoder: PropTypes.func,
  priorAuthList: PropTypes.func,
  coderList: PropTypes.func,
  mapingList: PropTypes.func,
  providerSave: PropTypes.func,
  getProvider: PropTypes.func,
  providerList: PropTypes.func,
  rowInfo: PropTypes.obj,
};

export const PhysicianInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhysicianInformation);
