import { InputBase, Toolbar, makeStyles } from '@material-ui/core';
// import { SearchIcon } from '@material-ui/icons/Search';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { searchPatient } from '../../../Redux/Appointment/apt.actions';
import { PropTypes } from 'prop-types';
import { dateTommddyyy } from '../../../helper-functions/src/lib/TimeDateTimestamp';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '2rem !important',
  },

  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
  },
  root: {
    flexGrow: 1,
  },
  search: {
    marginTop: '15px',
    border: '1px solid grey',
    position: 'relative',
    borderRadius: '50px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    color: 'black',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '25ch',
      },
    },
  },
}));

function PatientSearch(props) {
  const [search, setSearch] = useState([]);
  const [searchParam, setSearchParam] = useState('');
  const classes = useStyles();

  const searchPatient = (e) => {
    if (e.target.value.length > 2) {
      props.searchPatient({
        firstName: e.target.value,
        lastName: e.target.value,
        clinicId: '',
      });
    }
  };

  const handleChange = (e) => {
    setSearchParam(e.target.value);
    searchPatient(e);
  };

  // const searchSelect = (i, y) => {
  //   // console.log(y);
  //   if (y === null) {
  //     // props.setFilter({ ...forms });
  //   } else {
  //     // props.setFilter({
  //     //   ...forms,
  //     //   patientId: y.id,
  //     // });
  //   }
  // };

  useEffect(() => {
    if (props.AppointmentData.searchPatients) {
      let Response = props.AppointmentData.searchPatients.response;
      // console.log(Response.data);
      if (Response.responseCode === 0) {
        let searchList = Response.data.map((x) => {
          return {
            title:
              x.lastName +
              ', ' +
              x.firstName +
              ' ' +
              x.middleName +
              '(' +
              dateTommddyyy(x.dob) +
              ' - ' +
              x.clinicName.substring(0, 8) +
              ')',
            ...x,
          };
        });
        setSearch(searchList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppointmentData.searchPatients]);

  return (
    <div>
      <Toolbar style={{ border: 'none' }}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <div>
            <Autocomplete
              options={search}
              value={searchParam}
              // onBlur={() => setSearchParam('')}
              onChange={(e, y) => props.searchSelect(e, y)}
              getOptionLabel={(option) => option.title}
              style={{ width: 350 }}
              renderInput={(params) => {
                const { InputLabelProps, InputProps, ...rest } = params;
                return (
                  <InputBase
                    placeholder="Search…"
                    onChange={searchPatient}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    {...params.InputProps}
                    {...rest}
                  />
                );
              }}
            />
          </div>
        </div>
      </Toolbar>
    </div>
  );
}

const mapStateToProps = (state) => ({
  coderData: state.coder,
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  searchPatient: (values) => dispatch(searchPatient(values)),
});

PatientSearch.propTypes = {
  searchPatient: PropTypes.func,
};

export const SearchPatient = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientSearch);
