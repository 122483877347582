import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "Containers/NewLogin";
import DataTableContainer from "Containers/DataTableContainer";
import EditContainer from "Containers/EditContainer";
import { PrivateRoute } from "Utils/private-route";
import "./GlobalStyles.css";
import { Toaster } from "react-hot-toast";
function App() {
  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <BrowserRouter>
        <Switch>
          <PrivateRoute
            path={"/home"}
            name={"home"}
            component={DataTableContainer}
          />
          <PrivateRoute
            path={"/details"}
            name={"details"}
            component={EditContainer}
          />
          <Route path={"/login"} name={"login"} component={Login} />
          <Redirect from="/" to="/login" />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
