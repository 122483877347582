import { ofType } from 'redux-observable';
import { switchMap, map, takeUntil } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { ajax as UtilsAjax } from 'Utils';
import { objectToUrl } from 'Utils/ajax-utils';
import environment from '../../environments/environment';
import {
  BULK_UPDATE,
  LOG_INFO_SAVE,
  CPT_SAVE,
  CPT_SEARCH,
  DOWNLOAD,
  FILE_UPLOAD,
  GET_PRIORAUTH,
  ICD_SAVE,
  ICD_SEARCH,
  LOGIN,
  LOGIN_INFO,
  LOGLIST,
  PRIORAUTH_LIST,
  PRIORAUTH_SAVE,
  REQUEST_CANCEL,
  ROW_UPDATE,
  ICD_KEYWORD_SEARCH,
  CPT_DELETE,
  ICD_CHILD,
  PA_HISTORY,
  SEARCH_PAYOR,
  PATIENT_SAVE,
  POLICYHOLDER_SAVE,
  POLICY_SAVE,
  MAPPING_LIST,
  SAVE_MAPPING,
} from './prior.types';
import {
  bulkUpdateResponse,
  logInfoSaveResponse,
  cptSaveResponse,
  cptSearchResponse,
  fileDownloadResponse,
  fileUploadResponse,
  getPriorAuthResponse,
  icdSaveResponse,
  icdSearchResponse,
  loginInfoResponse,
  loginResponse,
  loglistResponse,
  priorAuthListResponse,
  priorAuthSaveRes,
  icdkeywordResponse,
  cptDeleteResponse,
  icdChildResponse,
  paHistoryRes,
  searchPayoRes,
  patientSaveRes,
  policyHolderSaveRes,
  policySaveRes,
  mappingListRes,
  saveMappingRes,
} from './prior.actions';
// import { Apis } from 'Redux/APIs'
const { baseUrl } = environment;
const { ClinicBaseUrl } = environment;
const PriorAuthEpic = {};

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example Name of the epic = (params) =>
 */

/**
 * Login
 * @description Calling the login API for a user to login
 * @param action$
 */
PriorAuthEpic.loginApi = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 4,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-clinic-service/v1/authentication/clinic/user/login`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => loginResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

// PriorAuthEpic.userSaveApi = (action$) =>
// action$.pipe(
//   ofType(SAVE_USER),
//   switchMap((action) =>
//   UtilsAjax({
//       url: `${baseUrl}/trillium-af-clinic-service/v1/user/`,
//       method: 'POST',
//       body: JSON.stringify(action.payload),
//     }).pipe(
//       map(
//         (response) => userSaveResponse(response),
//         takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
//       ),
//     ),
//   ),
// )

PriorAuthEpic.priorAuthListApi = (action$) =>
  action$.pipe(
    ofType(PRIORAUTH_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(
          `${ClinicBaseUrl}/trillium-internal-service/v1/priorAuth/list/range`,
          action.payload
        ),
        method: 'GET',
      }).pipe(
        map(
          (response) => priorAuthListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
PriorAuthEpic.getPriorAuthApi = (action$) =>
  action$.pipe(
    ofType(GET_PRIORAUTH),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/priorAuth/detail/${action.payload.apptId}?priorAuthId=${action.payload.priorAuthId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getPriorAuthResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
PriorAuthEpic.icdSearchApi = (action$) =>
  action$.pipe(
    ofType(ICD_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-clinic-service/v1/icd/icdcode/${action.payload.s}?clinicId=${action.payload.cid}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => icdSearchResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
PriorAuthEpic.icdSaveApi = (action$) =>
  action$.pipe(
    ofType(ICD_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-clinic-service/v1/icd/`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => icdSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

PriorAuthEpic.cptSearchApi = (action$) =>
  action$.pipe(
    ofType(CPT_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/cpt/cptcode/${action.payload.cptCode}?clinicId=${action.payload.clinicId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => cptSearchResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
PriorAuthEpic.logInfoSaveApi = (action$) =>
  action$.pipe(
    ofType(LOG_INFO_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/log/`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => logInfoSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.cptSaveApi = (action$) =>
  action$.pipe(
    ofType(CPT_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-clinic-service/v1/cliniccpt/`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => cptSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.priorAuthSaveApi = (action$) =>
  action$.pipe(
    ofType(PRIORAUTH_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/priorAuth/addTestProcedureDetails`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => priorAuthSaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.loginInfoApi = (action$) =>
  action$.pipe(
    ofType(LOGIN_INFO),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/priorAuth/cache?applicationId=4`,
        method: 'GET',
      }).pipe(
        map(
          (response) => loginInfoResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.fileUploadApi = (action$) =>
  action$.pipe(
    ofType(FILE_UPLOAD),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 2,
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/priorAuth/upload/${action.payload.priorAuthId}`,
        method: 'POST',
        body: action.payload.data,
      }).pipe(
        map(
          (response) => fileUploadResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.downloadFileApi = (action$) =>
  action$.pipe(
    ofType(DOWNLOAD),
    switchMap((action) =>
      ajax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/priorAuth/download?fileId=${action.payload.id}&fileName=${action.payload.name}`,
        method: 'GET',
        responseType: 'blob',
      }).pipe(
        map(
          (response) => fileDownloadResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

PriorAuthEpic.logListApi = (action$) =>
  action$.pipe(
    ofType(LOGLIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/log/list/${action.payload.appointmentId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => loglistResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

PriorAuthEpic.bulkUpdateApi = (action$) =>
  action$.pipe(
    ofType(BULK_UPDATE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/priorAuth/list`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => bulkUpdateResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.rowUpdateApi = (action$) =>
  action$.pipe(
    ofType(ROW_UPDATE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/priorAuth/list`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => bulkUpdateResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.icdKeywordSearchApi = (action$) =>
  action$.pipe(
    ofType(ICD_KEYWORD_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-clinic-service/v1/icd/search?searchField=%20&searchParam=${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => icdkeywordResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

PriorAuthEpic.cptDeleteApi = (action$) =>
  action$.pipe(
    ofType(CPT_DELETE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/priorAuth/cpt/${action.payload}`,
        method: 'DELETE',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => cptDeleteResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.icdChildApi = (action$) =>
  action$.pipe(
    ofType(ICD_CHILD),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-clinic-service/v1/icd/tree/search?parentId=${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => icdChildResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

PriorAuthEpic.paHistoryApi = (action$) =>
  action$.pipe(
    ofType(PA_HISTORY),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/priorAuth/list/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => paHistoryRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
PriorAuthEpic.searchPayorApi = (action$) =>
  action$.pipe(
    ofType(SEARCH_PAYOR),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-clinic-service/v1/payer/search/name?name=${action.payload.str}&clinicId=${action.payload.id}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => searchPayoRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
PriorAuthEpic.patientSaveApi = (action$) =>
  action$.pipe(
    ofType(PATIENT_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-clinic-service/v1/patient/`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => patientSaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.policyHolderSaveApi = (action$) =>
  action$.pipe(
    ofType(POLICYHOLDER_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-clinic-service/v1/policyHolder/`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => policyHolderSaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.policySaveApi = (action$) =>
  action$.pipe(
    ofType(POLICY_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-clinic-service/v1/policy/`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => policySaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
PriorAuthEpic.mappingListApi = (action$) =>
  action$.pipe(
    ofType(MAPPING_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/external/mapping/list/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => mappingListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
PriorAuthEpic.saveMappingApi = (action$) =>
  action$.pipe(
    ofType(SAVE_MAPPING),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/external/mapping/`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveMappingRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

export default PriorAuthEpic;
