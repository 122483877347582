import React from 'react';
import { PatientData } from './lib';
import { Provider } from 'react-redux';
import store from '../../Redux/store';
import Context from '../../context/AppContextProvider';
import { ContextProvider } from '../../context/contextProvider';

function app(props) {
  return (
    <Provider store={store}>
      <Context components={[ContextProvider]}>
        <PatientData
          applicationId={props.applicationId}
          rowInfo={props.rowInfo}
          setRowInfo={props.setRowInfo}
        />
      </Context>
    </Provider>
  );
}

export const PatientDetails = app;
