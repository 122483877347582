import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, styled } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  getPriorAuth,
  priorAuthList,
  setFilter,
} from 'Redux/PriorAuthApis/prior.actions';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import FilterIcon from '@material-ui/icons/FilterList';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Menu,
  TablePagination,
} from '@material-ui/core';
import { dateTommddyyy } from 'HelperFunctions/TimeDateTimestamp';
import { getCache, searchPatient } from 'Redux/Appointment/apt.actions';
import { PatientSearch } from '../../../../../libs/patient-search/src';
import { filterContext } from '../../context/contextProvider';
import './style.css';

/**
 * @fileOverview Manages Routing.
 * @component
 * @author Akhil Francis <akhil@tensaw.email>
 * @author Ridhik Govind <ridhik@tensaw.email>
 * @return {JSXElement}
 * @example
 * return(
 * <Header/>
 * )
 */

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '2rem !important',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
  },
  root: {
    flexGrow: 1,
  },
  search: {
    marginTop: '15px',
    border: '1px solid grey',
    position: 'relative',
    borderRadius: '50px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    color: 'black',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '25ch',
      },
    },
  },
}));
const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fill: `lightgray`,
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      fill: `#139696`,
    },
  },
}));
function Header(props) {
  const history = useHistory();
  const classes = useStyles();
  const [openedPopover, setOpenedPopover] = useState(false);
  const [search, setSearch] = useState([]);
  const [patientData, setPatientData] = useState('');
  const [patientInfo, setPatientInfo] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openFilter = Boolean(anchorEl);

  const { headCells, setHeadCells, selectedItem, setSelectedItem } =
    useContext(filterContext);
  const [forms, setForms] = useState({
    clinic: null,
    provider: null,
    status: [],
    result: [],
    startDate: new Date(),
    endDate: addDays(new Date(), 14),
    payorId: null,
    owner: [],
    dueDate: null,
    patientId: [],
  });

  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  useEffect(() => {
    if (props.rowInfo) {
      const apptHist = JSON.parse(sessionStorage.getItem('apptHistory'));
      let list = JSON.parse(localStorage.getItem('currentList'));
      if (list) {
        if (
          props.rowInfo?.priorAuthId?.toString() ===
          apptHist?.priorAuthId?.toString()
        ) {
          setPatientData(apptHist);
        } else {
          const id = list.find(
            (obj) =>
              obj.priorAuthId?.toString() ===
              props.rowInfo?.priorAuthId?.toString()
          );
          setPatientData(id);
        }
      }
    }
  }, [props.rowInfo]);

  const popoverAnchor = useRef(null);

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };
  const logOutUser = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  useEffect(() => {
    let newItem = [];
    Object.keys(selectedItem).forEach((x) => {
      headCells.forEach((j) => {
        if (x === j.id) {
          newItem.push({
            ...j,
            status: selectedItem[x],
          });
        }
      });
    });
    setHeadCells(newItem);
    newItem = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const searchPatient = (e) => {
    if (e.target.value.length > 2) {
      props.searchPatient({
        firstName: e.target.value,
        lastName: e.target.value,
        clinicId: '',
      });
    }
  };

  const handleFilterColumn = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterColumnClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.AppointmentData.searchPatients) {
      let Response = props.AppointmentData.searchPatients.response;
      if (Response.responseCode === 0) {
        let searchList = Response.data.map((x) => {
          return {
            title:
              x.lastName +
              ', ' +
              x.firstName +
              ' ' +
              x.middleName +
              '(' +
              dateTommddyyy(x.dob) +
              ' - ' +
              x.clinicName.substring(0, 8) +
              ')',
            ...x,
          };
        });
        setSearch(searchList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppointmentData.searchPatients]);

  const handleFilterCheckbox = (e) => {
    setSelectedItem({ ...selectedItem, [e.target.name]: e.target.checked });
  };

  const searchSelect = (i, y) => {
    if (y === null) {
      props.setFilter({ ...forms });
    } else {
      props.setFilter({
        ...forms,
        patientId: y.id,
      });
    }
  };

  useEffect(() => {
    if (props.priorAuthData.filterForm) {
      setForms({
        ...props.priorAuthData.filterForm,
      });
      if (props.priorAuthData.filterForm.clinic) {
        props.getCache(props.priorAuthData.filterForm.clinic);
      }
    }
  }, [props.priorAuthData.filterForm]);

  const findPrimaryInsurance = (policyList) => {
    const primary = policyList.find((policy) => policy.ranking === 1);
    return primary?.payerName ? primary?.payerName : '';
  };

  useEffect(() => {
    if (props.priorAuthData.priorAuthInfo) {
      let response = props.priorAuthData.priorAuthInfo.response;
      if (response.responseCode === 0) {
        let data = response.data;
        setPatientInfo(data);
      }
    }
  }, [props.priorAuthData.priorAuthInfo]);

  return (
    <div className="clinic__header">
      {props.backButton && (
        <div className="clinic__header--inner header-left">
          <div className="priorauth_header_info">
            <ul>
              <li>{'Edit Prior Auth'}</li>
            </ul>
            <div>{patientInfo && patientInfo.dos}</div>
            <div>
              <span>{patientInfo && ' | ' + patientInfo.patientName}</span>
              <span>{patientInfo && ' | ' + patientInfo.patientDob}</span>
            </div>
            <div>
              <span>{patientInfo && ' | ' + patientInfo.appointmentType}</span>
              <span>
                {props?.patientInfo?.payorName
                  ? ' | ' + props.patientInfo.payorName
                  : null}
              </span>
            </div>
          </div>
        </div>
      )}
      {props.backButton === false && (
        <div className="datatable_header">
          <div className="priorauth_header_info">
            <ul>
              <li>{props.name ? props.name : 'Prior Authorization'}</li>
              <li className="search_bar">
                <PatientSearch searchSelect={searchSelect} />
              </li>
            </ul>
          </div>
        </div>
      )}
      <div className="pagination">
        {props.backButton === false && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 50, 100]}
            component="div"
            count={props.totalCount}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
          />
        )}
      </div>
      {props.backButton === false && (
        <div className="column_filter_container">
          <div className="filterColumn_icon">
            <IconButton
              id="column-filter"
              aria-controls={openFilter ? 'column-menu' : undefined}
              aria-expanded={openFilter ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleFilterColumn}
            >
              <FilterIcon />
            </IconButton>
          </div>
          <Menu
            className="columnMenu"
            id="column-menu"
            MenuListProps={{
              'aria-labelledby': 'column-filter',
            }}
            anchorEl={anchorEl}
            open={openFilter}
            onClose={handleFilterColumnClose}
            PaperProps={{
              style: {
                width: '55ch',
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <FormGroup className="column_filter_container">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                  {headCells.map((x, i) => (
                    <Grid item xs={4} key={i}>
                      <FormControlLabel
                        className="column_filter_text"
                        control={
                          <GreenCheckbox size="small" color="secondary" />
                        }
                        onChange={(e) => handleFilterCheckbox(e)}
                        name={x.id}
                        label={x.label}
                        checked={x.status}
                        labelPlacement="end"
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </FormGroup>
          </Menu>
        </div>
      )}
      {props.backButton === false && (
        <div
          className="clinic__header--inner_log header-right"
          onMouseEnter={popoverEnter}
          onMouseLeave={popoverLeave}
        >
          <KeyboardArrowDownIcon color="primary" />
          <div
            className="clinic__profileCircle"
            ref={popoverAnchor}
            aria-owns="mouse-over-popover"
            aria-haspopup="true"
          >
            <span>PA</span>
          </div>
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.popoverContent,
            }}
            open={openedPopover}
            anchorEl={popoverAnchor.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              onMouseEnter: popoverEnter,
              onMouseLeave: popoverLeave,
            }}
          >
            <div className="clinicHeader__popover">
              <div className="clinicHeader__popover--name">Prior Auth</div>
              <div className="clinicHeader__popover--list">
                <div className="logout" onClick={() => logOutUser()}>
                  Logout
                </div>
              </div>
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  priorAuthData: state.priorAuth,
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  getPriorAuth: (values) => dispatch(getPriorAuth(values)),
  searchPatient: (values) => dispatch(searchPatient(values)),
  setFilter: (values) => dispatch(setFilter(values)),
  priorAuthList: (values) => dispatch(priorAuthList(values)),
  getCache: (values) => dispatch(getCache(values)),
});
Header.propTypes = {
  getPriorAuth: PropTypes.func,
  searchPatient: PropTypes.func,
  setFilter: PropTypes.func,
  priorAuthList: PropTypes.func,
  getCache: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
