import { combineReducers } from 'redux';
import mainReducer from './CoderApis/main.reducer';
import aptReducer from './Appointment/apt.reducer';
/** rootReducer
 * @fileOverview Combine  all reducer action
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 */

const rootReducer = combineReducers({
  coder: mainReducer,
  Appointment: aptReducer,
});

export default rootReducer;
