import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  logInfoSave,
  getPriorAuth,
  logInfoSaveResponse,
} from 'Redux/PriorAuthApis/prior.actions';
import PropTypes from 'prop-types';
import './style.css';
import { Button, Grid } from '@material-ui/core';
import Input from 'Components/Input';
import DobPicker from 'Components/dobPicker';
import PhoneFormat from 'Components/PhoneFormat';
import { makeStyles } from '@material-ui/core/styles';
import Dropdown from 'Components/Dropdown';
import Typography from '@material-ui/core/Typography';
import { dateToyyymmdd } from 'HelperFunctions/TimeDateTimestamp';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    // paddingBottom: '1.6rem !important',
  },
}));

function CallInfo(props) {
  // let currentInfo = JSON.parse(sessionStorage.getItem("currentPriorAuth"));
  const [status, setStatus] = useState([]);
  const [result, setResult] = useState([]);
  const [owner, setOwner] = useState([]);
  const [payorPortalList, setPayorPortalList] = useState([]);
  const [logTypeList, setLogTypeList] = useState([]);
  const [form, setForm] = useState({
    activityDate: new Date(),
    callRefNo: '',
    csrName: '',
    dueDate: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
    // dueDate: '',
    duration: 0,
    payorName: '',
    payorPortalId: 1,
    phoneNo: '',
    priorAuthId: 0,
    priorAuthNo: '',
    result: 5,
    status: 1,
    typeId: '1',
    staffName: '',
    staffUserId: '',
    staffProductivityCallerId: 0,
    staffProductivityOtherId: 0,
    notes: '',
  });
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    let currentInfo = JSON.parse(sessionStorage.getItem('currentRow'));
    props.logInfoSave({
      ...form,
      priorAuthId: currentInfo.priorAuthId,
      staffName: owner.find((x) => x.value === form.staffUserId)
        ? owner.find((x) => x.value === form.staffUserId).name
        : '',
      activityDate: dateToyyymmdd(form.activityDate),
      dueDate: dateToyyymmdd(form.dueDate),
      applicationId: 4,
    });
    sessionStorage.setItem('apptOpen', 1);
  };

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('cache'));
    let status = [];
    let result = [];
    let owner = [];
    let payorPortalList = [];
    let logTypeList = [];
    cache.priorAuthStatusDtoList.forEach((x) => {
      status.push({
        name: x.description,
        value: x.value,
      });
    });
    cache.priorAuthResultDtoList.forEach((x) => {
      result.push({
        name: x.description,
        value: x.value,
      });
    });
    cache.userDtoList.forEach((x) => {
      owner.push({
        name: x.fullName,
        value: x.userId,
      });
    });
    // Sort the owner array alphabetically by name
    owner.sort((a, b) => a.name.localeCompare(b.name));
    cache.payorPortalList.forEach((x) => {
      payorPortalList.push({
        name: x.description,
        value: x.value,
      });
    });
    cache.logTypeList.forEach((x) => {
      logTypeList.push({
        name: x.description,
        value: parseInt(x.value),
      });
    });
    setLogTypeList(logTypeList);
    setPayorPortalList(payorPortalList);
    setStatus(status);
    setResult(result);
    setOwner(owner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.priorAuthData.logInfoResponse) {
      let Response = props.priorAuthData.logInfoResponse.response;
      if (Response.responseCode === 0) {
        toast.success('Log Information Added');
        setForm({
          ...form,
          activityDate: new Date(),
          callRefNo: '',
          csrName: '',
          dueDate: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
          duration: 0,
          payorName: '',
          payorPortalId: 1,
          phoneNo: '',
          priorAuthId: 0,
          priorAuthNo: '',
          staffName: '',
          staffProductivityCallerId: 0,
          staffProductivityOtherId: 0,
          notes: '',
        });
        let f = props.priorAuthData.filterForm;

        let data = JSON.parse(sessionStorage.getItem('currentRow'));
        props.getPriorAuth({
          apptId: data.appointmentId,
          priorAuthId: data.priorAuthId,
        });
      }
      props.logInfoSaveResponse(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.logInfoResponse]);

  useEffect(() => {
    if (props.priorAuthData.priorAuthInfo) {
      let response = props.priorAuthData.priorAuthInfo.response;
      if (response.responseCode === 0) {
        const data = props.priorAuthData.priorAuthInfo.response.data;
        setForm({
          ...form,
          status: data.status,
          result: data.result,
          dueDate: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
          staffUserId: data.ownerId,
          priorAuthNo: data.priorAuthNo,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.priorAuthInfo]);

  return (
    <div className={'callinfo_main_div'}>
      <form onSubmit={handleSubmit}>
        <div className="log_header">
          <Typography className={classes.heading}>Log</Typography>
          <div className="callinfo_button">
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
            <Button variant="outlined" color="primary">
              Cancel
            </Button>
          </div>
        </div>
        <Grid container spacing={2} columns={16}>
          <Grid item xs={3}>
            <DobPicker
              required
              label="Date"
              value={form.activityDate}
              onChange={(e) =>
                setForm({ ...form, activityDate: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              label="Type"
              required
              variant="outlined"
              options={logTypeList}
              value={form.typeId}
              onChange={(e) => setForm({ ...form, typeId: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              className="input_material_field"
              variant="outlined"
              type="text"
              label="Payor"
              margin="normal"
              value={form.payorName}
              onChange={(e) => setForm({ ...form, payorName: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              className="input_material_field"
              variant="outlined"
              type="text"
              label="Payor CSR"
              margin="normal"
              value={form.csrName}
              onChange={(e) => setForm({ ...form, csrName: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              required={form.typeId === '1' ? true : false}
              variant="outlined"
              options={payorPortalList}
              label="Payor Portal"
              value={form.payorPortalId}
              onChange={(e) =>
                setForm({ ...form, payorPortalId: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={3}>
            <PhoneFormat
              label="Payor Phone"
              value={form.phoneNo}
              onChange={(e) => setForm({ ...form, phoneNo: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              className="input_material_field"
              variant="outlined"
              type="text"
              margin="normal"
              label="Case/Call Ref"
              value={form.callRefNo}
              onChange={(e) => setForm({ ...form, callRefNo: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              className="input_material_field"
              variant="outlined"
              type="text"
              margin="normal"
              label="Auth Number"
              value={form.priorAuthNo}
              onChange={(e) =>
                setForm({ ...form, priorAuthNo: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={2}>
            <Dropdown
              variant="outlined"
              // options={actions}
              label="Actions"
            />
          </Grid>
          <Grid item xs={2}>
            <Dropdown
              required
              variant="outlined"
              options={[{ name: 'No Status', value: '0' }, ...status]}
              label="Status"
              value={form.status}
              onChange={(e) => setForm({ ...form, status: e.target.value })}
            />
          </Grid>
          <Grid item xs={2}>
            <Dropdown
              required
              variant="outlined"
              options={[{ name: 'No Result', value: '0' }, ...result]}
              label="Result"
              value={form.result}
              onChange={(e) => setForm({ ...form, result: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <DobPicker
              required={form.status === '7' ? false : true}
              minDate={new Date()}
              label="Due Date"
              value={form.dueDate}
              onChange={(e) => setForm({ ...form, dueDate: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              required
              variant="outlined"
              options={[{ name: 'Unassigned', value: '0' }, ...owner]}
              label="Owner"
              value={form.staffUserId}
              onChange={(e) =>
                setForm({ ...form, staffUserId: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              label="Notes"
              multiline
              maxRows={4}
              variant="outlined"
              value={form.notes}
              onChange={(e) => setForm({ ...form, notes: e.target.value })}
            ></Input>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  priorAuthData: state.priorAuth,
});

const mapDispatchToProps = (dispatch) => ({
  logInfoSave: (values) => dispatch(logInfoSave(values)),
  getPriorAuth: (values) => dispatch(getPriorAuth(values)),
  logInfoSaveResponse: (values) => dispatch(logInfoSaveResponse(values)),
});
CallInfo.propTypes = {
  logInfoSave: PropTypes.func,
  getPriorAuth: PropTypes.func,
  logInfoSaveResponse: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(CallInfo);
