import React from 'react';
import { Provider } from 'react-redux';
import store from '../../Redux/store';
import CallHistory from './lib/call-history-table';

function app(props) {
  return (
    <Provider store={store}>
      <CallHistory
        rowInfo={props.rowInfo}
        applicationId={props.applicationId}
        isLogAdded={props.isLogAdded}
      />
    </Provider>
  );
}

export const CallHistoryTable = app;
