import React from 'react';
import { Provider } from 'react-redux';
import store from '../../Redux/store';
import { LeftTab } from './lib/patient-selector';

function app(props) {
  return (
    <Provider store={store}>
      <LeftTab
        totalCount={props.totalCount}
        setTotalCount={props.setTotalCount}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        setPage={props.setPage}
        isDrawerOpen={props.isDrawerOpen}
        setIsDrawerOpen={props.setIsDrawerOpen}
        handleChangePage={props.handleChangePage}
        handleChangeRowsPerPage={props.handleChangeRowsPerPage}
        rowData={props.rowData}
        setRowData={props.setRowData}
        applicationId={props.applicationId}
        isLogAdded={props.isLogAdded}
      />
    </Provider>
  );
}

export const PatientSelector = app;
