import React from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import "./style.css";

/**
 * @fileOverview Reusable Input field
 * @component
 * @author Akhil Francis <akhil@trillium-technologies.com>
 * @param {props} props will contain all the latest values for the autocomplete like value, onChange,Label
 * @return {JSXElement}
 * @example
 * return(
 * <Input/>
 * )
 */
function Input(props) {
  const handleInput = (event) => {
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <TextField
        tabIndex={props.tabIndex ? props.tabIndex : null}
        onChange={handleInput}
        type={props.type ? props.type : "text"}
        disabled={props.disabled ? props.disabled : false}
        label={props.placeholder ? props.placeholder : ""}
        {...props}
      />
    </FormControl>
  );
}
export default Input;
