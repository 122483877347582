import {
  Button,
  Dialog,
  IconButton,
  Popover,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import './style.css';
import { CloseRounded } from '@material-ui/icons';
import React from 'react';
export function ViewLedger(props) {
  return (
    <div>
      {/* <Dialog open={props.open} fullWidth={false} maxWidth="lg"> */}
      <Popover
        open={props.open}
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="close_btn">
          <span className="ledger_title">Ledger</span>
          <IconButton onClick={() => props.onClose(false)}>
            <CloseRounded />
          </IconButton>
        </div>
        {props.ledgerData ? (
          <div className="ledger_container">
            <TableContainer className="ledgerTableContainer">
              <TableHead>
                <TableRow>
                  <TableCell className="ledger_headCell">Created</TableCell>
                  <TableCell className="ledger_headCell">Posted</TableCell>
                  <TableCell className="ledger_headCell">Type</TableCell>
                  <TableCell className="ledger_headCell">Party</TableCell>
                  <TableCell className="ledger_headCell">Amount</TableCell>
                  <TableCell className="ledger_headCell">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.ledgerData.map((item) => (
                  <TableRow>
                    <TableCell className="ledger_tableCell">
                      {item.createdDate ? item.createdDate : ''}
                    </TableCell>
                    <TableCell className="ledger_tableCell">
                      {item.postedDate ? item.postedDate : ''}
                    </TableCell>
                    <TableCell className="ledger_tableCell">
                      {item.type ? item.type : ''}
                    </TableCell>
                    <TableCell className="ledger_tableCell">
                      {item.party ? item.party : ''}
                    </TableCell>
                    <TableCell className="ledger_tableCell">
                      {item.amount >= 0 ? '$' + item.amount : ''}
                    </TableCell>
                    <TableCell className="ledger_tableCell">
                      {item.status ? item.status : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
          </div>
        ) : null}
      </Popover>

      {/* </Dialog> */}
    </div>
  );
}
export default ViewLedger;
