export default function validateInfo(value, name,error) {
  let errors = error

  if (name === 'firstName') {
    if (value) {
      if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
        errors={...errors,firstName: 'Enter a valid Name'}
      } else {
        errors={...errors,firstName: ''}
      }
    } else {
      errors={...errors,firstName: ''}
    }
  }
  if (name === 'lastName') {
    if (value) {
      if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
        errors={...errors,lastName: 'Enter a valid Name'}
      } else {
        errors={...errors,lastName: ''}
      }
    } else {
      errors={...errors,lastName: ''}
    }
  }
  if (name === 'searchLastName') {
    if (value) {
      if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
        errors={...errors,searchLastName: 'Enter a valid Name'}
      } else {
        errors={...errors,searchLastName: ''}
      }
    } else {
      errors={...errors,searchLastName: ''}
    }
  }
  if (name === 'middleName') {
    if (value) {
      if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
        errors={...errors,middleName: 'Enter a valid Name'}
      } else {
        errors={...errors,middleName: ''}
      }
    } else {
      errors={...errors,middleName: ''}
    }
  }
  if (name === 'city') {
    if (value) {
      if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
        errors={...errors,city: 'Enter valid City'}
      } else {
        errors={...errors,city: ''}
      }
    } else {
      errors={...errors,city: ''}
    }
  }
  if (name === 'addressLine1') {
    if (value) {
      if (!/^[a-zA-Z-0-9][a-zA-Z-0-9 -'#&@,]+$/.test(value)) {
        errors={...errors,addressLine1: 'Enter valid Address'}
      } else {
        errors={...errors,addressLine1: ''}
      }
    } else {
      errors={...errors,addressLine1: ''}
    }
  }

  if (name === 'addressLine2') {
    if (value) {
      if (!/^[a-zA-Z-0-9][a-zA-Z-0-9 -'#&@,]+$/.test(value)) {
        errors={...errors,addressLine2: 'Enter valid Address'}
      } else {
        errors={...errors,addressLine2: ''}
      }
    } else {
      errors={...errors,addressLine2: ''}
    }
  }

  if (name === 'zip') {
    if (value) {
      if (!/^[0-9][0-9 -]+$/.test(value)) {
        errors={...errors,zip: 'Enter a valid Zip'}
      } else if (value.length > 10) {
        errors={...errors,zip: 'Max length is 10'}
      } else {
        errors={...errors,zip: ''}
      }
    } else {
      errors={...errors,zip: ''}
    }
  }
  if (name === 'mrn') {
    if (value) {
      if (!/^[a-zA-Z 0-9]+$/.test(value)) {
        errors.mrn = 'Enter a valid MRN'
      } else {
        errors.mrn = ''
      }
    } else {
      errors.mrn = ''
    }
  }
  if (name === 'email') {
    if (value) {
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value,
        )
      ) {
        errors={...errors,email: 'Enter a valid Email'}
      } else {
        errors={...errors,email: ''}
      }
    } else {
      errors={...errors,email: ''}
    }
  }
  if (name === 'ssn') {
    if (value) {
      if (!/^[0-9][0-9 -]+$/.test(value)) {
        errors={...errors,ssn: 'Enter a valid email'}
      } else {
        errors={...errors,ssn: ''}
      }
    } else {
      errors={...errors,ssn: ''}
    }
  }
  return errors
}
