import { Skeleton } from '@material-ui/lab';
import React from 'react';

function CallHistorySkeleton() {
  return (
    <div>
      <Skeleton height="30px" variant="text" />
      <Skeleton height="30px" variant="text" />
      <Skeleton height="30px" variant="text" />
      <Skeleton height="30px" variant="text" />
      <Skeleton height="30px" variant="text" />
      <Skeleton height="30px" variant="text" />
    </div>
  );
}

export default CallHistorySkeleton;
