import { ofType } from 'redux-observable';
import { switchMap, map, takeUntil } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { ajax as UtilsAjax } from '../../Utils';
import { objectToUrl } from '../../Utils/ajax-utils';
// import environment from '../../environments/environment';
import environment from '../../environments/environment';
import {
  BULK_UPDATE,
  LOG_INFO_SAVE,
  CPT_SAVE,
  CPT_SEARCH,
  DOWNLOAD,
  FILE_UPLOAD,
  GET_CODER,
  ICD_SAVE,
  ICD_SEARCH,
  LOGIN,
  LOGIN_INFO,
  LOGLIST,
  CODER_LIST,
  CODER_SAVE,
  REQUEST_CANCEL,
  ROW_UPDATE,
  ICD_KEYWORD_SEARCH,
  CPT_DELETE,
  ICD_CHILD,
  PA_HISTORY,
  SEARCH_PAYOR,
  PATIENT_SAVE,
  POLICYHOLDER_SAVE,
  POLICY_SAVE,
  GET_CODERS,
  LIST,
  GET_PATIENT,
  GET_PROVIDER,
  GET_CLAIM_LIST,
  CLAIM_DOWNLOAD,
  GET_PROCEDURE,
  GET_NOTES,
  GET_APPT_NOTES,
  GET_CODER_FILES_LIST,
  GET_ELIGIBILITY_FILES_LIST,
  GET_PRIORAUTH_FILES_LIST,
  GET_PAYMENT_SUMMARY,
  PROVIDER_SAVE_RES,
  PROVIDER_SAVE,
  MAPPING_LIST,
  SAVE_MAPPING,
  GET_CLAIM_SUMMARY,
  ICD_CODE_SEARCH,
  PRIORAUTH_LIST,
  WRITEOFF,
  GET_LEDGER,
  ARMGMT_LIST,
  ELIGIBILITY_LIST,
  CLAIM_PREVIEW,
  CPT_EDIT,
  ICD_EDIT,
  CLAIM_SUMMARY_SAVE,
} from './main.types';
import {
  bulkUpdateResponse,
  logInfoSaveResponse,
  cptSaveResponse,
  cptSearchResponse,
  fileDownloadResponse,
  fileUploadResponse,
  getCoderResponse,
  icdSaveResponse,
  icdSearchResponse,
  loginInfoResponse,
  loginResponse,
  loglistResponse,
  coderListResponse,
  coderSaveRes,
  icdkeywordResponse,
  cptDeleteResponse,
  icdChildResponse,
  paHistoryRes,
  searchPayoRes,
  patientSaveRes,
  policyHolderSaveRes,
  policySaveRes,
  getPatientResponse,
  getProviderResponse,
  getClaimListResponse,
  claimDownloadRes,
  getProcedureInfoResponse,
  getNotesResponse,
  getApptNotesResponse,
  getCoderFilesResponse,
  getEligiblityFilesResponse,
  getPriorAuthFilesResponse,
  getPaymentSummaryResponse,
  providerSaveResponse,
  procedureInfoSaveRes,
  mappingListRes,
  saveMappingRes,
  getClaimSummary,
  getClaimSummaryResponse,
  icdCodeResponse,
  priorAuthListResponse,
  writeOffResponse,
  arManagementListResponse,
  getLedgerResponse,
  eligibilityListResponse,
  getClaimPreviewResponse,
  cptEditResponse,
  editIcdResponse,
  claimSummarySave,
  claimSummarySaveResponse,
} from './main.actions';
import { Apis } from '../APIs';
// const { baseUrl } = environment;
const applicationId = localStorage.getItem('applicationId');
// const baseUrl =
// const { ClinicBaseUrl } = environment;
const MainEpic = {};

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example Name of the epic = (params) =>
 */

/**
 * Login
 * @description Calling the login API for a user to login
 * @param action$
 */

let baseUrl = '';
let ClinicBaseUrl = '';
export function configureLibrary({ url, clinicUrl }) {
  baseUrl = url;
  ClinicBaseUrl = clinicUrl;
}

MainEpic.loginApi = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 7,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/${Apis.coder_loginApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => loginResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

// PriorAuthEpic.userSaveApi = (action$) =>
// action$.pipe(
//   ofType(SAVE_USER),
//   switchMap((action) =>
//   UtilsAjax({
//       url: `${baseUrl}/trillium-clinic-service/v1/user/`,
//       method: 'POST',
//       body: JSON.stringify(action.payload),
//     }).pipe(
//       map(
//         (response) => userSaveResponse(response),
//         takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
//       ),
//     ),
//   ),
// )

MainEpic.coderListApi = (action$) =>
  action$.pipe(
    ofType(LIST),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(
          `${ClinicBaseUrl}/${Apis.coder_coderListApi}`,
          action.payload
        ),
        method: 'GET',
      }).pipe(
        map(
          (response) => coderListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.priorAuthListApi = (action$) =>
  action$.pipe(
    ofType(PRIORAUTH_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(
          `${ClinicBaseUrl}/trillium-internal-service/v1/priorAuth/list/range`,
          action.payload
        ),
        method: 'GET',
      }).pipe(
        map(
          (response) => priorAuthListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.arManagementListApi = (action$) =>
  action$.pipe(
    ofType(ARMGMT_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(
          `${ClinicBaseUrl}/trillium-internal-service/v1/ArManagement/list/range`,
          action.payload
        ),
        method: 'GET',
      }).pipe(
        map(
          (response) => arManagementListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.eligibilityListApi = (action$) =>
  action$.pipe(
    ofType(ELIGIBILITY_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(
          `${ClinicBaseUrl}/trillium-internal-service/v1/eligibilityPortal/list/range`,
          action.payload
        ),
        method: 'GET',
      }).pipe(
        map(
          (response) => eligibilityListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.getCoderApi = (action$) =>
  action$.pipe(
    ofType(GET_CODER),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': 7,
        },
        url: `${ClinicBaseUrl}/${Apis.coder_getCoderApi}/${action.payload.taskId}?claimId=${action.payload.claimId}&codingId=${action.payload.codingId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getCoderResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.writeOffApi = (action$) =>
  action$.pipe(
    ofType(WRITEOFF),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/payment/writeOffBalance/${action.payload.claimId}?status=${action.payload.status}&procedureId=${action.payload.procedureId}`,
        method: 'PUT',
      }).pipe(
        map(
          (response) => writeOffResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.getLedgerApi = (action$) =>
  action$.pipe(
    ofType(GET_LEDGER),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/ledger/list/${action.payload.claimId}?procedureId=${action.payload.procedureId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getLedgerResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.getPatientApi = (action$) =>
  action$.pipe(
    ofType(GET_PATIENT),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/patient/info/${action.payload.patientId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getPatientResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.getProviderApi = (action$) =>
  action$.pipe(
    ofType(GET_PROVIDER),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/provider/info/${action.payload.providerId}?facilityId=${action.payload.facilityId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getProviderResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.getClaimSummaryApi = (action$) =>
  action$.pipe(
    ofType(GET_CLAIM_SUMMARY),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/claim/summary/${action.payload.claimId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getClaimSummaryResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.saveClaimSummaryApi = (action$) =>
  action$.pipe(
    ofType(CLAIM_SUMMARY_SAVE),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/claim/update/${action.payload.claimId}`,
        method: 'PUT',
        body: action.payload.data,
      }).pipe(
        map(
          (response) => claimSummarySaveResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.getProcedureInfoApi = (action$) =>
  action$.pipe(
    ofType(GET_PROCEDURE),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': 7,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/visit/charge/${action.payload.visitId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getProcedureInfoResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.getPaymentSummaryApi = (action$) =>
  action$.pipe(
    ofType(GET_PAYMENT_SUMMARY),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': 7,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/payment/summary/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getPaymentSummaryResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

MainEpic.getNotesListApi = (action$) =>
  action$.pipe(
    ofType(GET_NOTES),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/claim/note/list/${action.payload.claimId}?patientId=${action.payload.patientId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getNotesResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

MainEpic.getApptNotesListApi = (action$) =>
  action$.pipe(
    ofType(GET_APPT_NOTES),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/appointment/note/list/${action.payload.appointmentId}?patientId=${action.payload.patientId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getApptNotesResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

MainEpic.icdSearchApi = (action$) =>
  action$.pipe(
    ofType(ICD_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_icdSearchApi}/${action.payload.s}?clinicId=${action.payload.cid}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => icdSearchResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.icdSaveApi = (action$) =>
  action$.pipe(
    ofType(ICD_SAVE),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        // url: `${ClinicBaseUrl}/${Apis.coder_icdSaveApi}/`,
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/icd/`,
        method: 'POST',
        body: JSON.stringify(action.payload.data),
      }).pipe(
        map(
          (response) => icdSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

MainEpic.cptSearchApi = (action$) =>
  action$.pipe(
    ofType(CPT_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        // url: `${ClinicBaseUrl}/${Apis.coder_cptSearchApi}/${action.payload.search}?clinicId=${action.payload.clinicId}`,
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/cpt/cptcode/${action.payload.cptCode}?clinicId=${action.payload.clinicId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => cptSearchResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.cptEditApi = (action$) =>
  action$.pipe(
    ofType(CPT_EDIT),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-clinic-service/v1/cliniccpt/${action.payload.cptId}?clinicId=${action.payload.clinicId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => cptEditResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

MainEpic.logInfoSaveApi = (action$) =>
  action$.pipe(
    ofType(LOG_INFO_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_logInfoSaveApi}/`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => logInfoSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
MainEpic.cptSaveApi = (action$) =>
  action$.pipe(
    ofType(CPT_SAVE),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/cpt/`,
        method: 'POST',
        body: JSON.stringify(action.payload.data),
      }).pipe(
        map(
          (response) => cptSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

MainEpic.procedureInfoSaveApi = (action$) =>
  action$.pipe(
    ofType(CODER_SAVE),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/visit/charge/`,
        method: 'POST',
        body: JSON.stringify(action.payload.data),
      }).pipe(
        map(
          (response) => procedureInfoSaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
MainEpic.loginInfoApi = (action$) =>
  action$.pipe(
    ofType(LOGIN_INFO),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_loginInfoApi}?applicationId=${7}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => loginInfoResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
MainEpic.fileUploadApi = (action$) =>
  action$.pipe(
    ofType(FILE_UPLOAD),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': action.payload.applicationId,
          enctype: 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/file/upload/`,
        method: 'POST',
        body: action.payload.data,
      }).pipe(
        map(
          (response) => fileUploadResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
MainEpic.downloadFileApi = (action$) =>
  action$.pipe(
    ofType(DOWNLOAD),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/file/download?fileId=${action.payload.data.fileId}&fileName=${action.payload.data.fileName}&isInternal=${action.payload.data.isInternal}`,
        method: 'GET',
        responseType: 'blob',
      }).pipe(
        map(
          (response) => fileDownloadResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

MainEpic.logListApi = (action$) =>
  action$.pipe(
    ofType(LOGLIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_logListApi}/${action.payload.claimId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => loglistResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

MainEpic.mappingListApi = (action$) =>
  action$.pipe(
    ofType(MAPPING_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/external/mapping/list/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => mappingListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.saveMappingApi = (action$) =>
  action$.pipe(
    ofType(SAVE_MAPPING),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/external/mapping/`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveMappingRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

MainEpic.bulkUpdateApi = (action$) =>
  action$.pipe(
    ofType(BULK_UPDATE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_bulkUpdateApi}`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => bulkUpdateResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
MainEpic.rowUpdateApi = (action$) =>
  action$.pipe(
    ofType(ROW_UPDATE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_rowUpdateApi}`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => bulkUpdateResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
MainEpic.icdKeywordSearchApi = (action$) =>
  action$.pipe(
    ofType(ICD_KEYWORD_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/icd/search?searchField=${action.payload.searchType}&searchParam=${action.payload.data}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => icdkeywordResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
MainEpic.icdCodeSearchApi = (action$) =>
  action$.pipe(
    ofType(ICD_CODE_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/icd/search/${action.payload.icdCode}?clinicId=${action.payload.clinicId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => icdCodeResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
MainEpic.icdEditApi = (action$) =>
  action$.pipe(
    ofType(ICD_EDIT),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/icd/search/${action.payload.icdCode}?clinicId=${action.payload.clinicId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => editIcdResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

MainEpic.cptDeleteApi = (action$) =>
  action$.pipe(
    ofType(CPT_DELETE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_cptDeleteApi}/${action.payload}`,
        method: 'DELETE',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => cptDeleteResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
MainEpic.icdChildApi = (action$) =>
  action$.pipe(
    ofType(ICD_CHILD),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
        },
        // url: `${ClinicBaseUrl}/${Apis.coder_icdChildApi}?parentId=${action.payload}`,
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/icd/tree/search?parentId=${action.payload.data}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => icdChildResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

MainEpic.paHistoryApi = (action$) =>
  action$.pipe(
    ofType(PA_HISTORY),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_paHistoryApi}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => paHistoryRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.searchPayorApi = (action$) =>
  action$.pipe(
    ofType(SEARCH_PAYOR),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_searchPayorApi}/name?name=${action.payload.str}&clinicId=${action.payload.id}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => searchPayoRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.patientSaveApi = (action$) =>
  action$.pipe(
    ofType(PATIENT_SAVE),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        // url: `${ClinicBaseUrl}/${Apis.coder_patientSaveApi}`,
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/patient/`,
        method: 'POST',
        body: JSON.stringify(action.payload.data),
      }).pipe(
        map(
          (response) => patientSaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
MainEpic.policyHolderSaveApi = (action$) =>
  action$.pipe(
    ofType(POLICYHOLDER_SAVE),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/policyHolder/`,
        method: 'POST',
        body: JSON.stringify(action.payload.data),
      }).pipe(
        map(
          (response) => policyHolderSaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
MainEpic.policySaveApi = (action$) =>
  action$.pipe(
    ofType(POLICY_SAVE),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        // url: `${ClinicBaseUrl}/${Apis.coder_policySaveApi}`,
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/policy/`,
        method: 'POST',
        body: JSON.stringify(action.payload.data),
      }).pipe(
        map(
          (response) => policySaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
MainEpic.providerSave = (action$) =>
  action$.pipe(
    ofType(PROVIDER_SAVE),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/update/provider/${action.payload.info.id}`,
        method: 'PUT',
        body: JSON.stringify(action.payload.info.data),
      }).pipe(
        map(
          (response) => providerSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
MainEpic.getClaimListApi = (action$) =>
  action$.pipe(
    ofType(GET_CLAIM_LIST),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        // url: `${baseUrl}/${Apis.coder_getCoderApi}/${action.payload.claimId}?codingId=${action.payload.codingId}`,
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/payment/eob/list/${action.payload.data}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getClaimListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.getCoderFilesListApi = (action$) =>
  action$.pipe(
    ofType(GET_CODER_FILES_LIST),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': action.payload.applicationId,
          'Content-Type': 'application/json',
        },
        url: objectToUrl(
          `${ClinicBaseUrl}/trillium-internal-service/v1/common/file/list`,
          action.payload.data
        ),
        method: 'GET',
      }).pipe(
        map(
          (response) => getCoderFilesResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
MainEpic.claimDownloadApi = (action$) =>
  action$.pipe(
    ofType(CLAIM_DOWNLOAD),
    switchMap((action) =>
      ajax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/coderPortal/download?claimId=${action.payload.claimId}&clinicId=${action.payload.clinicId}&fileId=${action.payload.fileId}&fileName=${action.payload.fileName}`,
        method: 'GET',
        responseType: 'blob',
      }).pipe(
        map(
          (response) => claimDownloadRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
MainEpic.claimPreviewApi = (action$) =>
  action$.pipe(
    ofType(CLAIM_PREVIEW),
    switchMap((action) =>
      ajax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/ArManagement/claim/preview?dos=${action.payload.dos}&patientId=${action.payload.patientId}`,
        method: 'GET',
        responseType: 'blob',
      }).pipe(
        map(
          (response) => getClaimPreviewResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

export default MainEpic;
