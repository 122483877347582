import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  priorAuthSave,
  getPriorAuth,
  paHistory,
} from 'Redux/PriorAuthApis/prior.actions';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import Input from 'Components/Input';
import Dropdown from 'Components/Dropdown';
import DobPicker from 'Components/dobPicker';
import { makeStyles } from '@material-ui/core/styles';
import CptTable from './CptTable';
import Diagnosis from './Diagnosis';
import { useState } from 'react';
import { dateToyyymmdd } from 'HelperFunctions/TimeDateTimestamp';
import PriorAuthHistoDialog from 'Components/AppointmentHistory';
import toast from 'react-hot-toast';
import { getCache } from '../../Redux/Appointment/apt.actions';
import './style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    paddingBottom: '2rem !important',
    margin: '10px',
  },
}));

function ProcedureDetails(props) {
  let currentInfo = JSON.parse(localStorage.getItem('currentRow'));

  const classes = useStyles();
  const [icdInfo, setIcdinfo] = useState({});
  const [cptInfo, setCptInfo] = useState({});
  const [receivedDate, setReceivedDate] = useState('');
  const [sensitivity, setSensitivity] = useState('');
  const [duplicateAppointmentWarning, setDuplicateAppointmentWarning] =
    useState('');
  const [aptList, setAptList] = useState([]);
  const [clinicalInfo, setClinicalInfo] = useState('');
  const [sensitivityList, setSensitivityList] = useState('');
  const [paHistoryOpen, setPaHistoryOpen] = useState('');
  const [dos, setDos] = useState('');
  const [apptType, setApptType] = useState('');
  const [patientName, setPatientName] = useState('');
  const [apptId, setApptId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  function removeType(arr) {
    return arr.map(({ type, ...rest }) => rest);
  }

  useEffect(() => {
    if (props.rowInfo)
      props.getPriorAuth({
        apptId: props.rowInfo.appointmentId,
        priorAuthId: props.rowInfo.priorAuthId,
      });
  }, [props.rowInfo]);

  function hasDuplicateICDCodes(object) {
    let icdCodes = new Set();

    for (let i = 1; i <= 8; i++) {
      let icdCode = object[`icd${i}Code`];
      if (icdCode && icdCodes.has(icdCode)) {
        return true; // Duplicate found
      }
      icdCodes.add(icdCode);
    }

    return false; // No duplicates found
  }

  function convertTimestampToISO(timestamp) {
    const date = new Date(timestamp);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    return isoString;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (hasDuplicateICDCodes(icdInfo)) {
      toast.error('Duplicate ICD entered');
      return;
    }
    // checkDuplicateICD(icdInfo);
    let currentSelection = JSON.parse(sessionStorage.getItem('currentRow'));
    let ar = [];
    cptInfo.forEach((x) => {
      ar.push({
        ...x,
        priorAuthId: currentSelection.priorAuthId,
        fee: parseFloat(x.fee).toFixed(2),
      });
    });
    const newArray = removeType(ar);
    props.priorAuthSave({
      appointmentId: apptId,
      appointmentTypeId: apptType,
      appointmentType: aptList.find((x) => x.value === apptType)
        ? aptList.find((x) => x.value === apptType).name
        : '',
      sensitivity: parseInt(sensitivity),
      clinicalInfo: clinicalInfo,
      dos: dateToyyymmdd(dos),
      receivedDate: dateToyyymmdd(receivedDate),
      priorAuthCptDtoList: newArray,
      priorAuthIcdDto: icdInfo,
      priorAuthId: currentSelection.priorAuthId,
      // startDate: new Date(dateToyyymmdd(dos)).getTime(),
      startDate: startDate,
      endDate: endDate,
    });
    sessionStorage.setItem('apptOpen', 1);
  };

  useEffect(() => {
    if (props.priorAuthData.priorAuthInfo) {
      let response = props.priorAuthData.priorAuthInfo.response;
      if (response.responseCode === 0) {
        let info = response.data;

        // const timestamp = info.startDate;
        // const startTime = new Date(timestamp);
        // const formattedTime = startTime
        //   .toISOString()
        //   .replace(/.*T/, '')
        //   .replace(/\.\d+/, '');

        // setStartTime(formattedTime);

        // const endDateTimestamp = info.endDate;
        // const endDate = new Date(endDateTimestamp);
        // const formattedEndDate = endDate
        //   .toISOString()
        //   .replace(/.*T/, '')
        //   .replace(/\.\d+/, '');

        // setEndDate(formattedEndDate);

        setClinicalInfo(info.clinicalInfo);
        setApptType(info.appointmentTypeId);
        setApptId(info.appointmentId);
        setDos(info.dos);
        // setStartDate(convertTimestampToISO(info.startDate));
        setStartDate(new Date(info.startDate).toISOString());
        setEndDate(new Date(info.endDate).toISOString());
        // setEndDate(convertTimestampToISO(info.endDate));
        console.log('start date', new Date(info.startDate).toISOString());
        console.log('end date', new Date(info.endDate).toISOString());
        setReceivedDate(info.receivedDate);
        setSensitivity(info.sensitivity);
        if (response.data.isPatientAppointmentExist === true) {
          setDuplicateAppointmentWarning(
            'Warning : Potential duplicate visits exit; check the list below for duplicate.'
          );
        } else {
          setDuplicateAppointmentWarning('');
        }
      }
    }
  }, [props.priorAuthData.priorAuthInfo]);

  useEffect(() => {
    if (props.priorAuthData.priorAuthSaveResponse) {
      let response = props.priorAuthData.priorAuthSaveResponse.response;
      if (response.responseCode === 0) {
        toast.success('Prior Auth details saved');
        // let data = JSON.parse(localStorage.getItem('currentRow'));
        let data = JSON.parse(sessionStorage.getItem('currentRow'));
        props.getPriorAuth({
          apptId: data.appointmentId,
          priorAuthId: data.priorAuthId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.priorAuthSaveResponse]);

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('cache'));
    let sensitivityList = [];
    cache.sensitivityList.forEach((x) => {
      sensitivityList.push({
        name: x.description,
        value: x.value,
      });
    });
    setSensitivityList(sensitivityList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('clinicId')) {
      let clinicId = sessionStorage.getItem('clinicId');
      props.getCache(clinicId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage.getItem('clinicId')]);

  useEffect(() => {
    if (props.AppointmentData.cache) {
      let Response = props.AppointmentData.cache.response;
      if (Response.responseCode === 0) {
        let data = Response.data;
        let aptList = [];
        data.appointmentTypeDtoList.forEach((x) => {
          aptList.push({
            name: x.name,
            value: x.id,
          });
        });
        setAptList(aptList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppointmentData]);

  const paHistoryfn = () => {
    const currentSelection = JSON.parse(sessionStorage.getItem('currentRow'));
    props.paHistory(currentSelection.patientId);
    setPaHistoryOpen(true);
    setApptType(currentSelection.appointmentType);
    setDos(currentSelection.appointmentTime);
    setPatientName(
      currentSelection.patientLName +
        ',' +
        currentSelection.patientFName +
        ' ' +
        currentSelection.patientMName
    );
  };

  return (
    <div className={'procedure_info_main_div'}>
      <form onSubmit={handleSubmit}>
        <div className="procedureSection_header">
          <div>
            <span className={classes.heading}>
              Procedure/Test/Surgery Details
            </span>
          </div>
          <div className="procedure_button">
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
            <Button variant="outlined" color="primary">
              Cancel
            </Button>
            <Button variant="outlined" color="primary" onClick={paHistoryfn}>
              Appointment history
            </Button>
          </div>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <DobPicker
              label="DOS"
              onChange={(e) => {
                const newDate = dateToyyymmdd(e.target.value);
                const updatedStartDate = newDate + startDate.slice(10);
                const updatedEndDate = newDate + endDate.slice(10);
                setStartDate(updatedStartDate);
                setEndDate(updatedEndDate);
                setDos(e.target.value);
              }}
              value={dos}
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              variant="outlined"
              tabIndex={3}
              options={aptList}
              label="Appointment Type"
              value={apptType}
              onChange={(e) => setApptType(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <DobPicker
              required
              label="Recieved Date"
              onChange={(e) => setReceivedDate(e.target.value)}
              value={receivedDate}
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              variant="outlined"
              tabIndex={3}
              options={sensitivityList}
              label="Sensitivity"
              value={sensitivity}
              onChange={(e) => setSensitivity(e.target.value)}
            />
          </Grid>
        </Grid>
        <Diagnosis
          rowInfo={props.rowInfo}
          icdInformation={(e) => setIcdinfo(e)}
        />
        <div className="procedure_table_container">
          <div className="procedure_cpt_table">
            <CptTable
              icdInfo={icdInfo}
              cptInformation={(e) => setCptInfo(e)}
              rowInfo={props.rowInfo}
            />
          </div>
        </div>
        <div className="subheading_procedure_accordion">
          <p>Supporting Diagnosis & Clinical Information</p>
        </div>
        <Input
          label="Information"
          multiline
          maxRows={4}
          value={clinicalInfo}
          onChange={(e) => setClinicalInfo(e.target.value)}
          variant="outlined"
        ></Input>
      </form>
      <PriorAuthHistoDialog
        open={paHistoryOpen}
        updateUrl={props.updateUrl}
        handleClose={() => setPaHistoryOpen(false)}
        dos={dos}
        apptType={apptType}
        name={patientName}
        warning={duplicateAppointmentWarning}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  priorAuthData: state.priorAuth,
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  priorAuthSave: (values) => dispatch(priorAuthSave(values)),
  getPriorAuth: (values) => dispatch(getPriorAuth(values)),
  paHistory: (values) => dispatch(paHistory(values)),
  getCache: (values) => dispatch(getCache(values)),
});
ProcedureDetails.propTypes = {
  icdSearch: PropTypes.func,
  getPriorAuth: PropTypes.func,
  paHistory: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProcedureDetails);
