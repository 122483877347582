import React from "react";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

/**
 * @fileOverview Manages Reusable Time Picker.
 * @component
 * @author Cinu James  <cinu@trillium-technologies.com>
 * @param {props} value from the parent component
 * @example
 * return(
 * <TimePicker/>
 * )
 */
function TimePicker(props) {
  /**
   * onChange of timepicker
   */
  const handleDateChange = (event) => {
    if (props.onChange) {
      const target = { type: "time", checked: null, value: event };
      props.onChange({ target });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        fullWidth
        minDateMessage="Time should be less than start time"
        {...props}
        invalidDateMessage={false}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleDateChange}
        keyboardIcon={<AccessTimeIcon />}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
        inputVariant="outlined"
      />
    </MuiPickersUtilsProvider>
  );
}

export default TimePicker;
