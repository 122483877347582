import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
// import Dropdown from 'Components/Dropdown';
import { Dropdown } from '../../../../drop-down/src/index';
import PropTypes from 'prop-types';
import './style.css';
import { connect } from 'react-redux';
import {
  saveMapping,
  saveMappingRes,
} from '../../../../Redux/CoderApis/main.actions';
import { toast } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '600 !important',
    paddingBottom: '1.5rem !important',
  },
  cellinfo: {
    fontSize: '12px !important',
    padding: '12px !important',
    cursor: 'pointer',
  },
  cellhead: {
    fontSize: '14px !important',
    padding: '12px !important',
    color: '#808080 !important',
  },
}));

const types = [
  { name: 'Provider', value: 1 },
  { name: 'Location', value: 2 },
  { name: 'Appoinment Type', value: 3 },
  { name: 'Bussiness', value: 4 },
];

// const Source = [
//   { name: "Choice care", value: 1 },
//   { name: "Humana", value: 2 },
// ];

// const Mapped = [
//   { name: "Bussiness", value: 1 },
//   { name: "Appoinment Type", value: 2 },
// ];

function Popup(props) {
  const classes = useStyles();
  const [getInfo, setGetInfo] = useState([]);
  const [mapInfo, setMapInfo] = useState([]);
  const [source, setSource] = useState([]);
  const [mapped, setMapped] = useState([]);
  const [values, setValues] = useState({
    clinicId: 0,
    externalMappingId: 0,
    mapName: '',
    name: '',
    type: 0,
    value: 0,
  });

  useEffect(() => {
    if (localStorage.getItem('cache')) {
      // if (response.responseCode === 0) {
      // let data = response.data;
      let data = localStorage.getItem('cache');
      setGetInfo(data);
      // console.log(data);
      // }
    }
  }, [props.AppointmentData.cache]);

  useEffect(() => {
    if (props.coderData.mappingListResponse) {
      let response = props.coderData.mappingListResponse.response;
      if (response.responseCode === 0) {
        let data = response.data;
        setMapInfo(data);
      }
    }
  }, [props.coderData.mappingListResponse]);

  useEffect(() => {
    if (props.coderData.saveMappingResponse) {
      let response = props.coderData.saveMappingResponse.response;
      if (response.responseCode === 0) {
        toast.success('success');
        props.saveMappingRes(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.saveMappingResponse]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentInfo = JSON.parse(localStorage.getItem('currentEligibility'));
    let name;
    let mapName;
    if (values.externalMappingId) {
      mapName = source.find((x) => x.value == values.externalMappingId).name;
    }
    if (values.externalMappingId) {
      name = mapped.find((x) => x.value == values.value).name;
    }
    props.saveMapping({
      ...values,
      name: name,
      mapName: mapName,
      clinicId: currentInfo.clinicId,
    });
  };

  const handleTypes = (e) => {
    setValues({ ...values, type: e.target.value });
    let source = [];
    mapInfo.forEach((x) => {
      if (x.type === e.target.value) {
        source.push({
          value: x.externalMappingId,
          name: x.name,
        });
      }
    });
    setSource(source);

    if (e.target.value === 1) {
      let providerList = [];
      getInfo.providerDtoList.forEach((x) => {
        providerList.push({
          value: x.id,
          name: x.fullName,
        });
      });
      setMapped(providerList);
    }
    if (e.target.value === 2) {
      let location = [];
      getInfo.locationDtoList.forEach((x) => {
        location.push({
          value: x.id,
          name: x.locationName,
        });
      });
      setMapped(location);
    }
    if (e.target.value === 3) {
      let apptTypeList = [];
      getInfo.appointmentTypeDtoList.forEach((x) => {
        apptTypeList.push({
          value: x.id,
          name: x.name,
        });
      });
      setMapped(apptTypeList);
    }
    if (e.target.value === 4) {
      let payorList = [];
      getInfo.payorDtoList.forEach((x) => {
        payorList.push({
          value: x.id,
          name: x.payerName,
        });
      });
      setMapped(payorList);
    }
  };

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        onClose={() => props.close()}
        open={props.open}
        aria-labelledby="form-dialog-title"
        className="appointment_dialog-create"
      >
        <form onSubmit={handleSubmit}>
          <div className="container_data_create scrollbarY--custom">
            <Typography className={classes.heading}>
              External Mapping
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Dropdown
                  options={types}
                  value={values.type}
                  onChange={handleTypes}
                  label="Type"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <Dropdown
                  value={values.externalMappingId}
                  onChange={(e) =>
                    setValues({ ...values, externalMappingId: e.target.value })
                  }
                  options={source}
                  label="Source"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <Dropdown
                  value={values.value}
                  onChange={(e) => {
                    setValues({ ...values, value: e.target.value });
                  }}
                  options={mapped}
                  label="Mapped"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                gap: '10px',
                marginBottom: '20px',
              }}
            >
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => props.close()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  AppointmentData: state.Appointment,
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  saveMapping: (values) => dispatch(saveMapping(values)),
  saveMappingRes: (values) => dispatch(saveMappingRes(values)),
});
Popup.propTypes = {
  saveMapping: PropTypes.func,
  saveMappingRes: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Popup);
