import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  logInfoSave,
  getCoder,
  getPaymentSummary,
  writeOff,
  getLedger,
  getClaimSummary,
} from '../../../Redux/CoderApis/main.actions';
import PropTypes from 'prop-types';
import NodataImage from '../../../assets/nodata-found.png';
import './style.css';
import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { ViewLedger } from '../../../view-ledger/src';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    paddingBottom: '1.6rem !important',
  },
}));

function Row(props) {
  const {
    row,
    applicationId,
    writeOff,
    getLedger,
    setLedgerOpen,
    setAnchorEl,
  } = props;
  function formatDateToShortYear(inputDate) {
    const [day, month, year] = inputDate.split('/');
    const shortYear = year.slice(-2);
    return `${day}/${month}/${shortYear}`;
  }

  // const handleClick = (e) => {
  //   setOpen(!open);
  // };

  const handleWriteOff = (row) => {
    console.log(row);
    writeOff({
      claimId: row.claimId,
      status: 11,
      applicationId: applicationId,
      procedureId: row.procedureId,
    });
  };
  const handleLedger = (row) => {
    setLedgerOpen(true);
    getLedger({
      claimId: row.claimId,
      applicationId: applicationId,
      procedureId: row.procedureId,
    });
  };

  return (
    <>
      <TableRow>
        <TableCell className="tableCell" align="center">
          {formatDateToShortYear(row.dos)}
        </TableCell>
        <TableCell className="tableCell" align="center">
          {row.cptCode}
        </TableCell>
        <TableCell className="tableCell" align="center">
          ${row.billed}
        </TableCell>
        <TableCell className="tableCell" align="center">
          ${0.0}
        </TableCell>
        <TableCell className="tableCell" align="center">
          ${row.priInsPaid}
        </TableCell>
        <TableCell className="tableCell" align="center">
          ${row.secInsPaid}
        </TableCell>
        <TableCell className="tableCell" align="center">
          ${row.patientPaid}
        </TableCell>
        <TableCell className="tableCell">{row.status}</TableCell>
        <TableCell className="buttonCell">
          <div className="text_button">
            <span onClick={() => handleWriteOff(row)}>Write Off</span>
          </div>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell className="expand_cell" colSpan={12}>
          <Collapse in={true} timeout="auto" unmountOnExit>
            <div className="expand_first_card">
              <div className="expandItem">
                <div className="expand_card_item">
                  Tot Adj: ${row.adjustment}
                </div>
                <div className="expand_card_item">
                  Pri Bal: ${row.priInsBalance}
                </div>
                <div className="expand_card_item">
                  Sec Bal: ${row.secInsBalance}
                </div>
                <div className="expand_card_item">
                  Pat Bal: ${row.patientBalance}
                </div>
              </div>
              <div className="button_container">
                <div className="text_button">
                  <span
                    onClick={(e) => {
                      handleLedger(row);
                      setAnchorEl(e.currentTarget);
                    }}
                  >
                    Ledger
                  </span>
                </div>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function Payment(props) {
  const classes = useStyles();
  const [paymentData, setPaymentData] = useState([]);
  const [ledgerOpen, setLedgerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ledgerData, setLedgerData] = useState([]);
  const heading = [
    'DOS',
    'CPT',
    'Billed',
    'Allowed',
    'Pri Paid',
    'Sec Paid',
    'Pat Paid',
    'Status',
  ];

  useEffect(() => {
    if (props.rowInfo) {
      props.getPaymentSummary(props.rowInfo.claimId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowInfo]);

  useEffect(() => {
    if (props.coderData.paymentSummary) {
      let response = props.coderData.paymentSummary.response;
      if (response.responseCode === 0) {
        let data = response.data;
        setPaymentData(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.paymentSummary]);

  useEffect(() => {
    if (props.coderData.writeOffInfo) {
      let response = props.coderData.writeOffInfo.response;
      if (response.responseCode === 0) {
        if (response.data.id !== 0) {
          toast.success('WriteOff Success');
          props.getPaymentSummary(props.rowInfo.claimId);
          props.getClaimSummary({
            claimId: props.rowInfo.claimId,
            applicationId: props.applicationId,
          });
          props.coderData.writeOffInfo = null;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.writeOffInfo]);

  useEffect(() => {
    if (props.coderData.ledgerInfo) {
      let response = props.coderData.ledgerInfo.response;
      if (response.responseCode === 0) {
        if (response.data.id !== 0) {
          setLedgerData(response.data);
          props.coderData.ledgerInfo = null;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.ledgerInfo]);

  return (
    <div className={'paymentSummary_main_div'}>
      <Typography className={classes.heading}>Payment Summary</Typography>

      {paymentData.length > 0 ? (
        <TableContainer className="tableWrap" component={Paper} elevation={0}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* <TableCell></TableCell> */}
                {/* <TableCell className="col_table_head_cell_filler arrow_icon"></TableCell> */}

                {heading.map((item, key) => (
                  <TableCell className="headingCell" key={key}>
                    {item}
                  </TableCell>
                ))}
                <TableCell className="headingCell" align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentData.map((row, i) => (
                <Row
                  writeOff={props.writeOff}
                  applicationId={props.applicationId}
                  key={i}
                  row={row}
                  getLedger={props.getLedger}
                  setLedgerOpen={setLedgerOpen}
                  setAnchorEl={setAnchorEl}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="nodata">
          <img src={NodataImage} alt="No Data" />
        </div>
      )}
      <ViewLedger
        open={ledgerOpen}
        onClose={setLedgerOpen}
        ledgerData={ledgerData}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  logInfoSave: (values) => dispatch(logInfoSave(values)),
  getPaymentSummary: (values) => dispatch(getPaymentSummary(values)),
  getCoder: (values) => dispatch(getCoder(values)),
  writeOff: (values) => dispatch(writeOff(values)),
  getLedger: (values) => dispatch(getLedger(values)),
  getClaimSummary: (values) => dispatch(getClaimSummary(values)),
});
Payment.propTypes = {
  logInfoSave: PropTypes.func,
  paymentSummary: PropTypes.func,
  getCoder: PropTypes.func,
  writeOff: PropTypes.func,
  getLedger: PropTypes.func,
  getClaimSummary: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Payment);
