import React, { createContext, useEffect, useState } from 'react';

export const filterContext = createContext();

export function ContextProvider({ children }) {
  const [claimId, setClaimId] = useState(null);
  const [codingId, setCodingId] = useState('hello');
  const [selectedItem, setSelectedItem] = useState({
    dos: true,
    patientName: true,
    dob: true,
    clinicName: true,
    provider: true,
    type: true,
    payor: true,
    dueDate: true,
    clarificationType: true,
    owner: true,
    status: true,
    result: true,
    actions: true,
  });
  const [headCells, setHeadCells] = useState([
    { id: 'dos', label: 'DOS', active: false, direction: 'desc', item: 'dos' },
    {
      id: 'patientName',
      label: 'Patient Name',
      active: false,
      direction: 'desc',
      item: 'patientName',
    },
    {
      id: 'dob',
      label: 'DOB',
      active: false,
      direction: 'desc',
      item: 'patientDob',
    },
    {
      id: 'clinicName',
      label: 'Clinic Name',
      active: false,
      direction: 'desc',
      item: 'clinicName',
    },
    {
      id: 'provider',
      label: 'Provider',
      active: false,
      direction: 'desc',
      item: 'providerName',
    },
    {
      id: 'type',
      label: 'Type',
      active: false,
      direction: 'desc',
      item: 'appointmentType',
    },
    {
      id: 'payor',
      label: 'Payor',
      active: false,
      direction: 'desc',
      item: 'payor',
    },
    {
      id: 'dueDate',
      label: 'Due Date',
      active: false,
      direction: 'desc',
      item: 'dueDate',
    },
    {
      id: 'clarificationType',
      label: 'Clarification Type',
      active: false,
      direction: 'desc',
      item: 'clarificationType',
    },
    {
      id: 'owner',
      label: 'Owner',
      active: false,
      direction: 'desc',
      item: 'owner',
    },
    {
      id: 'status',
      label: 'Status',
      active: false,
      direction: 'desc',
      item: 'status',
    },
    {
      id: 'result',
      label: 'Result',
      active: false,
      direction: 'desc',
      item: 'result',
    },
    // { id: "actions", label: "Actions" },
  ]);

  return (
    <filterContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        headCells,
        setHeadCells,
        claimId,
        setClaimId,
        codingId,
        setCodingId,
      }}
    >
      {children}
    </filterContext.Provider>
  );
}

// export default ContextProviders;
