import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPriorAuth } from 'Redux/PriorAuthApis/prior.actions';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import NoDataFound from 'assets/nodata-found.png';
import {
  TimestamptoTime,
  dateTommddyyy,
} from 'HelperFunctions/TimeDateTimestamp';
import { Button, Tooltip } from '@material-ui/core';
import './style.css';

const useStyles = makeStyles((theme) => ({
  table: {
    border: 'solid 1px #e0e0e0',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    paddingBottom: '0.5rem !important',
    display: 'flex',
    gap: '3rem',
  },
  warning: {
    paddingBottom: '1.6rem !important',
    color: 'orange',
  },
}));

function PriorAuthHistoDialog(props) {
  const classes = useStyles();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [orderBy, setOrderBy] = useState('');
  const [orderByParameter, setOrderByParameter] = useState('');

  useEffect(() => {
    if (props.priorAuthData.paHistory) {
      let response = props.priorAuthData.paHistory.response;
      // console.log(response.data);
      if (response.responseCode === 0) {
        setRows(response.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.paHistory]);

  useEffect(() => {
    if (
      props.priorAuthData.priorAuthInfo &&
      props.priorAuthData.priorAuthInfo.response &&
      props.priorAuthData.priorAuthInfo.response.data
    ) {
      props.handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.priorAuthInfo]);

  const editClick = (data) => {
    console.log(data);
    function isObjectInArray(array, targetObject) {
      return (
        array.find(
          (obj) => JSON.stringify(obj) === JSON.stringify(targetObject)
        ) !== undefined
      );
    }
    if (!props.isMainScreen) props.updateUrl(data);
    props.getPriorAuth({
      apptId: data.appointmentId,
      priorAuthId: data.priorAuthId,
    });
    // localStorage.setItem('currentSelection', JSON.stringify(data));
    const list = JSON.parse(localStorage.getItem('currentList'));
    if (!isObjectInArray(list, data)) {
      list.push(data);
      sessionStorage.setItem('currentRow', JSON.stringify(data));
      sessionStorage.setItem('currenSelection', JSON.stringify(data));
      sessionStorage.setItem('apptHistory', JSON.stringify(data));
      localStorage.setItem('currentList', JSON.stringify(list));
    }
    let f = props.priorAuthData.filterForm;
    localStorage.setItem(
      'tableCache',
      JSON.stringify({
        startDate: f.startDate,
        endDate: f.endDate,
        dueDate: f.dueDate,
        start: page,
        limit: rowsPerPage,
        ownerId: f.owner ? f.owner : '',
        providerId: f.provider ? f.provider : '',
        result: f.result ? f.result : '',
        status: f.status ? f.status : '',
        clinicId: f.clinic ? f.clinic : '',
        payorId: f.payorId ? f.payorId : '',
        orderBy: orderBy,
        orderByParameter: orderByParameter,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );
    sessionStorage.setItem('apptOpen', 1);
    // window.location.reload();
    if (props.isMainScreen === true) {
      setTimeout(() => {
        // window.open('/details', '');
        window.open(
          `/details?patientId=${window.btoa(
            data.patientId
          )}&providerId=${window.btoa(
            data.providerId
          )}&facilityId=${window.btoa(
            data.facilityId
          )}&priorAuthId=${window.btoa(
            data.priorAuthId
          )}&clinicId=${window.btoa(data.clinicId)}&appointmentId=${window.btoa(
            data.appointmentId
          )}`,
          ''
        );
      }, 500);
    }
  };

  useEffect(() => {
    let cache = JSON.parse(localStorage.getItem('tableCache'));
    if (cache) {
      // props.setFilter({
      //   clinic: cache.clinicId,
      //   provider: cache.providerId,
      //   status: cache.status,
      //   result: cache.result,
      //   startDate: cache.startDate,
      //   endDate: cache.endDate,
      //   payorId: cache.payorId,
      //   owner: cache.ownerId,
      //   dueDate: cache.dueDate,
      // });
      setOrderByParameter(cache.orderByParameter);
      setRowsPerPage(cache.limit);
      setPage(cache.start);
      setOrderBy(cache.orderBy);
      // setPatientID(cache.patientID);
      localStorage.setItem('tableCache', null);
    }
    sessionStorage.setItem('currentPriorAuth', null);
  }, []);

  const sentanceCase = (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  };

  const handleClose = () => {
    props.handleClose();
    sessionStorage.setItem('apptOpen', 0);
  };

  return (
    <Dialog
      onClose={() => props.handleClose()}
      open={props.open}
      fullWidth={true}
      maxWidth={'lg'}
    >
      <div className="pa_history_main">
        <div className="patient_info">
          <div className="header">
            <Typography className={classes.heading}>
              Appointment history
              <div className="name_info">
                {props.name} | {props.dos} | {props.apptType}
              </div>
            </Typography>
            <div className="pa_history_btns">
              <Button
                color="primary"
                variant="outlined"
                // onClick={() => props.handleClose()}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
          <div className={classes.warning}>{props.warning}</div>
        </div>
        <TableContainer style={{ height: 500 }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {[
                  'DOS',
                  'Created Date',
                  'Provider',
                  'Type',
                  'Payor',
                  'Owner',
                  'Status',
                  'Result',
                  'Actions',
                ].map((x) => (
                  <TableCell
                    className={'data_table_list_cell'}
                    align={'left'}
                    sortDirection={false}
                  >
                    <TableSortLabel active={false} direction={'asc'}>
                      {x}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((x, i) => (
                <TableRow>
                  <TableCell align="left" className={'data_table_list_cell'}>
                    {dateTommddyyy(x.appointmentTime)}
                  </TableCell>
                  <TableCell align="left" className={'data_table_list_cell'}>
                    {dateTommddyyy(x.receivedDate) +
                      ' ' +
                      TimestamptoTime(x.receivedDate)}
                  </TableCell>
                  <TableCell align="left" className={'data_table_list_cell'}>
                    {x.providerLName}
                    {',' + x.providerFName}
                    {',' + x.providerMName}
                  </TableCell>
                  <TableCell align="left" className={'data_table_list_cell'}>
                    {x.appointmentType}
                  </TableCell>
                  <TableCell align="left" className={'data_table_list_cell'}>
                    {sentanceCase(x.payorName.substring(0, 15)) + '...'}
                  </TableCell>
                  <TableCell align="left" className={'data_table_list_cell'}>
                    {x.owner}
                  </TableCell>
                  <Tooltip title={x.status}>
                    <TableCell
                      align="left"
                      style={{ cursor: 'pointer' }}
                      className={'data_table_list_cell'}
                    >
                      {/* {x.status.substring(0, 10)} */}
                      {x.status.length > 10
                        ? x.status.substring(0, 10) + '...'
                        : x.status}
                    </TableCell>
                  </Tooltip>
                  <TableCell align="left" className={'data_table_list_cell'}>
                    {/* {x.result} */}
                    {x.result.length > 10
                      ? x.result.substring(0, 10) + '...'
                      : x.result}
                  </TableCell>
                  <TableCell align="left" className={'data_table_list_cell'}>
                    <div className="table_icon">
                      <EditIcon
                        className="table_editBtn"
                        fontSize="small"
                        onClick={() => editClick(x)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {rows.length === 0 && (
            <div className="nodatafound">
              <img src={NoDataFound} alt={'NoDataFound'} />
            </div>
          )}
        </TableContainer>
        {/* <div className="pa_history_btns">
          <Button
            color="primary"
            variant="outlined"
            // onClick={() => props.handleClose()}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div> */}
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  priorAuthData: state.priorAuth,
});

const mapDispatchToProps = (dispatch) => ({
  getPriorAuth: (values) => dispatch(getPriorAuth(values)),
});
PriorAuthHistoDialog.propTypes = {
  getPriorAuth: PropTypes.func,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriorAuthHistoDialog);
