import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getCoder,
  getPatient,
  patientSave,
} from '../../../../Redux/CoderApis/main.actions';
import { Grid, InputAdornment, TextField } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
// import { Input } from '../../../../Input/index';
import { Button } from '@material-ui/core';
import Input from '../../../../Input';
import { Dropdown } from '../../../../drop-down/src/index';
import { DobPicker } from '../../../../dob-picker/src/index';
import './style.css';
import { useState } from 'react';
import states from '../../../../helper-functions/src/lib/states.json';
import {
  dateTommddyyy,
  dateToyyymmdd,
} from '../../../../helper-functions/src/lib/TimeDateTimestamp';
import toast from 'react-hot-toast';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import copy from 'copy-to-clipboard';
// import { Tooltip } from '@material-ui/core/Tooltip';

function PatientInfo(props) {
  let currentInfo = JSON.parse(localStorage.getItem('currentCoder'));
  const [mode, setMode] = useState('ViewMode');
  const [values, setValues] = useState({
    active: 1,
    clinicId: 0,
    id: 0,
    firstName: '',
    lastName: '',
    middleName: '',
    sdob: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
    policyType: '',
  });
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    zip: '',
    dob: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mode === 'ViewMode') {
      setMode('EditMode');
    } else {
      if (
        errors.firstName === '' &&
        errors.middleName === '' &&
        errors.lastName === '' &&
        errors.addressLine1 === '' &&
        errors.addressLine2 === '' &&
        errors.city === '' &&
        errors.zip === '' &&
        errors.dob === ''
      ) {
        // const dateParts = values.sdob.split('/');
        // const year = dateParts[2];
        // const month = dateParts[0];
        // const day = dateParts[1];
        // const isoDate = year + '-' + month + '-' + day + 'T12:00:00.000Z';
        // console.log(isoDate);

        props.patientSave({
          data: {
            ...values,
            id: props.rowInfo.patientId,
            clinicId: props.clinicId,
            dob: dateToyyymmdd(values.sdob) + 'T12:00:00.000Z',
            sdob: dateTommddyyy(values.sdob),
          },
          applicationId: props.applicationId,
        });
      }
    }
  };

  useEffect(() => {
    if (props.patientInfo) {
      setValues({
        ...values,
        ...props.patientInfo,
        sdob: dateTommddyyy(props.patientInfo.dob),
        policyType: props.patientInfo.billingMethod,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.patientInfo]);

  useEffect(() => {
    if (props.coderData.patientSaveResponse) {
      let response = props.coderData.patientSaveResponse.response;
      if (response.responseCode === 0) {
        setMode('ViewMode');
        toast.success('Patient details updated Successfully');
        let data = JSON.parse(sessionStorage.getItem('currentRow'));
        props.getPatient({
          applicationId: props.applicationId,
          patientId: data.patientId,
        });
        props.coderData.patientSaveResponse = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.patientSaveResponse]);

  const validateFirstName = (value) => {
    if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
      setErrors({ ...errors, firstName: 'Enter a valid Name' });
    } else {
      setErrors({ ...errors, firstName: '' });
    }
  };
  const validateLastName = (value) => {
    if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
      setErrors({ ...errors, lastName: 'Enter a valid Name' });
    } else {
      setErrors({ ...errors, lastName: '' });
    }
  };
  const validateMiddleName = (value) => {
    if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value) && value !== '') {
      setErrors({ ...errors, middleName: 'Enter a valid Name' });
    } else {
      setErrors({ ...errors, middleName: '' });
    }
  };
  const validateCity = (value) => {
    if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
      setErrors({ ...errors, city: 'Enter valid City' });
    } else {
      setErrors({ ...errors, city: '' });
    }
  };
  const validateAddress1 = (value) => {
    if (!/^[a-zA-Z-0-9 -'#&@,]+$/.test(value)) {
      setErrors({ ...errors, addressLine1: 'Enter a valid Address' });
    } else {
      setErrors({ ...errors, addressLine1: '' });
    }
  };
  const validateAddress2 = (value) => {
    if (!/^[a-zA-Z-0-9 -'#&@,]+$/.test(value) && value !== '') {
      setErrors({ ...errors, addressLine2: 'Enter a valid Address' });
    } else {
      setErrors({ ...errors, addressLine2: '' });
    }
  };
  const validateZip = (value) => {
    if (!/^[0-9]{5}(?:-[0-9]{4})?$/.test(value)) {
      setErrors({ ...errors, zip: 'Enter a valid Zip' });
    } else {
      setErrors({ ...errors, zip: '' });
    }
  };
  const validateDateOfBirth = (dob) => {
    const currentDate = new Date();
    const [month, day, year] = dob.split('/').map(Number);

    const inputDate = new Date(year, month - 1, day);

    if (
      !/^\d{2}\/\d{2}\/\d{4}$/.test(dob) ||
      inputDate > currentDate ||
      inputDate.getFullYear() !== year ||
      inputDate.getMonth() !== month - 1 ||
      inputDate.getDate() !== day
    ) {
      setErrors({ ...errors, dob: 'Enter a valid Date of Birth' });
    } else {
      setErrors({ ...errors, dob: '' });
    }
  };

  const handleCancel = () => {
    setMode('ViewMode');
  };

  function formatDateToShortYear(inputDate) {
    if (inputDate !== undefined) {
      const [day, month, year] = inputDate.split('/');
      const shortYear = year.slice(-2);
      return `${day}/${month}/${shortYear}`;
    }
  }

  const concatStrings = (first, second, third, fourth, fifth) => {
    let resultString = first ? first : '';
    if (second) resultString += ', ' + second;
    if (third) resultString += ', ' + third;
    if (fourth) resultString += ', ' + fourth;
    if (fifth) resultString += ', ' + fifth;
    return resultString;
  };

  // const handleStateChange = (v) => {
  //   // console.log(v);
  //   setValues({ ...values, state: v.value });
  // };

  return (
    <div>
      {mode === 'EditMode' && (
        <div className={'EditMode'}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Input
                  // className="input_material_field"
                  variant="outlined"
                  type="text"
                  label="First Name"
                  margin="normal"
                  onChange={(e) =>
                    setValues({ ...values, firstName: e.target.value })
                  }
                  value={values.firstName}
                  onBlur={(e) => validateFirstName(e.target.value)}
                  error={errors.firstName ? true : false}
                  helperText={errors.firstName}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  // className="input_material_field"
                  variant="outlined"
                  type="text"
                  label="Middle Name"
                  margin="normal"
                  onChange={(e) =>
                    setValues({ ...values, middleName: e.target.value })
                  }
                  value={values.middleName}
                  onBlur={(e) => validateMiddleName(e.target.value)}
                  error={errors.middleName ? true : false}
                  helperText={errors.middleName}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  // className="input_material_field"
                  variant="outlined"
                  type="text"
                  label="Last Name"
                  margin="normal"
                  onChange={(e) =>
                    setValues({ ...values, lastName: e.target.value })
                  }
                  value={values.lastName}
                  onBlur={(e) => validateLastName(e.target.value)}
                  error={errors.lastName ? true : false}
                  helperText={errors.lastName}
                />
              </Grid>
              <Grid item xs={3}>
                <DobPicker
                  required
                  label="DOB"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      // sdob: dateTommddyyy(e.target.value),
                      sdob: e.target.value,
                      dob: e.target.value,
                    })
                  }
                  value={values.sdob}
                  maxDate={new Date()}
                  onBlur={(e) => validateDateOfBirth(e.target.value)}
                  error={errors.dob ? true : false}
                  helperText={errors.dob}
                />
                {/* <Skeleton variant="text" /> */}
              </Grid>
              <Grid item xs={4}>
                <Input
                  // className="input_material_field"
                  variant="outlined"
                  type="text"
                  label="AddressLine 1"
                  margin="normal"
                  onChange={(e) =>
                    setValues({ ...values, addressLine1: e.target.value })
                  }
                  value={values.addressLine1}
                  onBlur={(e) => validateAddress1(e.target.value)}
                  error={errors.addressLine1 ? true : false}
                  helperText={errors.addressLine1}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  // className="input_material_field"
                  variant="outlined"
                  type="text"
                  label="AddressLine 2"
                  margin="normal"
                  onChange={(e) =>
                    setValues({ ...values, addressLine2: e.target.value })
                  }
                  value={values.addressLine2}
                  onBlur={(e) => validateAddress2(e.target.value)}
                  error={errors.addressLine2 ? true : false}
                  helperText={errors.addressLine2}
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  // className="input_material_field"
                  variant="outlined"
                  type="text"
                  label="City"
                  margin="normal"
                  onChange={(e) =>
                    setValues({ ...values, city: e.target.value })
                  }
                  value={values.city}
                  onBlur={(e) => validateCity(e.target.value)}
                  error={errors.city ? true : false}
                  helperText={errors.city}
                />
              </Grid>
              <Grid item xs={3}>
                <Dropdown
                  options={states}
                  label="State"
                  variant="outlined"
                  onChange={(e) =>
                    setValues({ ...values, state: e.target.value })
                  }
                  value={values.state}
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  // className="input_material_field"
                  variant="outlined"
                  type="text"
                  label="Zip"
                  margin="normal"
                  onChange={(e) =>
                    setValues({ ...values, zip: e.target.value })
                  }
                  value={values.zip}
                  onBlur={(e) => validateZip(e.target.value)}
                  error={errors.zip ? true : false}
                  helperText={errors.zip}
                />
              </Grid>
              <Grid item xs={3}>
                <Dropdown
                  value={values.country ? values.country : 'US'}
                  options={[
                    { name: 'USA', value: 'US' },
                    { name: 'MEXICO', value: 'MEX' },
                  ]}
                  label="Country"
                  variant="outlined"
                  onChange={(e) =>
                    setValues({ ...values, country: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <div className="edit_btn_group">
                  <Button
                    style={{ marginTop: '10px' }}
                    onClick={handleCancel}
                    variant="contained"
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <div className="sae_btn_patientInfo">
                    <Button
                      style={{ marginTop: '10px' }}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2}>
                {/* <div className="save_btn_patientInfo">
                  <Button
                    style={{ marginTop: '10px' }}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </div> */}
              </Grid>
            </Grid>
          </form>
        </div>
      )}
      {mode === 'ViewMode' && (
        <div className={'ViewMode'}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <div className="input_field_view_mode">
                  <span className="label">Patient Name</span>
                  <div className="input_field_content_wrap">
                    <span
                      style={{}}
                      onDoubleClick={() =>
                        copy(
                          values.firstName +
                            ' ' +
                            values.middleName +
                            ' ' +
                            values.lastName
                        )
                      }
                      className="input_field_content"
                    >
                      {values.firstName.charAt(0).toUpperCase() +
                        values.firstName.slice(1) +
                        ' ' +
                        values.middleName +
                        ' ' +
                        values.lastName}
                    </span>
                    <span
                      style={{
                        height: '25px',
                      }}
                    >
                      <Tooltip title="Click here to Copy">
                        <FileCopyIcon
                          className="copyIcon"
                          color="secondary"
                          fontSize="small"
                          onClick={() =>
                            copy(
                              values.firstName +
                                ' ' +
                                values.middleName +
                                ' ' +
                                values.lastName
                            )
                          }
                        />
                      </Tooltip>
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="input_field_view_mode">
                  <span className="label">DOB</span>
                  <div className="input_field_content_wrap">
                    <span className="input_field_content">
                      {values.sdob ? dateTommddyyy(values.sdob) : null}
                    </span>
                    <span
                      style={{
                        height: '25px',
                      }}
                    >
                      <Tooltip title="Click here to Copy">
                        <FileCopyIcon
                          className="copyIcon"
                          color="secondary"
                          fontSize="small"
                          onClick={() => copy(values.sdob)}
                        />
                      </Tooltip>
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={3}>
                <Tooltip
                  title={
                    values?.addressLine1 +
                    ' ' +
                    values?.addressLine2 +
                    ',' +
                    values?.city +
                    ',' +
                    values?.state +
                    ' ' +
                    values?.zip
                  }
                >
                  <div
                    className="input_field_view_mode"
                    style={{ cursor: 'pointer' }}
                  >
                    <span className="label">Patient Address</span>
                    <div className="input_field_content_wrap">
                      <span className="input_field_content">
                        {/* {values?.addressLine1 +
                          ' ' +
                          values?.addressLine2 +
                          ',' +
                          values?.city +
                          ',' +
                          values?.state +
                          ' ' +
                          values?.zip} */}
                        {concatStrings(
                          values.addressLine1,
                          values.addressLine2,
                          values.city,
                          values.state,
                          values.zip
                        )}
                      </span>
                      <span
                        style={{
                          height: '25px',
                        }}
                      ></span>
                    </div>
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{ marginTop: '10px' }}
                  className="edit_btn_patientInfo"
                >
                  <Button variant="outlined" color="primary" type="submit">
                    {mode === 'ViewMode' ? 'Edit' : 'Save'}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  patientSave: (values) => dispatch(patientSave(values)),
  getCoder: (values) => dispatch(getCoder(values)),
  getPatient: (values) => dispatch(getPatient(values)),
});
PatientInfo.propTypes = {
  patientSave: PropTypes.func,
  getCoder: PropTypes.func,
  getPatient: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo);
