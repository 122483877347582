import { combineReducers } from "redux";
import regReducer from "./PriorAuthApis/prior.reducer";
import aptReducer from "./Appointment/apt.reducer";
/** rootReducer
 * @fileOverview Combine  all reducer action
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 */

const rootReducer = combineReducers({
  priorAuth: regReducer,
  Appointment: aptReducer,
});

export default rootReducer;
