import { useState } from 'react';
import validate from './validateInfo'
  const useForm = () => {
  const [errors, setErrors] = useState({});
  const validateField = (e)=> {
    const {name,value}=e.target
    setErrors(validate(value,name,errors));
  };
  return { validateField, errors };
};

export default useForm;