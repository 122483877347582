import { ofType } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
// import { ajax } from "rxjs/ajax";
// import { ajax as UtilsAjax } from '../../Utils';
import { ajax as UtilsAjax } from '../../Utils';

// import environment from '../../environments/environment';
import environment from '../../environments/environment';

import {
  APT_SAVE,
  CACHE,
  PAYER_SAVE,
  PROVIDER_LIST,
  SEARCH_PATIENT,
} from './apt.types';
import {
  AppointmentSaveResponse,
  getCacheResponse,
  payerSaveResponse,
  providerListResponse,
  searchPatientResponse,
} from './apt.actions';
import { Apis } from '../APIs';
import { objectToUrl } from '../../Utils/ajax-utils';
// const { baseUrl } = environment;
// const { ClinicBaseUrl } = environment;
const AppointmentEpic = {};
const applicationId = localStorage.getItem('applicationId');

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example Name of the epic = (params) =>
 */

/**
 * Login
 * @description Calling the login API for a user to login
 * @param action$
 */

let baseUrl = '';
let ClinicBaseUrl = '';
export function configureApptEpic({ url, clinicUrl }) {
  baseUrl = url;
  ClinicBaseUrl = clinicUrl;
}
// AppointmentEpic.searchPatientApi = (action$) =>
//   action$.pipe(
//     ofType(SEARCH_PATIENT),
//     switchMap((action) =>
//       UtilsAjax({
//         url: `${ClinicBaseUrl}/${Apis.coder_searchPatientApi}?lastName=${action.payload.searchLastName}&dob=${action.payload.searchDob}&clinicId=${action.payload.clinicId}`,
//         method: 'GET',
//       }).pipe(
//         map(
//           (response) => searchPatientResponse(response)
//           // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
//         )
//       )
//     )
//   );
AppointmentEpic.searchPatientApi = (action$) =>
  action$.pipe(
    ofType(SEARCH_PATIENT),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(
          `${ClinicBaseUrl}/trillium-clinic-service/v1/patient/search`,
          action.payload
        ),
        // url: `${ClinicBaseUrl}/trillium-clinic-service/v1/patient/search?lastName=${action.payload.searchLastName}&dob=${action.payload.searchDob}&clinicId=${action.payload.clinicId}  &dob=${action.payload.searchDob}  `,
        // url: `${ClinicBaseUrl}/trillium-clinic-service/v1/patient/search?firstName=${action.payload.firstName}&lastName=${action.payload.lastName}&searchDob=${action.payload.searchDob}&clinicId=${action.payload.clinicId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => searchPatientResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

AppointmentEpic.getProviderListApi = (action$) =>
  action$.pipe(
    ofType(PROVIDER_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(
          `${ClinicBaseUrl}/trillium-clinic-service/v1/provider/list/all`,
          action.payload
        ),
        method: 'GET',
      }).pipe(
        map(
          (response) => providerListResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

AppointmentEpic.cacheApi = (action$) =>
  action$.pipe(
    ofType(CACHE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_cacheApi}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getCacheResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

AppointmentEpic.AppointmentSaveApi = (action$) =>
  action$.pipe(
    ofType(APT_SAVE),

    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': applicationId,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/${Apis.coder_AppointmentSaveApi}/`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => AppointmentSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
AppointmentEpic.payerSaveApi = (action$) =>
  action$.pipe(
    ofType(PAYER_SAVE),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/${Apis.coder_payerSaveApi}/`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => payerSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

export default AppointmentEpic;
