import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

/**
 * Mask for Phone Number
 * @param {props} value from the parent compontent
 * @return {JSXElement}
 */
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar="_"
      //  showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
/**
 * @fileOverview Manages Reusable Phone Number field.
 * @component
 * @author Akhil francis <akhil@trillium-technologies.com>
 * @param {props} value from the parent component
 * @example
 * return(
 * <PhoneFormat/>
 * )
 */
function PhoneFormat(props) {
  const [value, setValues] = useState(props.value ? props.value : "");
  /**
   * onChange of Phone Number field
   */
  const handleInput = (event) => {
    setValues(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };
  return (
    <FormControl style={{ width: "100%" }}>
      <TextField
        onChange={handleInput}
        helperText={props.helperText}
        fullWidth
        InputProps={{
          inputComponent: TextMaskCustom,
          autoComplete: "no",
        }}
        type={props.type ? props.type : "text"}
        id="standard-basic"
        value={value}
        {...props}
        variant="outlined"
        // size="small"
      />
    </FormControl>
  );
}
export default PhoneFormat;
