import React, { createContext, useEffect, useState } from 'react';

export const filterContext = createContext();

function ContextProvider({ children }) {
  const [selectedItem, setSelectedItem] = useState({
    dos: true,
    patientName: true,
    dob: true,
    clinicName: true,
    providerName: true,
    type: true,
    payor: true,
    dueDate: true,
    owner: true,
    status: true,
    result: true,
    locationType: true,
  });
  const [headCells, setHeadCells] = useState([
    { id: 'dos', active: false, direction: 'desc', label: 'DOS', item: 'dos' },
    {
      id: 'patientName',
      active: false,
      direction: 'desc',
      label: 'Patient Name',
      item: 'patientName',
    },
    {
      id: 'dob',
      ctive: false,
      direction: 'desc',
      label: 'DOB',
      item: 'patientDob',
    },
    {
      id: 'clinicName',
      active: false,
      direction: 'desc',
      label: 'Clinic Name',
      item: 'clinicName',
    },
    {
      id: 'providerName',
      active: false,
      direction: 'desc',
      label: 'Provider',
      item: 'providerName',
    },
    {
      id: 'type',
      active: false,
      direction: 'desc',
      label: 'Type',
      item: 'appointmentType',
    },
    {
      id: 'payor',
      active: false,
      direction: 'desc',
      label: 'Payor',
      item: 'payor',
    },
    {
      id: 'dueDate',
      active: false,
      direction: 'desc',
      label: 'Due Date',
      item: 'dueDate',
    },
    {
      id: 'owner',
      active: false,
      direction: 'desc',
      label: 'Owner',
      item: 'owner',
    },
    {
      id: 'status',
      active: false,
      direction: 'desc',
      label: 'Status',
      item: 'status',
    },
    {
      id: 'result',
      active: false,
      direction: 'desc',
      label: 'Result',
      item: 'result',
    },
    // { active: false, direction: 'desc', label: 'Actions', item: '' },
  ]);

  // useEffect(() => {
  //   if (localStorage.getItem('selectedItem') !== null) {
  //     const data = JSON.parse(localStorage.getItem('selectedItem'));
  //     setSelectedItem(data);
  //   }
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem('selectedItem', JSON.stringify(selectedItem));
  // }, [selectedItem]);

  return (
    <filterContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        headCells,
        setHeadCells,
      }}
    >
      {children}
    </filterContext.Provider>
  );
}

export default ContextProvider;
