import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/**
 * @fileOverview Manages Reusable Switch.
 * @component
 * @author Athul George <athul@trillium-technologies.com>
 * @param {props} value from the parent component
 * @example
 * return(
 * <SwitchButton/>
 * )
 */
function SwitchButton(props) {
  const [value, setValues] = useState(
    props.value === true ? props.value : false
  );
  /**
   * rendering the props value
   */
  useEffect(() => {
    setValues(props.value);
  }, [props.value]);
  /**
   * onChange of datepicker
   */
  const changeSwitchStatus = (e) => {
    setValues(e.target.checked);
    if (props.onChange) {
      const target = { value: e.target.checked };
      props.onChange({ target });
    }
    // setCheckedSwitch(e.target.checked)
  };
  return (
    <FormControlLabel
      style={{ marginLeft: '0' }}
      labelPlacement="top"
      control={
        <Switch
          disabled={props.disabled ? props.disabled.active === '' : false}
          size={'medium'}
          checked={value ? value : false}
          onChange={changeSwitchStatus}
          color="primary"
        />
      }
      label={props.label ? props.label : ''}
    />
  );
}

export default SwitchButton;
