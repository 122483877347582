// module.exports = {
//   baseUrl: 'https://api.primrose.health',
//   ClinicBaseUrl: 'https://api.trillium.health',
//   systemName: 'System Prod',
// };

module.exports = {
  baseUrl: 'https://stagingnew.trillium.health',
  ClinicBaseUrl: 'https://staging.api.trillium.health',
  systemName: 'System dev',
};
