import { useState } from 'react';
import { Button } from '@material-ui/core';
import './style.css';
import { PatientDetails } from '../../../patient-info/src/app';
import { PhysicianInformation } from '../../../physician-info/src';
// import CallHistory from '../../../call-history/src/lib/call-history';
import { PaymentSummary } from '../../../payment-summary/src';
import CallHistory from '../../../call-history/src/lib/call-history';
import { UploadList } from '../../../upload-list/src/index';
export function TabsPanel(props) {
  const [openTab, setOpenTab] = useState('basic');

  return (
    <div className="right">
      <d iv className="buttonContainer">
        <div className="tabButtonGroup1">
          <Button
            variant="contained"
            className={openTab === 'basic' ? 'tabButtons' : 'button_active'}
            color="primary"
            onClick={() => setOpenTab('basic')}
            disableElevation
            size="large"
          >
            Basic
          </Button>
          <Button
            variant="contained"
            onClick={() => setOpenTab('notes')}
            className={openTab === 'notes' ? 'tabButtons' : 'button_active'}
            disableElevation
            size="large"
            color="primary"
          >
            Notes
          </Button>
          {props.applicationId === 7 || props.applicationId === 8 ? (
            <Button
              variant="contained"
              className={
                openTab === 'payments' ? 'tabButtons' : 'button_active'
              }
              onClick={() => setOpenTab('payments')}
              disableElevation
              color="primary"
              // disabled={props.applicationId !== 7}
              size="large"
            >
              Payments
            </Button>
          ) : null}
          <Button
            variant="contained"
            className={openTab === 'files' ? 'tabButtons' : 'button_active'}
            color="primary"
            onClick={() => setOpenTab('files')}
            disableElevation
            size="large"
          >
            Files
          </Button>

          {/* <Button
            variant="contained"
            className="tabButtons"
            // onClick={() => setOpenTab('basic')}
            disableElevation
            size="large"
          >
            Filing Hx
          </Button> */}
          {/* <Button
            variant="contained"
            className="tabButtons"
            // onClick={() => setOpenTab('basic')}
            disableElevation
            size="large"
          >
            Tracker
          </Button> */}
        </div>
      </d>

      {openTab === 'basic' && (
        <div className="tab_panel_container">
          <div className="patient_container">
            <PatientDetails
              applicationId={props.applicationId}
              rowInfo={props.rowData}
              setRowInfo={props.setRowInfo}
            />
          </div>
          <div className="physician_container">
            <PhysicianInformation
              rowInfo={props.rowData}
              setRowInfo={props.setRowInfo}
              applicationId={props.applicationId}
            />
          </div>
        </div>
      )}
      {openTab === 'notes' && (
        <div className="patient_container">
          <CallHistory
            rowInfo={props.rowData}
            isLogAdded={props.isLogAdded}
            applicationId={props.applicationId}
          />
        </div>
      )}
      {openTab === 'payments' && (
        <div className="physician_container">
          <PaymentSummary
            applicationId={props.applicationId}
            rowInfo={props.rowData}
          />
        </div>
      )}
      {openTab === 'files' && (
        <div className="physician_container">
          <UploadList
            rowInfo={props.rowData}
            applicationId={props.applicationId}
          />
        </div>
      )}
    </div>
  );
}
export default TabsPanel;
