import React from 'react';
import { Provider } from 'react-redux';
import store from '../../Redux/store';
import FileUploader from './lib/FileUploaderDropzone';
// import { FileUploaderDropzone } from 'Components/FileUploaderDropzone/FileUploaderDropzone';

function app(props) {
  return (
    <Provider store={store}>
      <FileUploader
        applicationId={props.applicationId}
        rowInfo={props.rowInfo}
      />
    </Provider>
  );
}

export const FileUploaderDropzone = app;
