import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  searchPatient,
  getCache,
  AppointmentSave,
  AppointmentSaveResponse,
} from '../../../Redux/Appointment/apt.actions';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Input from '../../../Input/index';
import Dialog from '@material-ui/core/Dialog';
// import PhoneFormat from 'Components/PhoneFormat';
import PhoneFormat from '../../../PhoneFormat';
import Typography from '@material-ui/core/Typography';
import { Dropdown } from '../../../drop-down/src/';
import { DobPicker } from '../../../dob-picker/src';
import TimePicker from '../../../TimePicker';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import states from '../../../helper-functions/src/lib/states.json';
import { dateToyyymmdd } from '../../../helper-functions/src/lib/TimeDateTimestamp';
import CloseIcon from '@material-ui/icons/Close';
import useForm from '../../../CustomValidator/useForm';
import './style.css';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-hot-toast';
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '600 !important',
    paddingBottom: '1.5rem !important',
  },
  cellinfo: {
    fontSize: '12px !important',
    padding: '12px !important',
    cursor: 'pointer',
  },
  cellhead: {
    fontSize: '14px !important',
    padding: '12px !important',
    color: '#808080 !important',
  },
}));

function AppointmentPopup(props) {
  const classes = useStyles();
  const { validateField, errors } = useForm();
  const [dropdowns, setDropdowns] = useState({
    clinics: [],
    locations: [],
    aptList: [],
    provider: [],
    gender: [
      {
        name: 'Male',
        value: 1,
      },
      {
        name: 'Female',
        value: 2,
      },
    ],
    policy: [
      { name: 'Insurance patient', value: 9 },
      { name: 'PIP patient', value: 7 },
      { name: 'WC patient', value: 6 },
      { name: 'Other patient', value: 5 },
      { name: 'HMO patient', value: 3 },
      { name: 'Cash patient', value: 1 },
    ],
    country: [
      { name: 'US', value: 'US' },
      { name: 'Mexico', value: 'MX' },
      // { name: 'CANADA', value: 'Canada' },
    ],
  });

  const [searchForm, setSearchForm] = useState({
    firstName: '',
    lastName: '',
    searchDob: '',
  });

  const [apmtInfo, setApmtInfo] = useState({
    clinicId: '',
    id: 0,
    locationId: '',
    mode: 1,
    notes: '',
    patientId: 0,
    providerId: '',
    reason: '',
    startDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    appointmentReminderStatus: 1,
    appointmentStatus: 1,
    type: '',
    providerName: '',
    locationName: '',
  });
  const [patientInfo, setPatientInfo] = useState({
    active: 1,
    addressLine1: '',
    addressLine2: '',
    billingMethod: 0,
    city: '',
    clinicId: apmtInfo.clinicId,
    country: 'US',
    // dob: new Date('01/01/1971'),
    dob: '',
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    maritalStatus: 0,
    middleName: '',
    mrn: '',
    phone: '',
    policyType: 9,
    sex: 1,
    state: '',
    zip: '',
  });

  const [showTable, setShowTable] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [providerName, setProviderName] = useState('');
  const [locationName, setLocationName] = useState('');

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('cache'));
    let clinicList = [];
    cache.clinicDtoList.forEach((x) => {
      clinicList.push({
        name: x.name,
        value: x.clinicId,
      });
    });
    setDropdowns({
      ...dropdowns,
      clinics: clinicList,
    });
    setApmtInfo({ ...apmtInfo, clinicId: clinicList[0].value });
    props.getCache(clinicList[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.AppointmentData.cache && props.AppointmentData.cache.response) {
      let Response = props.AppointmentData.cache.response;
      if (Response.data === null) {
        setDropdowns({
          ...dropdowns,
          provider: [],
          aptList: [],
          locations: [],
        });
      }
      if (Response.responseCode === 0) {
        let data = Response.data;
        let provider = [];
        let aptList = [];
        let locations = [];
        let clinicList = [];
        const cache = JSON.parse(localStorage.getItem('cache'));

        cache.clinicDtoList.forEach((x) => {
          clinicList.push({
            name: x.name,
            value: x.clinicId,
          });
        });
        data.providerDtoList.forEach((x) => {
          provider.push({
            name: x.fullName,
            value: x.id,
          });
        });
        data.appointmentTypeDtoList
          .filter((x) => x.priorAuthRequired === 1)
          .forEach((x) => {
            aptList.push({
              name: x.name,
              value: x.id,
            });
          });
        data.locationDtoList.forEach((x) => {
          locations.push({
            name: x.locationName,
            value: x.id,
          });
        });
        setDropdowns({
          ...dropdowns,
          provider: provider,
          aptList: aptList,
          locations: locations,
          clinics: clinicList,
        });
        setProviderName(provider);
        setLocationName(locations);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppointmentData.cache]);

  const handleSearch = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (searchForm.lastName || searchForm.searchDob) {
      props.searchPatient({
        lastName: searchForm.lastName,
        firstName: searchForm.lastName,
        dob: searchForm.searchDob ? dateToyyymmdd(searchForm.searchDob) : '',
        clinicId: apmtInfo.clinicId,
      });
    }
    setShowTable(true);
  };

  const getProviderName = (y) => {
    let data = providerName.find((x) => x.value === y);
    if (data) {
      return data.name;
    } else {
      return '';
    }
  };
  const getLocationName = (y) => {
    let data = locationName.find((x) => x.value === y);
    if (data) {
      return data.name;
    } else {
      return '';
    }
  };

  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let cr = apmtInfo.startTime;
    let startTime = new Date(apmtInfo.startDate);
    startTime.setMilliseconds(cr.getMilliseconds());
    startTime.setMinutes(cr.getMinutes());
    startTime.setHours(cr.getHours());
    startTime.setSeconds(cr.getSeconds());
    const data = {
      patientDto: {
        ...patientInfo,
        dob: dateToyyymmdd(patientInfo.dob) + 'T12:00:00.000Z',
        country: patientInfo.country === 'USA' ? 'US' : patientInfo.country,
      },
      appointmentDto: {
        ...apmtInfo,
        startTime: startTime,
        providerName: getProviderName(apmtInfo.providerId),
        locationName: getLocationName(apmtInfo.locationId),
      },
      // appointmentDto: { ...apmtInfo, startTime: startTime },
    };
    // console.log(data.patientDto);
    const err = Object.keys(errors).find((x) => errors[x] !== '');
    if (err === undefined) {
      props.AppointmentSave(data);
    }

    // setPatientInfo("");
    // setApmtInfo("");
    clearForms();
    setShowTable(false);
  };
  // Effect  for handling the API response  after saving  new  appointment.
  useEffect(() => {
    if (
      props.AppointmentData.appointmentResponse &&
      props.AppointmentData.appointmentResponse.response
    ) {
      let Response = props.AppointmentData.appointmentResponse.response;
      if (Response.responseCode === 0 ) {
        toast.success('Appointment added sucessfully');
        props.refreshHomeScreen();
        props.setPatientData(false);
        // props.AppointmentData.appointmentResponse=null
      }
      props.handleClose();
      // Reset the AppointmentResponse after handling the response
      props.AppointmentSaveResponse(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppointmentData.appointmentResponse]);

  useEffect(() => {
    if (props.AppointmentData.searchPatients) {
      let Response = props.AppointmentData.searchPatients.response;
      if (Response.responseCode === 0) {
        setPatientList(Response.data);
      } else {
        setPatientList('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppointmentData.searchPatients]);

  const fillData = (x) => {
    // setShow(true);
    props.setPatientData(true);
    setPatientInfo({
      ...patientInfo,
      ...x,
    });
  };
  const popupClose = () => {
    props.handleClose();
    setSearchForm('');
    // setPatientInfo("");
    // setApmtInfo("");
    clearForms();
    setShowTable(false);
    // setShow(false);
    props.setPatientData(false);
  };
  const handleStateChange = (v) => {
    // console.log(v);
    setPatientInfo({ ...patientInfo, state: v.value });
  };

  const clearForms = () => {
    setPatientInfo({
      ...patientInfo,
      ...{
        active: 1,
        addressLine1: '',
        addressLine2: '',
        billingMethod: 0,
        city: '',
        clinicId: apmtInfo.clinicId,
        country: 'US',
        // dob: new Date('01/01/1971'),
        dob: '',
        email: '',
        firstName: '',
        id: 0,
        lastName: '',
        maritalStatus: 0,
        middleName: '',
        mrn: '',
        phone: '',
        policyType: 9,
        sex: 1,
        state: '',
        zip: '',
      },
    });
    setApmtInfo({
      ...apmtInfo,
      ...{
        clinicId: '',
        id: 0,
        locationId: '',
        mode: 1,
        notes: '',
        patientId: 0,
        providerId: '',
        reason: '',
        startDate: new Date(),
        startTime: new Date(),
        endTime: new Date(),
        appointmentReminderStatus: 1,
        appointmentStatus: 1,
        type: '',
      },
    });
    setSearchForm({
      ...searchForm,
      ...{
        firstName: '',
        lastName: '',
        searchDob: '',
      },
    });
  };

  const clearPatient = () => {
    setPatientInfo({
      ...patientInfo,
      ...{
        active: 1,
        addressLine1: '',
        addressLine2: '',
        billingMethod: 0,
        city: '',
        clinicId: apmtInfo.clinicId,
        country: 'US',
        // dob: new Date('01/01/1971'),
        dob: '',
        email: '',
        firstName: '',
        id: 0,
        lastName: '',
        maritalStatus: 0,
        middleName: '',
        mrn: '',
        phone: '',
        policyType: 9,
        sex: 1,
        state: '',
        zip: '',
      },
    });
    setSearchForm({
      ...searchForm,
      ...{
        firstName: '',
        lastName: '',
        searchDob: '',
      },
    });
  };

  const handleNewPatient = () => {
    // setShow(true);
    props.setPatientData(true);
    clearPatient();
    setShowTable(false);
  };

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        className="appointment_dialog-create"
      >
        <form onSubmit={handleSubmit}>
          <div className="container_data_create scrollbarY--custom">
            <div className="close_icon_div">
              <CloseIcon onClick={popupClose} />
            </div>
            <Typography className={classes.heading}>
              {'Create New Appointment'}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Dropdown
                  required
                  options={dropdowns.clinics}
                  label="Clinic"
                  variant="outlined"
                  // value={apmtInfo.clinicId ? apmtInfo.clinicId : 83622}
                  value={apmtInfo?.clinicId}
                  onChange={(e) => {
                    setApmtInfo({ ...apmtInfo, clinicId: e.target.value });
                    setPatientInfo({
                      ...patientInfo,
                      clinicId: e.target.value,
                    });
                    props.getCache(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Dropdown
                  required
                  options={
                    dropdowns.aptList.length === 0
                      ? [{ name: 'No data found' }]
                      : dropdowns.aptList
                  }
                  label="Type"
                  variant="outlined"
                  onChange={(e) =>
                    setApmtInfo({ ...apmtInfo, type: e.target.value })
                  }
                  value={apmtInfo.type}
                />
              </Grid>
              <Grid item xs={4}>
                <Dropdown
                  required
                  options={dropdowns.provider}
                  label="Provider"
                  variant="outlined"
                  onChange={(e) =>
                    setApmtInfo({ ...apmtInfo, providerId: e.target.value })
                  }
                  value={apmtInfo.providerId}
                />
              </Grid>

              <Grid item xs={4}>
                <DobPicker
                  required
                  variant="outlined"
                  label="Start Date"
                  onChange={(e) =>
                    setApmtInfo({ ...apmtInfo, startDate: e.target.value })
                  }
                  value={apmtInfo.startDate}
                  // minDate={new Date()}
                />
              </Grid>
              <Grid item xs={4}>
                <TimePicker
                  variant="outlined"
                  required
                  label="Start Time"
                  onChange={(e) =>
                    setApmtInfo({ ...apmtInfo, startTime: e.target.value })
                  }
                  value={apmtInfo.startTime}
                />
              </Grid>
              <Grid item xs={4}>
                <TimePicker
                  variant="outlined"
                  required
                  label="End Time"
                  onChange={(e) =>
                    setApmtInfo({ ...apmtInfo, endTime: e.target.value })
                  }
                  value={apmtInfo.endTime}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  required
                  variant="outlined"
                  type="text"
                  label="Reason"
                  onChange={(e) =>
                    setApmtInfo({ ...apmtInfo, reason: e.target.value })
                  }
                  value={apmtInfo.reason}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  variant="outlined"
                  type="text"
                  label="Notes"
                  onChange={(e) =>
                    setApmtInfo({ ...apmtInfo, notes: e.target.value })
                  }
                  value={apmtInfo.notes}
                />
              </Grid>
              <Grid item xs={4}>
                <Dropdown
                  required
                  options={dropdowns.locations}
                  label="Location"
                  variant="outlined"
                  onChange={(e) =>
                    setApmtInfo({ ...apmtInfo, locationId: e.target.value })
                  }
                  value={apmtInfo.locationId}
                />
              </Grid>
            </Grid>
            <div className="patient_search-section" hidden={props.patientData}>
              <form onSubmit={handleSearch}>
                <Typography className={classes.heading}>
                  Patient Search
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Input
                      required
                      variant="outlined"
                      type="text"
                      label="Last Name"
                      name="searchLastName"
                      onBlur={(e) => validateField(e)}
                      error={errors.lastName ? true : false}
                      helperText={errors.lastName}
                      inputProps={{ maxLength: 15 }}
                      value={searchForm.lastName}
                      onChange={(e) =>
                        setSearchForm({
                          ...searchForm,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DobPicker
                      variant="outlined"
                      label="DOB"
                      id="searchDob"
                      value={searchForm.searchDob ? searchForm.searchDob : ''}
                      onChange={(e) =>
                        setSearchForm({
                          ...searchForm,
                          searchDob: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <div className="patient_search_btn">
                      <Button
                        onClick={handleSearch}
                        variant="contained"
                        color="primary"
                      >
                        Search
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        // onClick={() => setShow(true)}
                        onClick={handleNewPatient}
                      >
                        New
                      </Button>
                    </div>
                  </Grid>
                  {showTable ? (
                    <Grid xs={12} className="patient_search_table">
                      <TableContainer component={Paper} style={{ height: 250 }}>
                        <Table aria-label="collapsible table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className={classes.cellhead}
                                align="left"
                              >
                                Last Name
                              </TableCell>
                              <TableCell
                                className={classes.cellhead}
                                align="left"
                              >
                                First Name
                              </TableCell>
                              <TableCell
                                className={classes.cellhead}
                                align="left"
                              >
                                MRN
                              </TableCell>
                              <TableCell
                                className={classes.cellhead}
                                align="left"
                              >
                                DOB
                              </TableCell>
                              <TableCell
                                className={classes.cellhead}
                                align="left"
                              >
                                Gender
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {patientList ? (
                            patientList.length !== 0 ? (
                              <TableBody>
                                {patientList.map((x) => (
                                  <TableRow onClick={() => fillData(x)}>
                                    <TableCell
                                      className={classes.cellinfo}
                                      align="left"
                                    >
                                      {x.lastName}
                                    </TableCell>
                                    <TableCell
                                      className={classes.cellinfo}
                                      align="left"
                                    >
                                      {x.firstName}
                                    </TableCell>
                                    <TableCell
                                      className={classes.cellinfo}
                                      align="left"
                                    >
                                      {x.mrn}
                                    </TableCell>
                                    <TableCell
                                      className={classes.cellinfo}
                                      align="left"
                                    >
                                      {x.sdob}
                                    </TableCell>
                                    <TableCell
                                      className={classes.cellinfo}
                                      align="left"
                                    >
                                      {x.sex === 1 ? 'Male' : 'Female'}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            ) : (
                              <TableBody>
                                <TableRow>
                                  <TableCell align="center" colSpan="5">
                                    No data found
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            )
                          ) : (
                            <TableBody>
                              <TableRow>
                                <TableCell align="center" colSpan="5">
                                  No data found
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                    </Grid>
                  ) : (
                    <div className="patientSearch__noResults">
                      <div className="patientSearch__noResults--text">
                        Please search for a patient to see results.
                      </div>
                    </div>
                  )}
                </Grid>
              </form>
            </div>
            <div className="patient_search-section" hidden={!props.patientData}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Typography className={classes.heading}>
                    Patient Information
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <div className="crate_prior_auth_btn1">
                    <Button
                      onClick={() => props.setPatientData(false)}
                      variant="contained"
                      color="primary"
                    >
                      Search
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Input
                    required
                    variant="outlined"
                    type="text"
                    label="First Name"
                    inputProps={{ maxLength: 15 }}
                    name="firstName"
                    onBlur={(e) => validateField(e)}
                    error={errors.firstName ? true : false}
                    helperText={errors.firstName}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        firstName: e.target.value,
                      })
                    }
                    value={patientInfo.firstName}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    variant="outlined"
                    type="text"
                    label="Middle Name"
                    onBlur={(e) => validateField(e)}
                    error={errors.middleName ? true : false}
                    helperText={errors.middleName}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        middleName: e.target.value,
                      })
                    }
                    value={patientInfo.middleName}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    required
                    variant="outlined"
                    type="text"
                    label="Last Name"
                    inputProps={{ maxLength: 15 }}
                    name="lastName"
                    onBlur={(e) => validateField(e)}
                    error={errors.lastName ? true : false}
                    helperText={errors.lastName}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        lastName: e.target.value,
                      })
                    }
                    value={patientInfo.lastName}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DobPicker
                    variant="outlined"
                    required
                    label="DOB"
                    maxDate={new Date()}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        dob: e.target.value,
                      })
                    }
                    value={patientInfo.dob ? patientInfo.dob : ''}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PhoneFormat
                    required
                    label="Phone"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        phone: e.target.value,
                      })
                    }
                    value={patientInfo.phone}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Dropdown
                    required
                    options={dropdowns.gender}
                    label="Gender"
                    variant="outlined"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        sex: e.target.value,
                      })
                    }
                    value={patientInfo.sex ? patientInfo.sex : 1}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    variant="outlined"
                    type="text"
                    label="Email"
                    name="email"
                    onBlur={(e) => validateField(e)}
                    error={errors.email ? true : false}
                    helperText={errors.email}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        email: e.target.value,
                      })
                    }
                    value={patientInfo.email}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    variant="outlined"
                    type="text"
                    label="MRN"
                    name="mrn"
                    onBlur={(e) => validateField(e)}
                    error={errors.mrn ? true : false}
                    helperText={errors.mrn}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        mrn: e.target.value,
                      })
                    }
                    value={patientInfo.mrn}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Dropdown
                    options={dropdowns.policy}
                    label="Policy Type"
                    required
                    variant="outlined"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        policyType: e.target.value,
                      })
                    }
                    value={
                      patientInfo.policyType === 0 ? 9 : patientInfo.policyType
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <Input
                    variant="outlined"
                    type="text"
                    label="Address Line 1"
                    inputProps={{ maxLength: 25 }}
                    name="addressLine1"
                    onBlur={(e) => validateField(e)}
                    error={errors.addressLine1 ? true : false}
                    helperText={errors.addressLine1}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        addressLine1: e.target.value,
                      })
                    }
                    value={patientInfo.addressLine1}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    variant="outlined"
                    type="text"
                    label="Address Line 2"
                    inputProps={{ maxLength: 25 }}
                    name="addressLine2"
                    onBlur={(e) => validateField(e)}
                    error={errors.addressLine2 ? true : false}
                    helperText={errors.addressLine2}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        addressLine2: e.target.value,
                      })
                    }
                    value={patientInfo.addressLine2}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    variant="outlined"
                    label="Zip"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        zip: e.target.value,
                      })
                    }
                    value={patientInfo.zip}
                    name="zip"
                    onBlur={(e) => validateField(e)}
                    error={errors.zip ? true : false}
                    helperText={errors.zip}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    variant="outlined"
                    type="text"
                    label="City"
                    name="city"
                    onBlur={(e) => validateField(e)}
                    error={errors.city ? true : false}
                    helperText={errors.city}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        city: e.target.value,
                      })
                    }
                    value={patientInfo.city}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    options={states}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, v) => {
                      console.log(v);
                      handleStateChange(v);
                    }}
                    // value={values.state.name}
                    renderInput={(params) => (
                      <TextField
                        value={patientInfo.state}
                        {...params}
                        label="State"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Dropdown
                    options={dropdowns.country}
                    label="Country"
                    variant="outlined"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        country: e.target.value,
                      })
                    }
                    // value={patientInfo.country ? patientInfo.country : 'US'}
                    value={
                      patientInfo.country
                        ? patientInfo.country === 'USA'
                          ? 'US'
                          : patientInfo.country
                        : 'US'
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* <div className="crate_prior_auth_btn1">
                    <Button
                      onClick={() => props.setPatientData(false)}
                      variant="contained"
                      color="primary"
                    >
                      Search
                    </Button>
                  </div> */}
                </Grid>
                <Grid item xs={6}>
                  <div className="crate_prior_auth_btn2">
                    <Button
                      onClick={popupClose}
                      variant="outlined"
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      // onClick={() => props.setPatientData(false)}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => ({
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  searchPatient: (values) => dispatch(searchPatient(values)),
  getCache: (values) => dispatch(getCache(values)),
  AppointmentSave: (values) => dispatch(AppointmentSave(values)),
  AppointmentSaveResponse: (values) =>
    dispatch(AppointmentSaveResponse(values)),
});
AppointmentPopup.propTypes = {
  searchPatient: PropTypes.func,
  getCache: PropTypes.func,
  AppointmentSave: PropTypes.func,
  AppointmentSaveResponse: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentPopup);
