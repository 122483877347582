import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import DataTableFilter from 'Components/dataTableFilter';
import DataTable from 'Components/DataTable';
import { toast } from 'react-hot-toast';
// import Appointment from "Components/Appointment";
import Header from 'Components/Header';
import AppLogout from 'Utils/inactive';
import PropTypes from 'prop-types';
import { Appointment } from '../../../../../libs/appointment-popup/src';
import { priorAuthList } from 'Redux/PriorAuthApis/prior.actions';
import { dateToyyymmdd } from './../../HelperFunctions/TimeDateTimestamp';
import './style.css';

function TableContainer(props) {
  const [open, setOpen] = useState(false);
  const [checkBoxArray, setCheckBoxArray] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [patientData, setPatientData] = useState(false);
  useEffect(() => {
    localStorage.removeItem('currentPriorAuth');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClose = () => {
    setOpen(false);
  };

  const refreshHomeScreen = (params) => {
    let f = props.priorAuthData.filterForm;
    let statusFilter = [];
    let ownerFilter = [];
    let resultFilter = [];
    f.status.forEach((x) => {
      statusFilter.push(f.statusContainer.find((y) => y.name === x)['value']);
    });
    f.result.forEach((x) => {
      resultFilter.push(f.resultContainer.find((y) => y.name === x)['value']);
    });
    f.owner.forEach((x) => {
      ownerFilter.push(f.ownerContainer.find((y) => y.name === x)['value']);
    });
    props.priorAuthList({
      startDate: dateToyyymmdd(f.startDate),
      endDate: dateToyyymmdd(f.endDate),
      dueDate: f.dueDate ? dateToyyymmdd(f.dueDate) : '',
      start: page,
      limit: rowsPerPage,
      ownerIds: ownerFilter.toString(),
      providerId: f.provider ? f.provider : '',
      resultIds: f.result ? resultFilter.toString() : '',
      statusIds: f.status ? statusFilter.toString() : '',
      clinicId: f.clinic ? f.clinic : '',
      payorId: f.payorId ? f.payorId : '',
      patientId: f.patientId ? f.patientId : '',
      orderBy: '',
      orderByParameter: '',
    });
  };

  // useEffect(() => {
  //   let f = props.priorAuthData.filterForm;
  //   let statusFilter = [];
  //   let ownerFilter = [];
  //   let resultFilter = [];
  //   f.status.forEach((x) => {
  //     statusFilter.push(f.statusContainer.find((y) => y.name === x)['value']);
  //   });
  //   f.result.forEach((x) => {
  //     resultFilter.push(f.resultContainer.find((y) => y.name === x)['value']);
  //   });
  //   f.owner.forEach((x) => {
  //     ownerFilter.push(f.ownerContainer.find((y) => y.name === x)['value']);
  //   });

  //   props.priorAuthList({
  //     startDate: dateToyyymmdd(f.startDate),
  //     endDate: dateToyyymmdd(f.endDate),
  //     dueDate: f.dueDate ? dateToyyymmdd(f.dueDate) : '',
  //     start: props.page,
  //     limit: props.rowsPerPage,
  //     ownerIds: ownerFilter.toString(),
  //     providerId: f.provider ? f.provider : '',
  //     resultIds: f.result ? resultFilter.toString() : '',
  //     statusIds: f.status ? statusFilter.toString() : '',
  //     clinicId: f.clinic ? f.clinic : '',
  //     payorId: f.payorId ? f.payorId : '',
  //     patientId: f.patientId ? f.patientId : '',
  //     orderBy: orderBy,
  //     orderByParameter: orderByParameter,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   props.page,
  //   props.rowsPerPage,
  //   props.priorAuthData.bulkUpdateResponse,
  //   props.AppointmentData.appointmentResponse,
  //   orderBy,
  //   orderByParameter,
  // ]);

  useEffect(() => {
    if (
      props.AppointmentData.appointmentResponse &&
      props.AppointmentData.appointmentResponse.response
    ) {
      let Response = props.AppointmentData.appointmentResponse.response;
      if (Response.responseCode === 0) {
        toast.success('Appointment added sucessfully');
        // setOpen(false);
        setPatientData(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppointmentData.appointmentResponse]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // localStorage.setItem("tabelPage", JSON.stringify(newPage));
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const apptCreated = () => {
    //
  };

  return (
    <AppLogout>
      <Header
        totalCount={totalCount}
        setTotalCount={setTotalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        backButton={false}
      />
      <div className="data_table_filter_div">
        <DataTableFilter checkBoxArray={checkBoxArray} />
      </div>
      <div className="main_table">
        <DataTable
          count={totalCount}
          setTotalCount={setTotalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          priorDialog={(e) => setOpen(e)}
          checkBoxArray={(e) => setCheckBoxArray(e)}
        />
        <Appointment
          apptCreated={apptCreated}
          refreshHomeScreen={refreshHomeScreen}
          handleClose={() => handleClose()}
          open={open}
          patientData={patientData}
          setPatientData={setPatientData}
        />
      </div>
    </AppLogout>
  );
}

const mapStateToProps = (state) => ({
  priorAuthData: state.priorAuth,
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  priorAuthList: (values) => dispatch(priorAuthList(values)),
});
TableContainer.propTypes = {
  priorAuthList: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(TableContainer);
