import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import LogDialog from 'Components/logDialog';
import { getApptNotes, getNotes } from '../../../Redux/CoderApis/main.actions';
import { PropTypes } from 'prop-types';
import CallHistorySkeleton from './CallHistorySkeleton';
import './style.css';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
// import { TabPanel } from '@material-ui/lab';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Callhistory(props) {
  const [callInfoList, setCallInfoList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (props.rowInfo) {
      setIsLoading(true);
      if (props.applicationId === 7 || props.applicationId === 8) {
        props.getNotes({
          claimId: props.rowInfo.claimId,
          applicationId: props.applicationId,
          patientId: props.rowInfo.patientId,
        });
      }
      if (props.applicationId === 6 || props.applicationId === 4) {
        props.getApptNotes({
          appointmentId: props.rowInfo.appointmentId,
          applicationId: props.applicationId,
          patientId: props.rowInfo.patientId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowInfo]);

  useEffect(() => {
    if (props.rowInfo) {
      if (props.applicationId === 7) {
        props.getNotes({
          claimId: props.rowInfo.claimId,
          applicationId: props.applicationId,
          patientId: props.rowInfo.patientId,
        });
      }
      if (props.applicationId === 6 || props.applicationId === 4) {
        props.getApptNotes({
          applicationId: props.applicationId,
          patientId: props.rowInfo.patientId,
          appointmentId: props.rowInfo.appointmentId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isLogAdded]);

  useEffect(() => {
    if (props.coderData.notes) {
      let response = props.coderData.notes.response;
      if (response.responseCode === 0) {
        setIsLoading(false);
        let data = response.data;
        // data.reverse();
        // setCallInfoList(data.reverse());
        setCallInfoList(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.notes]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isLoading ? (
        <CallHistorySkeleton />
      ) : (
        <Paper elevation={2} className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label={'All'} />
            <Tab label="Appointment Notes" />
            <Tab label="Patient Notes" />
          </Tabs>
          <TabPanel value={value} index={0}>
            {callInfoList
              .filter((item) => item.note)
              .map((x, i) => (
                <TableRow key={i}>
                  <TableCell className="notes_content" align="left">
                    {x.note && x.note}
                  </TableCell>
                </TableRow>
              ))}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {callInfoList
              .filter((x) => x.isPatientNote === false && x.note)
              .map((x, i) => (
                <TableRow key={i}>
                  <TableCell className="notes_content" align="left">
                    {x.note && x.note}
                  </TableCell>
                </TableRow>
              ))}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {callInfoList
              .filter((x) => x.isPatientNote === true && x.note)
              .map((x, i) => (
                <TableRow key={i}>
                  <TableCell className="notes_content" align="left">
                    {x.note && x.note}
                  </TableCell>
                </TableRow>
              ))}
          </TabPanel>
        </Paper>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  getNotes: (values) => dispatch(getNotes(values)),
  getApptNotes: (values) => dispatch(getApptNotes(values)),
});
Callhistory.propTypes = {
  getNotes: PropTypes.func,
  getApptNotes: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Callhistory);
