import React, { useContext } from 'react';
import { connect } from 'react-redux';
import {
  priorAuthList,
  getPriorAuth,
  logList,
  bulkUpdate,
  paHistory,
  setFilter,
} from 'Redux/PriorAuthApis/prior.actions';
import PropTypes from 'prop-types';
import { makeStyles, styled } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {
  dateTommddyyy,
  dateToyyymmdd,
} from 'HelperFunctions/TimeDateTimestamp';
import Dropdown from 'Components/Dropdown';
import './style.css';
import { useEffect } from 'react';
import { useState } from 'react';
import { Checkbox } from '@material-ui/core';
import NoDataFound from 'assets/nodata-found.png';
import { toast } from 'react-hot-toast';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import ListIcon from '@material-ui/icons/List';
import LogDialog from 'Components/logDialog';
import PriorAuthHistoDialog from 'Components/AppointmentHistory';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { filterContext } from '../../context/contextProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    borderTop: 'solid 1px #e0e0e0',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  logBtnTable: {
    cursor: 'pointer',
  },
}));
const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fill: `lightgray`,
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      fill: `#139696`,
    },
  },
}));
const EnhancedTable = (props) => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const { selectedItem, headCells, setHeadCells } = useContext(filterContext);
  const [status, setStatus] = useState([]);
  const [result, setResult] = useState([]);
  const [owner, setOwner] = useState([]);
  const [mainCheckbox, setMainCheckbox] = useState(false);
  const [logOpen, setLogOpen] = useState(false);
  const [paHistoryOpen, setPaHistoryOpen] = useState(false);
  const [logName, setlogName] = useState('');
  const [duplicateAppointmentWarning, setDuplicateAppointmentWarning] =
    useState('');
  const [dos, setDos] = useState('');
  const [apptType, setApptType] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [orderByParameter, setOrderByParameter] = useState('');
  const [isNewAppt, setIsNewAppt] = useState(false);
  const [currentAppt, setCurrentAppt] = useState({});

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('cache'));
    let status = [];
    let result = [];
    let owner = [];
    cache.priorAuthStatusDtoList.forEach((x) => {
      status.push({
        name: x.description,
        value: parseInt(x.value),
      });
    });
    cache.priorAuthResultDtoList.forEach((x) => {
      result.push({
        name: x.description,
        value: parseInt(x.value),
      });
    });
    cache.userDtoList.forEach((x) => {
      owner.push({
        name: x.fullName,
        value: x.userId,
      });
    });
    // Sort the owner array alphabetically by name
    owner.sort((a, b) => a.name.localeCompare(b.name));
    setStatus(status);
    setResult(result);
    setOwner(owner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let f = props.priorAuthData.filterForm;
    let statusFilter = [];
    let ownerFilter = [];
    let resultFilter = [];
    f.status.forEach((x) => {
      statusFilter.push(f.statusContainer.find((y) => y.name === x)['value']);
    });
    f.result.forEach((x) => {
      resultFilter.push(f.resultContainer.find((y) => y.name === x)['value']);
    });
    f.owner.forEach((x) => {
      ownerFilter.push(f.ownerContainer.find((y) => y.name === x)['value']);
    });

    props.priorAuthList({
      startDate: dateToyyymmdd(f.startDate),
      endDate: dateToyyymmdd(f.endDate),
      dueDate: f.dueDate ? dateToyyymmdd(f.dueDate) : '',
      start: props.page,
      limit: props.rowsPerPage,
      ownerIds: ownerFilter.toString(),
      providerId: f.provider ? f.provider : '',
      resultIds: f.result ? resultFilter.toString() : '',
      statusIds: f.status ? statusFilter.toString() : '',
      clinicId: f.clinic ? f.clinic : '',
      payorId: f.payorId ? f.payorId : '',
      patientId: f.patientId ? f.patientId : '',
      orderBy: orderBy,
      orderByParameter: orderByParameter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.page,
    props.rowsPerPage,
    props.priorAuthData.bulkUpdateResponse,
    props.AppointmentData.appointmentResponse,
    orderBy,
    orderByParameter,
  ]);

  // const refreshScreen = () => {
  //   let f = props.priorAuthData.filterForm;
  //   console.log(f);
  //   let statusFilter = [];
  //   let ownerFilter = [];
  //   let resultFilter = [];
  //   f.status.forEach((x) => {
  //     statusFilter.push(f.statusContainer.find((y) => y.name === x)['value']);
  //   });
  //   f.result.forEach((x) => {
  //     resultFilter.push(f.resultContainer.find((y) => y.name === x)['value']);
  //   });
  //   f.owner.forEach((x) => {
  //     ownerFilter.push(f.ownerContainer.find((y) => y.name === x)['value']);
  //   });
  //   props.priorAuthList({
  //     startDate: dateToyyymmdd(f.startDate),
  //     endDate: dateToyyymmdd(f.endDate),
  //     dueDate: f.dueDate ? dateToyyymmdd(f.dueDate) : '',
  //     start: 0,
  //     limit: props.rowsPerPage,
  //     ownerIds: ownerFilter.toString(),
  //     providerId: f.provider ? f.provider : '',
  //     resultIds: f.result ? resultFilter.toString() : '',
  //     statusIds: f.status ? statusFilter.toString() : '',
  //     clinicId: f.clinic ? f.clinic : '',
  //     payorId: f.payorId ? f.payorId : '',
  //     patientId: f.patientId ? f.patientId : '',
  //     orderBy: orderBy,
  //     orderByParameter: orderByParameter,
  //   });
  //   props.setPage(0);
  // };

  useEffect(() => {
    let f = props.priorAuthData.filterForm;
    // console.log(f);
    let statusFilter = [];
    let ownerFilter = [];
    let resultFilter = [];
    f.status.forEach((x) => {
      statusFilter.push(f.statusContainer.find((y) => y.name === x)['value']);
    });
    f.result.forEach((x) => {
      resultFilter.push(f.resultContainer.find((y) => y.name === x)['value']);
    });
    f.owner.forEach((x) => {
      ownerFilter.push(f.ownerContainer.find((y) => y.name === x)['value']);
    });
    props.priorAuthList({
      startDate: dateToyyymmdd(f.startDate),
      endDate: dateToyyymmdd(f.endDate),
      dueDate: f.dueDate ? dateToyyymmdd(f.dueDate) : '',
      start: 0,
      limit: props.rowsPerPage,
      ownerIds: ownerFilter.toString(),
      providerId: f.provider ? f.provider : '',
      resultIds: f.result ? resultFilter.toString() : '',
      statusIds: f.status ? statusFilter.toString() : '',
      clinicId: f.clinic ? f.clinic : '',
      payorId: f.payorId ? f.payorId : '',
      patientId: f.patientId ? f.patientId : '',
      orderBy: orderBy,
      orderByParameter: orderByParameter,
    });
    props.setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.filterForm]);

  useEffect(() => {
    let cache = JSON.parse(localStorage.getItem('tableCache'));
    if (cache) {
      setOrderByParameter(cache.orderByParameter);
      props.setRowsPerPage(cache.limit);
      props.setPage(cache.start);
      setOrderBy(cache.orderBy);
      // setPatientID(cache.patientID);
      localStorage.setItem('tableCache', null);
    }
    sessionStorage.setItem('currentPriorAuth', null);
  }, []);

  useEffect(() => {
    if (props.priorAuthData.bulkUpdateResponse) {
      let response = props.priorAuthData.bulkUpdateResponse.response;
      if (response.responseCode === 0) {
        toast.success('Updation Success');
        setMainCheckbox(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.bulkUpdateResponse]);

  useEffect(() => {
    if (props.priorAuthData.tableData) {
      let response = props.priorAuthData.tableData.response;
      if (response.responseCode === 0) {
        setRows(response.data.results);
        localStorage.setItem('allItems', JSON.stringify(response.data.results));
        // console.log((response.data.results));
        props.setTotalCount(response.data.totalRecords);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.tableData]);

  const editClick = (data) => {
    setCurrentAppt(data);
    if (data.priorAuthId === 0) {
      setIsNewAppt(true);
    } else setIsNewAppt(false);
    props.getPriorAuth({
      apptId: data.appointmentId,
      priorAuthId: data.priorAuthId,
    });
    const list = JSON.parse(localStorage.getItem('allItems'));
    list.forEach((item) => {
      if (parseInt(item.appointmentId) === parseInt(data.appointmentId)) {
        sessionStorage.setItem('currentSelection', JSON.stringify(item));
        sessionStorage.setItem('currentRow', JSON.stringify(item));
      }
    });
    sessionStorage.setItem('clinicId', data.clinicId);
    // localStorage.setItem('openedItem', JSON.stringify(data));
    let f = props.priorAuthData.filterForm;
    localStorage.setItem('filterData', JSON.stringify(f));
    localStorage.setItem(
      'tableCache',
      JSON.stringify({
        startDate: f.startDate,
        endDate: f.endDate,
        dueDate: f.dueDate,
        start: props.page,
        limit: props.rowsPerPage,
        ownerId: f.owner ? f.owner : '',
        providerId: f.provider ? f.provider : '',
        result: f.result ? f.result : '',
        status: f.status ? f.status : '',
        clinicId: f.clinic ? f.clinic : '',
        payorId: f.payorId ? f.payorId : '',
        // patientId: patientID,
        orderBy: orderBy,
        orderByParameter: orderByParameter,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );
    if (data.priorAuthId !== 0) {
      setTimeout(() => {
        window.open(
          `/details?patientId=${window.btoa(
            data.patientId
          )}&providerId=${window.btoa(
            data.providerId
          )}&facilityId=${window.btoa(
            data.facilityId
          )}&priorAuthId=${window.btoa(
            data.priorAuthId
          )}&clinicId=${window.btoa(data.clinicId)}&appointmentId=${window.btoa(
            data.appointmentId
          )}`,
          ''
        );
      }, 500);
    }
  };

  const handleLog = (x) => {
    props.logList({
      appointmentId: x.appointmentId,
    });
    setlogName(x.patientLName + ',' + x.patientFName + ' ' + x.patientMName);
    setDos(dateTommddyyy(x.appointmentTime));
    setLogOpen(true);
  };

  const handleLogClose = () => {
    setLogOpen(false);
  };

  const handleCheckboxArray = (e) => {
    setMainCheckbox(e.target.checked);
    let r = [];
    rows.forEach((x) => {
      r.push({
        ...x,
        isSelected: e.target.checked,
      });
    });
    setRows([...r]);
    props.checkBoxArray([...r]);
  };

  const handleCheckbox = (e, i) => {
    let r = rows;
    r[i] = { ...r[i], isSelected: e.target.checked };
    setRows([...r]);
    props.checkBoxArray([...r]);
  };

  const updateStatus = (e, x, i) => {
    let r = rows;
    r[i].statusId = e.target.value;
    setRows([...r]);
    props.bulkUpdate([
      {
        appointmentId: x.appointmentId,
        owner: '',
        ownerId: null,
        priorAuthId: x.priorAuthId,
        resultId: null,
        statusId: e.target.value,
      },
    ]);
  };

  const updateResult = (e, x, i) => {
    let r = rows;
    r[i].resultId = e.target.value;
    setRows([...r]);
    props.bulkUpdate([
      {
        appointmentId: x.appointmentId,
        owner: '',
        ownerId: null,
        priorAuthId: x.priorAuthId,
        resultId: e.target.value,
        statusId: null,
      },
    ]);
  };

  const updateOwner = (e, x, i) => {
    let r = rows;
    r[i].ownerId = e.target.value;
    setRows([...r]);
    props.bulkUpdate([
      {
        appointmentId: x.appointmentId,
        owner: owner.find((x) => x.value === e.target.value)
          ? owner.find((x) => x.value === e.target.value)['name']
          : '',
        ownerId: e.target.value,
        priorAuthId: x.priorAuthId,
        resultId: null,
        statusId: null,
      },
    ]);
  };

  useEffect(() => {
    if (
      props.priorAuthData.priorAuthInfo &&
      props.priorAuthData.priorAuthInfo.response &&
      props.priorAuthData.priorAuthInfo.response.data
    ) {
      let data = props.priorAuthData.priorAuthInfo.response.data;
      if (isNewAppt) {
        let current = JSON.parse(sessionStorage.getItem('currentRow'));
        let newRow = { ...current, priorAuthId: data.priorAuthId };
        sessionStorage.setItem('currentRow', JSON.stringify(newRow));
        sessionStorage.setItem('currentSelection', JSON.stringify(newRow));
        setTimeout(() => {
          window.open(
            `/details?patientId=${window.btoa(
              data.patientId
            )}&providerId=${window.btoa(
              currentAppt.providerId
            )}&facilityId=${window.btoa(
              data.facilityId
            )}&priorAuthId=${window.btoa(
              data.priorAuthId
            )}&clinicId=${window.btoa(
              data.clinicId
            )}&appointmentId=${window.btoa(data.appointmentId)}`,
            ''
          );
        }, 500);
        // setIsNewAppt(false);
      }
      if (data.isPatientAppointmentExist === true) {
        setDuplicateAppointmentWarning(
          'Warning : Potential duplicate visits exit; check the list below for duplicate.'
        );
      } else {
        setDuplicateAppointmentWarning('');
      }
    }
  }, [props.priorAuthData.priorAuthInfo]);

  const handlePrioauthHistory = (x) => {
    // console.log(x);
    // const currentSelection = JSON.parse(localStorage.getItem('currentRow'));
    props.paHistory(x.patientId);
    setPaHistoryOpen(true);
    // setApptOpen(true)
    sessionStorage.setItem('apptOpen', 1);
    setlogName(x.patientLName + ',' + x.patientFName + ' ' + x.patientMName);
    setDos(dateTommddyyy(x.appointmentTime));
    setApptType(x.appointmentType);
  };

  const handleSort = (index) => {
    let ar = headCells;
    ar.forEach((y, i) => {
      if (i === index) {
        ar[i].active = true;
        ar[i].direction = ar[i].direction === 'asc' ? 'desc' : 'asc';
        setOrderByParameter(ar[i].item);
        setOrderBy(ar[i].direction === 'asc' ? 'desc' : 'asc');
      } else {
        ar[i].active = false;
        ar[i].direction = 'desc';
      }
    });
    setHeadCells([...ar]);
  };

  const sentanceCase = (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  };

  const handlePaHistClose = () => {
    setPaHistoryOpen(false);
    sessionStorage.setItem('apptOpen', 0);
  };
  const apptAdd = () => {
    props.priorDialog(true);
    localStorage.setItem('searchRes', 1);
  };

  const updateUrl = (paramsData) => {
    // setParamsData(paramsData);
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    params.set('providerId', window.btoa(paramsData.providerId));
    params.set('facilitytId', window.btoa(paramsData.facilityId));
    params.set('priorAuthId', window.btoa(paramsData.priorAuthId));
    params.set('clinicId', window.btoa(paramsData.clinicId));
    params.set('appointmentId', window.btoa(paramsData.appointmentId));
    url.search = params.toString();
    window.history.replaceState({}, '', url.href);
  };

  return (
    <div className={classes.root}>
      <LogDialog
        open={logOpen}
        handleClose={() => handleLogClose()}
        name={logName}
        dos={dos}
      />
      <PriorAuthHistoDialog
        open={paHistoryOpen}
        handleClose={() => handlePaHistClose()}
        isMainScreen={true}
        updateUrl={updateUrl}
        name={logName}
        warning={duplicateAppointmentWarning}
        dos={dos}
        apptType={apptType}
      />
      <AddCircleRoundedIcon
        color="primary"
        className="appointment_add_icon"
        // onClick={() => props.priorDialog(true)}
        onClick={() => apptAdd()}
      />
      <TableContainer className="priorauth_table_list">
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="a dense table"
          size="small"
        >
          <TableHead className="dataTable_head">
            <TableRow>
              <TableCell
                className={'data_table_list_cell'}
                align={'left'}
                sortDirection={false}
              >
                <TableSortLabel
                  active={false}
                  direction={'asc'}
                  className="table_checkbox"
                >
                  <GreenCheckbox
                    color="primary"
                    size="small"
                    onChange={handleCheckboxArray}
                    checked={mainCheckbox}
                  ></GreenCheckbox>
                </TableSortLabel>
              </TableCell>
              {headCells
                .filter((x) => x.status === true)
                .map((x, i) => (
                  <TableCell
                    className={'prior_auth_table_cell'}
                    align={'left'}
                    sortDirection={true}
                  >
                    <TableSortLabel
                      onClick={(e) => handleSort(i)}
                      active={x.active}
                      direction={x.direction}
                    >
                      {x.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              <TableCell className="data_table_list_cell">
                <TableSortLabel>Actions</TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((x, i) => (
              <TableRow hover role="checkbox">
                <TableCell className={'prior_auth_table_cell'} scope="row">
                  <div className="table_checkbox">
                    <GreenCheckbox
                      size="small"
                      checked={x.isSelected === true ? true : false}
                      onChange={(e) => handleCheckbox(e, i)}
                    ></GreenCheckbox>
                    {x.sensitivity === 1 && (
                      <PriorityHighIcon className="priority_icon" />
                    )}
                  </div>
                </TableCell>
                {selectedItem.dos && (
                  <TableCell className={'prior_auth_table_cell'} scope="row">
                    {x.appointmentTime && dateTommddyyy(x.appointmentTime)}
                  </TableCell>
                )}
                {selectedItem.patientName && (
                  <TableCell className={'prior_auth_table_cell'} align="left">
                    {x.patientLName &&
                      sentanceCase(x.patientLName.substring(0, 20))}
                    {x.patientFName &&
                      ',' + sentanceCase(x.patientFName.substring(0, 20))}
                    {x.patientMName &&
                      ',' + sentanceCase(x.patientMName.substring(0, 20))}
                  </TableCell>
                )}
                {selectedItem.dob && (
                  <TableCell className={'prior_auth_table_cell'} align="left">
                    {x.patientDob && x.patientDob}
                  </TableCell>
                )}
                {selectedItem.clinicName && (
                  <TableCell className={'prior_auth_table_cell'} align="left">
                    {x.clinicName &&
                      sentanceCase(x.clinicName.substring(0, 15)) + '...'}
                  </TableCell>
                )}
                {selectedItem.providerName && (
                  <TableCell className={'prior_auth_table_cell'} align="left">
                    {x.providerLName && sentanceCase(x.providerLName)}
                    {x.providerFName && ',' + sentanceCase(x.providerFName)}
                    {x.providerMName && ',' + sentanceCase(x.providerMName)}
                  </TableCell>
                )}
                {selectedItem.type && (
                  <TableCell className={'prior_auth_table_cell'} align="left">
                    {x.appointmentType &&
                      sentanceCase(x.appointmentType.substring(0, 15))}
                  </TableCell>
                )}
                {selectedItem.payor && (
                  <TableCell className={'prior_auth_table_cell'} align="left">
                    {x.payorName && sentanceCase(x.payorName.substring(0, 10))}
                  </TableCell>
                )}
                {selectedItem.dueDate && (
                  <TableCell className={'prior_auth_table_cell'} align="left">
                    {x.dueDate && x.dueDate}
                  </TableCell>
                )}

                {selectedItem.owner && (
                  <TableCell className={'data_table_dropdown'} align="left">
                    <Dropdown
                      options={[{ name: 'Unassigned', value: '0' }, ...owner]}
                      size="small"
                      value={x.ownerId}
                      onChange={(e) => updateOwner(e, x, i)}
                    />
                  </TableCell>
                )}
                {selectedItem.status && (
                  <TableCell className={'data_table_dropdown'} align="left">
                    <Dropdown
                      options={[{ name: 'No Status', value: '0' }, ...status]}
                      size="small"
                      value={x.statusId}
                      onChange={(e) => updateStatus(e, x, i)}
                    />
                  </TableCell>
                )}
                {selectedItem.result && (
                  <TableCell className={'data_table_dropdown'} align="left">
                    <Dropdown
                      options={[{ name: 'No Result', value: '0' }, ...result]}
                      size="small"
                      value={x.resultId}
                      onChange={(e) => updateResult(e, x, i)}
                    />
                  </TableCell>
                )}
                <TableCell className={'data_table_list_cell'} align="left">
                  <div className="table_icon">
                    <EditIcon
                      className="table_editBtn"
                      fontSize="small"
                      onClick={() =>
                        editClick({
                          appointmentId: x.appointmentId,
                          priorAuthId: x.priorAuthId,
                          patientId: x.patientId,
                          providerId: x.providerId,
                          facilityId: x.facilityId,
                          clinicId: x.clinicId,
                        })
                      }
                    />
                    <AssignmentIcon
                      className="table_logBtn"
                      fontSize="small"
                      onClick={() => handleLog(x)}
                    />
                    <ListIcon
                      className="table_editBtn"
                      fontSize="small"
                      onClick={() => handlePrioauthHistory(x)}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {rows.length === 0 && (
          <div className="nodatafound">
            <img src={NoDataFound} alt={'NoDataFound'} />
          </div>
        )}
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  priorAuthData: state.priorAuth,
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  priorAuthList: (values) => dispatch(priorAuthList(values)),
  getPriorAuth: (values) => dispatch(getPriorAuth(values)),
  logList: (values) => dispatch(logList(values)),
  bulkUpdate: (values) => dispatch(bulkUpdate(values)),
  paHistory: (values) => dispatch(paHistory(values)),
  setFilter: (values) => dispatch(setFilter(values)),
});
EnhancedTable.propTypes = {
  priorAuthList: PropTypes.func,
  getPriorAuth: PropTypes.func,
  logList: PropTypes.func,
  bulkUpdate: PropTypes.func,
  paHistory: PropTypes.func,
  setFilter: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(EnhancedTable);
