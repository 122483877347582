import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  cptSearch,
  cptDelete,
  getPriorAuth,
} from 'Redux/PriorAuthApis/prior.actions';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Dropdown from 'Components/Dropdown';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import Input from 'Components/Input';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CptDialog from '../CptDialog';
import './style.css';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  iconColor: {
    color: '#ffff',
  },
  cellinfo: {
    fontSize: '14px !important',
    padding: '0px !important',
    paddingLeft: '8px !important',
    paddingRight: '5px !important',
  },
  cellhead: {
    fontSize: '16px !important',
    padding: '15px !important',
  },
});

function CptTable(props) {
  const [openCpt, setopenCpt] = useState(false);
  const [cptIndex, setCptIndex] = useState(0);
  const [cptList, setCptList] = useState([]);
  const [cptArray, setCptArray] = useState([]);
  const currentRow = JSON.parse(sessionStorage.getItem('currentRow'));
  const clinicId = currentRow.clinicId;
  const classes = useRowStyles();

  const cptSearchFn = (e, i) => {
    // const clinicId = sessionStorage.getItem('clinicId');
    if (e.target.value.length > 2) {
      // props.cptSearch({ s: e.target.value, cid: clinicId });
      props.cptSearch({
        cptCode: e.target.value,
        applicationId: 4,
        clinicId: clinicId,
      });
    }
  };

  useEffect(() => {
    if (props.priorAuthData.cptSearchResponse) {
      let Response = props.priorAuthData.cptSearchResponse.response;
      // console.log(Response);
      if (Response.data === null) {
        setCptList([]);
      }
      if (Response.responseCode === 0) {
        let clist = Response.data.map((x) => {
          return {
            // title: x.modifier ? x.cptCode + ' - ' + x.modifier : x.cptCode,
            title: x.cptCode,

            ...x,
          };
        });
        setCptList(clist);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.cptSearchResponse]);

  const addNewRow = () => {
    let cpt = cptArray;
    cpt.push({
      cptCode: '',
      cptDescription: '',
      cptId: '',
      fee: '',
      priorAuthCptId: 0,
      unit: 1,
      isPriorAuthRequired: 1,
      copay: '',
      coinsurance: '',
      type: 2,
      deductible: '',
    });
    setCptArray([...cpt]);
  };

  const removeRow = (index) => {
    if (cptArray.length === 1) {
      if (cptArray[index].priorAuthCptId) {
        props.cptDelete(cptArray[index].priorAuthCptId);
      }
      setCptArray([
        {
          // title: "",
          cptCode: '',
          cptDescription: '',
          cptId: '',
          fee: '',
          priorAuthCptId: 0,
          unit: 1,
          type: 2,
          isPriorAuthRequired: 1,
          copay: '',
          coinsurance: '',
          deductible: '',
          // type: 1,
        },
      ]);
    } else {
      let cpt = cptArray;
      if (cpt[index].priorAuthCptId) {
        props.cptDelete(cpt[index].priorAuthCptId);
      }
      cpt.splice(index, 1);
      setCptArray([...cpt]);
    }
  };
  useEffect(() => {
    if (props.priorAuthData.cptDeleteResponse) {
      let Response = props.priorAuthData.cptDeleteResponse.response;
      if (Response.responseCode === 0) {
        toast.success('CPT Deleted Successfully');
        // props.getPriorAuth({
        //   apptId: data.appointmentId,
        //   priorAuthId: data.priorAuthId,
        // });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.cptDeleteResponse]);

  const handleChangeCpt = (i, y) => {
    if (y === null) {
      let form = cptArray;
      form[i].cptCode = '';
      // form[i].title = "";
      form[i].cptDescription = '';
      form[i].fee = '';
      form[i].cptId = '';
      form[i].unit = 0;
      setCptArray([...form]);
    }
    if (y) {
      let form = cptArray;
      form[i].cptCode = y.cptCode;
      // form[i].title = y.cptCode;
      form[i].cptDescription = y.description;
      form[i].fee = parseFloat(y.fee).toFixed(2);
      form[i].cptId = y.cptId;
      form[i].unit = 1;
      setCptArray([...form]);
    }
  };

  const cptBlur = (i) => {
    let form = cptArray;
    if (form[i].cptId === '') {
      // form[i].title = "";
      form[i].cptCode = '';
    }
    setCptArray([...form]);
  };

  useEffect(() => {
    if (props.priorAuthData.priorAuthInfo) {
      let response = props.priorAuthData.priorAuthInfo.response;
      if (response.responseCode === 0) {
        let data = response.data;
        if (data.priorAuthCptDtoList) {
          let cparray = [];
          data.priorAuthCptDtoList.forEach((x, index) => {
            if (x.coinsurance) {
              cparray.push({ ...x, cptCode: x.cptCode, type: 2 });
            } else if (x.copay) {
              cparray.push({ ...x, cptCode: x.cptCode, type: 1 });
            } else {
              cparray.push({ ...x, cptCode: x.cptCode, type: 2 });
            }
          });
          setCptArray([...cparray]);
        }
        if (data?.priorAuthCptDtoList?.length === 0) {
          setCptArray([
            {
              // title: "",
              cptCode: '',
              cptDescription: '',
              cptId: '',
              fee: '',
              priorAuthCptId: 0,
              unit: 1,
              isPriorAuthRequired: 1,
              copay: '',
              coinsurance: '',
              deductible: '',
              type: 2,
            },
          ]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.priorAuthInfo]);

  useEffect(() => {
    props.cptInformation(cptArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cptArray]);

  // console.log(cptArray[cptIndex], 'cptArray');
  const updateCpt = (cpt) => {
    const arr = [...cptArray];

    const obj = {
      cptCode: cpt.cptCode,
      cptDescription: cpt.description,
      cptId: cpt.cptId,
      fee: cpt.fee,
      priorAuthCptId: 0,
      isPriorAuthRequired: 1,
      copay: '',
      coinsurance: '',
      deductible: '',
      type: 2,
      unit: arr[cptIndex].unit,
    };
    setCptArray((prevCptArray) => {
      const arr = [...prevCptArray];
      arr[cptIndex] = obj;
      return arr;
    });
    // let arr = cptArray;
    // arr[cptIndex] = obj;
    // setCptArray(arr);
    // console.log(cptArray);
  };

  const openDialog = (x, i) => {
    const clinicId = sessionStorage.getItem('clinicId');
    setopenCpt(true);
    setCptIndex(i);
    if (x.cptId) {
      props.cptSearch({
        cptCode: x.cptCode,
        applicationId: 4,
        clinicId: clinicId,
      });
    } else {
      props.cptSearch({
        cptCode: 0,
        applicationId: 4,
        clinicId: 0,
      });
    }
  };
  useEffect(() => {
    if (props.priorAuthData.cptSaveResponse) {
      // console.log('cpt Save Res');
      let Response = props.priorAuthData.cptSaveResponse.response;
      if (Response.responseCode === 0) {
        let data = Response.data;
        let index = cptIndex;
        let cparray = cptArray;
        cparray[index].cptCode = data.cptCode;
        // cparray[index].title = data.cptCode;
        cparray[index].cptDescription = data.description;
        cparray[index].unit = 1;
        cparray[index].fee = parseFloat(data.fee).toFixed(2);
        cparray[index].cptId = data.cptId;
        setCptArray([...cparray]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.cptSaveResponse]);

  const unitChange = (e, i) => {
    let cparray = cptArray;
    cparray[i].unit = e.target.value;
    setCptArray([...cparray]);
  };
  const feeChange = (e, i) => {
    let cparray = cptArray;
    cparray[i].fee = e.target.value;
    setCptArray([...cparray]);
  };
  const copayChange = (e, i) => {
    let cparray = cptArray;
    cparray[i].copay = e.target.value;
    setCptArray([...cparray]);
  };
  const coInsuranceChange = (e, i) => {
    let cparray = cptArray;
    cparray[i].coinsurance = e.target.value;
    setCptArray([...cparray]);
  };
  const typeChange = (e, i) => {
    let cparray = cptArray;
    cparray[i].type = e.target.value;
    cparray[i].copay = '';
    cparray[i].coinsurance = '';
    setCptArray([...cparray]);
  };

  const deductibleChange = (e, i) => {
    let cparray = cptArray;
    cparray[i].deductible = e.target.value;
    setCptArray([...cparray]);
  };
  const priorChange = (e, i) => {
    let cparray = cptArray;
    cparray[i].isPriorAuthRequired = e.target.value === true ? 1 : 0;
    setCptArray([...cparray]);
  };

  const handleCheckbox = (e, i, num) => {
    if (num === 1) {
      let cparray = cptArray;
      if (e.target.checked === true) {
        cparray[i].cptIcd1Map = 1;
      } else {
        cparray[i].cptIcd1Map = 0;
      }
      setCptArray([...cparray]);
    }
    if (num === 2) {
      let cparray = cptArray;
      if (e.target.checked === true) {
        cparray[i].cptIcd2Map = 1;
      } else {
        cparray[i].cptIcd2Map = 0;
      }
      setCptArray([...cparray]);
    }
    if (num === 3) {
      let cparray = cptArray;
      if (e.target.checked === true) {
        cparray[i].cptIcd3Map = 1;
      } else {
        cparray[i].cptIcd3Map = 0;
      }
      setCptArray([...cparray]);
    }
    if (num === 4) {
      let cparray = cptArray;
      if (e.target.checked === true) {
        cparray[i].cptIcd4Map = 1;
      } else {
        cparray[i].cptIcd4Map = 0;
      }
      setCptArray([...cparray]);
    }
    if (num === 5) {
      let cparray = cptArray;
      if (e.target.checked === true) {
        cparray[i].cptIcd5Map = 1;
      } else {
        cparray[i].cptIcd5Map = 0;
      }
      setCptArray([...cparray]);
    }
    if (num === 6) {
      let cparray = cptArray;
      if (e.target.checked === true) {
        cparray[i].cptIcd6Map = 1;
      } else {
        cparray[i].cptIcd6Map = 0;
      }
      setCptArray([...cparray]);
    }
    if (num === 7) {
      let cparray = cptArray;
      if (e.target.checked === true) {
        cparray[i].cptIcd7Map = 1;
      } else {
        cparray[i].cptIcd7Map = 0;
      }
      setCptArray([...cparray]);
    }
    if (num === 8) {
      let cparray = cptArray;
      if (e.target.checked === true) {
        cparray[i].cptIcd8Map = 1;
      } else {
        cparray[i].cptIcd8Map = 0;
      }
      setCptArray([...cparray]);
    }
  };

  const checkDuplicateCpt = (option) => {
    const cptIdArray = cptArray.map((cpt) => cpt.cptId);
    return cptIdArray.includes(option.cptId);
  };

  return (
    <div>
      <TableContainer
        component={Paper}
        elevation={0}
        className="cpt_table_container scrollbarY--custom"
      >
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellhead} align="left">
                CPT *
              </TableCell>
              {/* <TableCell className={classes.cellhead} align="left">
                Description
              </TableCell> */}
              <TableCell className={classes.cellhead} align="left">
                Units*
              </TableCell>
              {/* <TableCell className={classes.cellhead} align="left">
                Fee*
              </TableCell>
              <TableCell className={classes.cellhead} align="left">
                Amount
              </TableCell> */}
              <TableCell className={classes.cellhead} align="left">
                PriorAuth
              </TableCell>
              <TableCell className={classes.cellhead} align="left">
                Type
              </TableCell>
              <TableCell className={classes.cellhead} align="left">
                Copay/Coins
              </TableCell>
              <TableCell className={classes.cellhead} align="left">
                Deductible
              </TableCell>

              <TableCell className={classes.cellhead} align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cptArray.map((x, i) => (
              <>
                <TableRow className={classes.root}>
                  <TableCell className={classes.cellinfo} align="left">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100px',
                        overflow: 'hidden !important',
                        marginRight: '2px',
                      }}
                    >
                      <Autocomplete
                        fullWidth
                        freesolo
                        options={cptList}
                        getOptionLabel={(option) => option.title}
                        getOptionSelected={(option, value) =>
                          option.title === value.title
                        }
                        getOptionDisabled={(option) =>
                          checkDuplicateCpt(option)
                        }
                        value={{ title: cptArray[i].cptCode }}
                        onChange={(e, y) => handleChangeCpt(i, y)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{
                              width: '109px',
                              paddingRight: '2px !important',
                            }}
                            label="Search"
                            onChange={(e) => {
                              let arr = cptArray;
                              arr[i].cptCode = e.target.value;
                              setCptArray(arr);
                              cptSearchFn(e, i);
                            }}
                            onBlur={() => cptBlur(i)}
                          />
                        )}
                      />
                      {x.cptId ? (
                        <EditIcon
                          color="primary"
                          fontSize="small"
                          onClick={() => openDialog(x, i)}
                          className={'cpt_add_icon'}
                        />
                      ) : (
                        <AddCircleOutlineIcon
                          color="primary"
                          fontSize="small"
                          onClick={() => openDialog(x, i)}
                          className={'cpt_add_icon'}
                        />
                      )}
                    </div>
                  </TableCell>
                  {/* <TableCell className={classes.cellinfo} align="left">
                  <div>
                    <Input
                      className="input_material_field"
                      type="text"
                      value={x.cptDescription}
                    />
                  </div>
                </TableCell> */}
                  <TableCell className={classes.cellinfo} align="">
                    <div
                      style={{
                        width: '45px',
                        paddingTop: '14px',
                        marginLeft: '7px',
                      }}
                    >
                      <Input
                        className="input_material_field"
                        type="text"
                        value={x.unit}
                        onChange={(e) => unitChange(e, i)}
                      />
                    </div>
                  </TableCell>
                  {/* <TableCell className={classes.cellinfo} align="left">
                    <div style={{ width: '65px', paddingTop: '14px' }}>
                      <Input
                        className="input_material_field"
                        type="text"
                        value={x.fee}
                        onChange={(e) => feeChange(e, i)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </TableCell> */}
                  {/* <TableCell className={classes.cellinfo} align="left">
                    <div
                      style={{
                        maxWidth: '76px',
                        minWidth: '40px',
                        paddingTop: '14px',
                      }}
                    >
                      <Input
                        className="input_material_field"
                        value={
                          x.fee && x.unit
                            ? parseFloat(
                                parseInt(x.fee) * parseInt(x.unit)
                              ).toFixed(2)
                            : ''
                        }
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </TableCell> */}
                  {/* <TableCell className={classes.cellinfo} align="left">
                  <Checkbox
                    checked={x.cptIcd1Map === 1 ? true : false}
                    onChange={(e) => handleCheckbox(e, i, 1)}
                  />
                </TableCell>
                <TableCell className={classes.cellinfo} align="left">
                  <Checkbox
                    checked={x.cptIcd2Map === 1 ? true : false}
                    onChange={(e) => handleCheckbox(e, i, 2)}
                  />
                </TableCell>
                <TableCell className={classes.cellinfo} align="left">
                  <Checkbox
                    checked={x.cptIcd3Map === 1 ? true : false}
                    onChange={(e) => handleCheckbox(e, i, 3)}
                  />
                </TableCell>
                <TableCell className={classes.cellinfo} align="left">
                  <Checkbox
                    checked={x.cptIcd4Map === 1 ? true : false}
                    onChange={(e) => handleCheckbox(e, i, 4)}
                  />
                </TableCell>
                <TableCell className={classes.cellinfo} align="left">
                  <Checkbox
                    checked={x.cptIcd5Map === 1 ? true : false}
                    onChange={(e) => handleCheckbox(e, i, 5)}
                  />
                </TableCell>
                <TableCell className={classes.cellinfo} align="left">
                  <Checkbox
                    checked={x.cptIcd6Map === 1 ? true : false}
                    onChange={(e) => handleCheckbox(e, i, 6)}
                  />
                </TableCell>
                <TableCell className={classes.cellinfo} align="left">
                  <Checkbox
                    checked={x.cptIcd7Map === 1 ? true : false}
                    onChange={(e) => handleCheckbox(e, i, 7)}
                  />
                </TableCell>
                <TableCell className={classes.cellinfo} align="left">
                  <Checkbox
                    checked={x.cptIcd8Map === 1 ? true : false}
                    onChange={(e) => handleCheckbox(e, i, 8)}
                  />
                </TableCell>
                <TableCell className={classes.cellinfo} align="left">
                  <div style={{ width: "60px" }}>
                    <Input className="input_material_field" type="text" />
                  </div>
                </TableCell>
                <TableCell className={classes.cellinfo} align="left">
                  <div style={{ width: "60px" }}>
                    <Input className="input_material_field" type="text" />
                  </div>
                </TableCell> */}
                  <TableCell className={classes.cellinfo} align="left">
                    <div style={{ width: '100px' }}>
                      <Dropdown
                        options={[
                          { name: 'Unknown', value: 0 },
                          { name: 'Required', value: 1 },
                          { name: 'Not Required', value: 2 },
                        ]}
                        value={x.isPriorAuthRequired}
                        onChange={(e) => priorChange(e, i)}
                        style={{
                          maxWidth: '150px',
                          minWidth: '110px',
                          // paddingTop: '14px',
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell className={classes.cellinfo} align="left">
                    <div
                      style={{
                        maxWidth: '76px',
                        minWidth: '50px',
                      }}
                    >
                      <Dropdown
                        options={[
                          { name: 'Copay', value: 1 },
                          { name: 'CoInsurance', value: 2 },
                        ]}
                        value={x.type}
                        onChange={(e) => typeChange(e, i)}
                        // style={{ marginBottom: '9px' }}
                      />
                    </div>
                  </TableCell>
                  <TableCell className={classes.cellinfo} align="left">
                    <div
                      style={{
                        width: '90px',
                        // border: '1px solid red',
                        marginTop: '12px',
                      }}
                    >
                      {x.type === 1 ? (
                        <Input
                          className="input_material_field"
                          type="text"
                          value={x.copay}
                          onChange={(e) => copayChange(e, i)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      ) : (
                        <Input
                          className="input_material_field"
                          type="text"
                          value={x.coinsurance}
                          onChange={(e) => coInsuranceChange(e, i)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      {/* {x.type === 1 && (
                        <Input
                          className="input_material_field"
                          type="text"
                          value={x.copay}
                          onChange={(e) => copayChange(e, i)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      )} */}
                      {/* {
                        <Input
                          className="input_material_field"
                          type="text"
                          value={x.coinsurance}
                          onChange={(e) => coInsuranceChange(e, i)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            ),
                          }}
                        />
                      } */}
                    </div>
                  </TableCell>
                  <TableCell className={classes.cellinfo} align="left">
                    <div style={{ width: '76px', marginTop: '12px' }}>
                      <Input
                        className="input_material_field"
                        type="text"
                        value={x.deductible}
                        onChange={(e) => deductibleChange(e, i)}
                      />
                    </div>
                  </TableCell>
                  {/* <TableCell
                    className={classes.cellinfo}
                    align="left"
                  ></TableCell>
                  <TableCell
                    className={classes.cellinfo}
                    align="left"
                  ></TableCell> */}
                  <TableCell className={classes.cellinfo}>
                    <IconButton aria-label="expand row" size="small">
                      <CancelIcon
                        color="primary"
                        onClick={() => removeRow(i)}
                      />

                      {cptArray.length === i + 1 && (
                        <AddCircleOutlineIcon
                          color="primary"
                          onClick={() => addNewRow()}
                        />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={15}>
                    <div
                      style={{ paddingTop: '10px' }}
                      className="cpt_des_container"
                    >
                      {x.cptDescription}
                    </div>
                  </TableCell>
                </TableRow>
              </>
            ))}
            {/* <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={6}
              >
                <Collapse in={false} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    <Typography variant="h6" gutterBottom component="div">
                      data
                    </Typography>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
      <CptDialog
        clinicId={clinicId}
        updateCpt={updateCpt}
        open={openCpt}
        handleClose={() => setopenCpt(false)}
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  priorAuthData: state.priorAuth,
});

const mapDispatchToProps = (dispatch) => ({
  cptSearch: (values) => dispatch(cptSearch(values)),
  cptDelete: (values) => dispatch(cptDelete(values)),
  getPriorAuth: (values) => dispatch(getPriorAuth(values)),
});
CptTable.propTypes = {
  cptSearch: PropTypes.func,
  cptDelete: PropTypes.func,
  getPriorAuth: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(CptTable);
