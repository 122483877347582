import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  Grid,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import {
  searchPayor,
  getCoderResponse,
  policySave,
} from '../../../../Redux/CoderApis/main.actions';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import Input from '../../../../Input';
import { Dropdown } from '../../../../drop-down/src/lib/index';
import CloseIcon from '@material-ui/icons/Close';
import copy from 'copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PayerDialog from '../PayerDialog';
import './style.css';
const ranking = [
  { name: 'Primary', value: 1 },
  { name: 'Secondary', value: 2 },
  { name: 'Tertiary', value: 3 },
];

const status = [
  { name: 'Active', value: 1 },
  { name: 'Inactive', value: 0 },
];

function PolicyInfo(props) {
  const [btnStatus, setBtnStatus] = useState(false);
  const [mode, setMode] = useState('ViewMode');
  const [payorOpen, setPayorOpen] = useState(false);
  const [form, setForm] = useState({
    active: 1,
    activeCoverageCleared: true,
    clarificationPresent: true,
    clinicId: 0,
    groupNo: '',
    id: 0,
    note: '',
    patientId: 0,
    payerAddressId: 0,
    payerId: 0,
    payerName: '',
    plan: '',
    policyHolderId: '0',
    policyHolderName: '',
    policyHolderRelation: 0,
    policyNo: '',
    policyType: 1,
    ranking: props.currentObject.ranking,
  });
  const [errors, setErrors] = useState({
    policyNo: '',
    groupNo: '',
  });
  const [policyHolders, setPolicyHolders] = useState([]);
  const [payorList, setPayorList] = useState([]);
  const [primary, setPrimary] = useState({ id: 0, payerName: '' });
  let clinicId = sessionStorage.getItem('clinicId');
  useEffect(() => {
    // // Check if clinicId is already present in props, otherwise get it from session storage
    // if (!clinicId) {
    //   const storedClinicId = sessionStorage.getItem('clinicId');
    //   if (storedClinicId) {
    //     clinicId = storedClinicId; // This assumes clinicId can be set in props
    //   }
    // }
    if (props.coderData.patientInfo) {
      let response = props.coderData.patientInfo.response;

      if (response.responseCode === 0) {
        let data = response.data;
        localStorage.setItem('currentPatient', JSON.stringify(data.patientDto));
        localStorage.setItem(
          'patientPolicy',
          JSON.stringify(data.policyDtoList)
        );
        let ph = [];
        if (data.policyHolderDtoList?.length === 0) {
          ph.push({
            name: data.patientDto.firstName + ' ' + data.patientDto.lastName,
            value: 0,
            relation: 1,
          });
          setPolicyHolders(ph);
        } else {
          data.policyHolderDtoList?.map((x) =>
            ph.push({
              name: x.firstName + ' ' + x.lastName,
              value: x.id,
              ...x,
            })
          );
          setPolicyHolders(ph);
        }
        props.getCoderResponse(null);
        // localStorage.setItem('clinic_id', data.clinicId);

        // const selectedPolicy = data.policyDtoList.find(
        //   (policy) => policy.ranking === 1
        // );
        // const payerName = selectedPolicy ? selectedPolicy.payerName : null;
        // props.setRowInfo({
        //   ...props.rowInfo,
        //   primaryPayorName: payerName,
        // });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.patientInfo]);

  useEffect(() => {
    if (props.coderData.payorlist) {
      let response = props.coderData.payorlist.response;
      if (response.responseCode === 0) {
        let data = response.data;
        setPayorList(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.payorlist]);

  const searchPayorFn = (v) => {
    props.searchPayor({
      str: v,
      id: props.clinicId,
    });
  };

  const validatePolicy = (value) => {
    if (!/^[A-Za-z0-9_-]*$/.test(value)) {
      setErrors({ ...errors, policyNo: 'Enter a valid Policy' });
    } else {
      setErrors({ ...errors, policyNo: '' });
    }
  };
  const validateGroup = (value) => {
    if (!/^[A-Za-z0-9_-]*$/.test(value)) {
      setErrors({ ...errors, groupNo: 'Enter a valid Group Number' });
    } else {
      setErrors({ ...errors, groupNo: '' });
    }
  };

  const handleSubmit = (e) => {
    setBtnStatus(true);
    e.preventDefault();
    // Check if clinicId is defined
    // const clinicId = clinicId;
    // if (clinicId === 'undefined') {
    //   console.error('Clinic ID is undefined');
    //   toast.error('Error occured ');
    //   return;
    // }
    if (form.active === 1) {
      if (errors.policyNo === '' && errors.groupNo === '') {
        props.policySave({
          data: {
            ...form,
            clinicId: props.clinicId,
            patientId: props.rowInfo.patientId,
            payerId: primary.id,
            payerName: primary.payerName,
            policyHolderId: parseInt(form.policyHolderId),
            policyHolderName: policyHolders
              ? policyHolders.find(
                  (x) => parseInt(x.value) === parseInt(form.policyHolderId)
                ).name
              : '',
            policyHolderRelation:
              parseInt(form.policyHolderId) === 0
                ? 7
                : form.policyHolderRelation,
          },
          applicationId: props.applicationId,
        });
      }
    } else {
      if (errors.policyNo === '' && errors.groupNo === '') {
        props.policySave({
          data: {
            ...form,
            clinicId: props.clinicId,
            patientId: props.rowInfo.patientId,
            payerId: primary.id,
            payerName: primary.payerName,
            policyHolderId: parseInt(form.policyHolderId),
            policyHolderName: policyHolders.find(
              (x) => parseInt(x.value) === parseInt(form.policyHolderId)
            ).name,
            policyHolderRelation:
              parseInt(form.policyHolderId) === 0
                ? 7
                : form.policyHolderRelation,
          },
          applicationId: props.applicationId,
        });
      }
      setForm({
        ...form,
        active: 2,
        activeCoverageCleared: true,
        clarificationPresent: true,
        clinicId: props.clinicId,
        groupNo: '',
        id: 0,
        note: '',
        patientId: 0,
        payerAddressId: 0,
        payerId: 0,
        payerName: '',
        plan: '',
        policyHolderId: '0',
        policyHolderName: '',
        policyHolderRelation: 7,
        policyNo: '',
        policyType: 1,
        ranking: props.currentObject.ranking,
      });
      setPrimary({ payerName: '' });
      // console.log(form);
    }
  };
  // console.log(props.applicationId,"appt id");
  // console.log(form.active)
  // console.log(clinicId, 'clinic id from session storage');
  useEffect(() => {
    if (props.coderData.policySaveResponse) {
      let response = props.coderData.policySaveResponse.response;
      if (response.responseCode === 0) {
        let data = response.data;
        setMode('ViewMode');
        // console.warn('clinic id inside effect ', clinicId);
        if (mode === 'EditMode') {
          console.log(props.rowInfo, 'row info frm Editmode');

          if (form.id === 0) {
            if (btnStatus === true) {
              setForm({ ...form, id: data.id });
              setBtnStatus(false);
              // console.log(props.rowInfo, 'row info frm ');
              if (form.active === 1) {
                toast.success('Insurance added successfully');
              } else {
                toast.success('Insurance deactivated successfully');
              }
              if (form.ranking === 1) {
                props.setRowInfo({
                  ...props.rowInfo,
                  primaryPayorName: primary.payerName,
                });
                // sessionStorage.setItem(
                //   'currentRow',
                //   JSON.stringify({
                //     ...props.rowInfo,
                //     primaryPayorName: primary.payerName,
                //   })
                // );
                if (props.applicationId === 4) {
                  let currentRow = JSON.parse(
                    sessionStorage.getItem('currentRow')
                  );
                  sessionStorage.setItem(
                    'currentRow',
                    JSON.stringify({
                      // ...props.rowInfo,
                      ...currentRow,
                      primaryPayorName: primary.payerName,
                    })
                  );
                } else {
                  sessionStorage.setItem(
                    'currentRow',
                    JSON.stringify({
                      ...props.rowInfo,
                      // ...currentRow,
                      primaryPayorName: primary.payerName,
                    })
                  );
                }
              }
            }
          } else {
            setBtnStatus(false);
            toast.success('Insurance updated successfully');
            let currentRow = JSON.parse(sessionStorage.getItem('currentRow'));
            if (form.ranking === 1) {
              props.setRowInfo({
                ...props.rowInfo,
                primaryPayorName: primary.payerName,
              });
              if (props.applicationId === 4) {
                sessionStorage.setItem(
                  'currentRow',
                  JSON.stringify({
                    // ...props.rowInfo,
                    ...currentRow,
                    primaryPayorName: primary.payerName,
                  })
                );
              } else {
                sessionStorage.setItem(
                  'currentRow',
                  JSON.stringify({
                    ...props.rowInfo,
                    // ...currentRow,
                    primaryPayorName: primary.payerName,
                  })
                );
              }
            }
          }
          if (props.applicationId === 6) {
            let currentRow = JSON.parse(sessionStorage.getItem('currentRow'));
            currentRow.insuranceId = data.id;
            sessionStorage.setItem('currentRow', JSON.stringify(currentRow));
          }
        }
      }
    }
  }, [props.coderData.policySaveResponse]);

  useEffect(() => {
    if (props.currentObject) {
      setForm(props.currentObject);
      setPrimary({
        payerName: props.currentObject.payerName,
        id: props.currentObject.payerId,
      });
    }
  }, [props.currentObject]);

  const handleCancel = () => {
    setMode('ViewMode');
  };

  const payerClick = () => {
    setPayorOpen(true);
  };

  const payorClose = () => {
    setPayorOpen(false);
  };

  const savedPayerInfo = (e) => {
    setPrimary({
      id: e.value,
      payerName: e.title,
    });
  };

  return (
    <div>
      <Dialog open={payorOpen}>
        <PayerDialog
          isOpen={payorOpen}
          onClose={payorClose}
          rowInfo={props.rowInfo}
          savedPayerInfo={(e) => savedPayerInfo(e)}
        />
      </Dialog>
      {mode === 'EditMode' && (
        <div className="EditMode">
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className="ins_field_set">
                  <Autocomplete
                    fullWidth
                    options={payorList}
                    onChange={(e, v) => {
                      setPrimary({
                        id: v?.id,
                        payerName: v?.payerName,
                      });
                    }}
                    value={primary}
                    getOptionLabel={(option) => {
                      return option.payerName;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        variant="outlined"
                        label="Insurance"
                        placeholder="Search insurance"
                        onChange={(e) => searchPayorFn(e.target.value)}
                      />
                    )}
                  />
                  <CloseIcon
                    onClick={() => setPrimary({ payerName: '' })}
                    color="primary"
                    fontSize="small"
                    className={'insurance_closeIcon'}
                  />
                  <AddCircleIcon
                    fontSize="small"
                    color="primary"
                    className="insurance_addicon"
                    onClick={payerClick}
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <Input
                  required
                  // className="input_material_field"
                  variant="outlined"
                  type="text"
                  label="Policy Number"
                  value={form.policyNo}
                  onBlur={(e) => validatePolicy(e.target.value)}
                  onChange={(e) =>
                    setForm({ ...form, policyNo: e.target.value })
                  }
                  error={errors.policyNo ? true : false}
                  helperText={errors.policyNo}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Click here to Copy">
                          <FileCopyOutlinedIcon
                            className="copyIcon"
                            fontSize="small"
                            onClick={() => copy(form.policyNo)}
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  // className="input_material_field"
                  variant="outlined"
                  type="text"
                  label="Group Number"
                  value={form.groupNo}
                  onChange={(e) =>
                    setForm({ ...form, groupNo: e.target.value })
                  }
                  onBlur={(e) => validateGroup(e.target.value)}
                  error={errors.groupNo ? true : false}
                  helperText={errors.groupNo}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Click here to Copy">
                          <FileCopyOutlinedIcon
                            className="copyIcon"
                            fontSize="small"
                            onClick={() => copy(form.groupNo)}
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Dropdown
                  required
                  value={form.policyHolderId}
                  onChange={(e) =>
                    setForm({ ...form, policyHolderId: e.target.value })
                  }
                  options={policyHolders}
                  label="Policy Holder"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <Dropdown
                  value={form.ranking}
                  options={ranking}
                  label="Ranking"
                  variant="outlined"
                  onChange={(e) =>
                    setForm({ ...form, ranking: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Dropdown
                  value={form.active}
                  onChange={(e) => setForm({ ...form, active: e.target.value })}
                  options={status}
                  label="Status"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <div className="edit_btn_group">
                  <Button
                    style={{ marginTop: '10px' }}
                    onClick={handleCancel}
                    variant="contained"
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <div className="sae_btn_patientInfo">
                    <Button
                      style={{ marginTop: '10px' }}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
      {mode === 'ViewMode' && (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div className={'ViewMode'}>
              <div className="ins_field_set">
                <span
                  style={{
                    fontSize: '16px',
                    marginTop: '21px',
                    marginRight: '5px',
                    marginLeft: '-18px',
                  }}
                >
                  {form.ranking}.
                </span>
                <Autocomplete
                  fullWidth
                  options={payorList}
                  value={primary}
                  getOptionLabel={(option) => option.payerName}
                  renderInput={(params) => (
                    <TextField
                      className="insuranceName"
                      {...params}
                      required
                      InputProps={{
                        disableUnderline: true,
                      }}
                      variant="standard"
                      label="Insurance"
                      placeholder="Search insurance"
                    />
                  )}
                />
                {/* <CloseIcon
                  onClick={() => setPrimary({ payerName: '' })}
                  color="primary"
                  // style={{ paddingLeft: '10px' }}
                  fontSize="small"
                  className={'insurance_closeIcon'}
                /> */}
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={'none'}>
              <div className="input_field_view_mode">
                <span className="label">Policy No.</span>
                <div className="input_field_content_wrap">
                  <span
                    className="input_field_content"
                    style={{ paddingTop: '6px' }}
                  >
                    {form.policyNo}
                  </span>
                  <span
                    style={{
                      height: '25px',
                      paddingTop: '6px',
                    }}
                  >
                    {form.policyNo && (
                      <Tooltip title="Click here to Copy">
                        <FileCopyOutlinedIcon
                          className="copyIcon"
                          fontSize="small"
                          color="secondary"
                          onClick={() => copy(form.policyNo)}
                        />
                      </Tooltip>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="input_field_view_mode">
              <span className="label">Group No</span>
              <div className="input_field_content_wrap">
                <span
                  className="input_field_content"
                  style={{ paddingTop: '6px' }}
                >
                  {form.groupNo}
                </span>
                <span
                  style={{
                    height: '25px',
                    paddingTop: '6px',
                  }}
                >
                  {form.groupNo && (
                    <Tooltip title="Click here to Copy">
                      <FileCopyOutlinedIcon
                        className="copyIcon"
                        color="secondary"
                        fontSize="small"
                        onClick={() => copy(form.groupNo)}
                      />
                    </Tooltip>
                  )}
                </span>
              </div>
            </div>
            {/* <Input
              className="input_material_field"
              variant="standard"
              type="text"
              label="Group No."
              value={form.groupNo}
              onBlur={(e) => validateGroup(e.target.value)}
              error={errors.groupNo ? true : false}
              helperText={errors.groupNo}
              margin="normal"
              InputProps={{
                disableUnderline: true,

                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Click here to Copy">
                      <FileCopyOutlinedIcon
                        className="copyIcon"
                        onClick={() => copy(form.groupNo)}
                      />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            /> */}
          </Grid>
          <Grid item xs={2}>
            <div className="edit_btn_patientInfo edit_btn_policyInfo">
              <Button
                variant={'outlined'}
                color="primary"
                onClick={() => setMode('EditMode')}
              >
                Edit
              </Button>
            </div>
          </Grid>
          {/* <Grid item xs={5}>
            <Grid container spacing={1}> */}
          {/* <Grid item xs={3}>
            <div className={'ViewMode'}>
              <Dropdown
                value={form.policyHolderId}
                options={policyHolders}
                label="Policy Holder"
                InputProps={{
                  disableUnderline: true,
                }}
                variant="standard"
              />
            </div>
          </Grid> */}
          {/* <Grid item xs={4}>
            <div className={'ViewMode'}>
              <Input
                label="ranking"
                value={
                  form.ranking !== 0
                    ? ranking.find((value) => value.value === form.ranking).name
                    : ''
                }
                InputProps={{
                  disableUnderline: true,
                }}
                variant="standard"
                className="ranking"
              />
            </div>
          </Grid> */}
          {/* </Grid>
          </Grid> */}

          {/* <Grid item xs={5}>
            <div className="edit_btn_patientInfo edit_btn_policyInfo">
              <Button
                variant={'outlined'}
                color="primary"
                onClick={() => setMode('EditMode')}
              >
                Edit
              </Button>
            </div>
          </Grid> */}
        </Grid>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  searchPayor: (values) => dispatch(searchPayor(values)),
  getCoderResponse: (values) => dispatch(getCoderResponse(values)),
  policySave: (values) => dispatch(policySave(values)),
});
PolicyInfo.propTypes = {
  searchPayor: PropTypes.func,
  getCoderResponse: PropTypes.func,
  policySave: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(PolicyInfo);
