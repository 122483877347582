import React, { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import {
  getPriorAuth,
  getPriorAuthResponse,
  paHistory,
} from 'Redux/PriorAuthApis/prior.actions';
import { getCache } from 'Redux/Appointment/apt.actions';
import PropTypes from 'prop-types';
import Header from 'Components/Header';
import CallInfo from 'Components/LogInfo';
import ProcedureDetails from 'Components/ProcedureDetails';
import UploadInformation from 'Components/UploadInfo';
import Loader from 'Components/loader';
import AppLogout from 'Utils/inactive';
import PriorAuthHistoDialog from 'Components/AppointmentHistory';
import { PatientSelector } from '../../../../../libs/patient-selector/src';
import { TabsPanel } from '../../../../../libs/tabs-panel/src';
import classNames from 'classnames';
import './EditStyle.css';

function EditContainer(props) {
  const [loader, setLoader] = useState(true);
  const [priorAuthInfo, setPriorAuthInfo] = useState(null);
  const [paHistoryOpen, setPaHistoryOpen] = useState(false);
  const [dos, setDos] = useState('');
  const [isLogAdded, setIsLogAdded] = useState(false);
  const [apptType, setApptType] = useState('');
  const [patientName, setPatientName] = useState('');
  const [paramsData, setParamsData] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [patientInfo, setpatientInfo] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let id = window.location.href.split('?')[1];
    let obj = { ...paramsData };
    let queryString = new URLSearchParams(id);
    for (let pair of queryString.entries()) {
      obj[pair[0]] = window.atob(pair[1]);
    }
    setParamsData(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     localStorage.removeItem('apptHistory');
  //     let currentList = JSON.parse(localStorage.getItem('currentList'));
  //     localStorage.setItem('currentRow', JSON.stringify(currentList[0]));
  //     localStorage.setItem('currentSelection', JSON.stringify(currentList[0]));
  //     console.log(currentList[0]);
  //     updateUrl(currentList[0]);
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  useEffect(() => {
    if (props.priorAuthData.logInfoResponse) {
      let Response = props.priorAuthData.logInfoResponse.response;
      if (Response.responseCode === 0) {
        setIsLogAdded(!isLogAdded);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.logInfoResponse]);

  useEffect(() => {
    if (
      props.priorAuthData.priorAuthInfo &&
      props.priorAuthData.priorAuthInfo.response &&
      props.priorAuthData.priorAuthInfo.response.data
    ) {
      let response = props.priorAuthData.priorAuthInfo.response;
      let data = props.priorAuthData.priorAuthInfo.response.data;
      if (response.responseCode === 0) {
        setPriorAuthInfo(data);
        setLoader(false);
      }
      let apptOpen = parseInt(sessionStorage.getItem('apptOpen'));
      if (data.isPatientAppointmentExist === true) {
        props.paHistory(data.patientId);
        if (apptOpen === 1) {
          setPaHistoryOpen(false);
        } else {
          setPaHistoryOpen(true);
          setApptType(data.appointmentType);
          setDos(data.dos);
          setPatientName(data.patientName);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.priorAuthInfo]);

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem('currentPriorAuth'))) {
      let data = JSON.parse(sessionStorage.getItem('currentPriorAuth'));
      props.getPriorAuth({
        apptId: data.appointmentId,
        priorAuthId: data.priorAuthId,
      });
    } else {
      let data = JSON.parse(sessionStorage.getItem('currentRow'));
      props.getPriorAuth({
        apptId: data.appointmentId,
        priorAuthId: data.priorAuthId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUrl = (paramsData) => {
    setParamsData(paramsData);
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    params.set('patientId', window.btoa(paramsData.patientId));
    params.set('providerId', window.btoa(paramsData.providerId));
    params.set('facilitytId', window.btoa(paramsData.facilityId));
    params.set('priorAuthId', window.btoa(paramsData.priorAuthId));
    params.set('clinicId', window.btoa(paramsData.clinicId));
    params.set('appointmentId', window.btoa(paramsData.appointmentId));
    url.search = params.toString();
    window.history.replaceState({}, '', url.href);
  };

  useEffect(() => {
    if (paramsData) {
      const allItems = JSON.parse(localStorage.getItem('allItems'));
      const currentList = JSON.parse(localStorage.getItem('currentList'));
      const apptHist = JSON.parse(sessionStorage.getItem('apptHistory'));
      if (
        paramsData?.priorAuthId?.toString() ===
        apptHist?.priorAuthId?.toString()
      ) {
        apptHist && setpatientInfo(apptHist);
        // console.log(apptHist);
      } else {
        let appt = null;
        appt = allItems.find(
          (item) =>
            parseInt(item.appointmentId) === parseInt(paramsData.appointmentId)
        );
        if (appt === undefined) {
          appt = currentList.find(
            (item) =>
              parseInt(item.appointmentId) ===
              parseInt(paramsData.appointmentId)
          );
        }
        appt && setpatientInfo(appt);
        // console.log(appt);
      }
      updateUrl(paramsData);
    }
  }, [paramsData]);

  return (
    <AppLogout>
      <div className="wrapper">
        <PriorAuthHistoDialog
          open={paHistoryOpen}
          handleClose={() => setPaHistoryOpen(false)}
          dos={dos}
          updateUrl={updateUrl}
          apptType={apptType}
          name={patientName}
          warning={
            'Warning : Potential duplicate visits exit; check the list below for duplicate.'
          }
        />
        <div className={isDrawerOpen ? 'leftTabOpen' : 'leftTabClosed'}>
          <div className="left_tab_inner_wrap">
            <PatientSelector
              totalCount={totalCount}
              setTotalCount={setTotalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowData={paramsData}
              setRowData={setParamsData}
              applicationId={4}
              isLogAdded={isLogAdded}
            />
          </div>
        </div>
        <div className={isDrawerOpen ? 'container_min' : 'container_max'}>
          {loader === false && (
            <div className="headerComponent">
              <Header
                name={'Edit Prior Auth'}
                backButton={true}
                visible={(e) => props.visible(e)}
                setRowInfo={setParamsData}
                rowInfo={paramsData}
                patientInfo={patientInfo}
                priorInfo={priorAuthInfo && priorAuthInfo}
              />
            </div>
          )}

          {loader === false && (
            <div className="mainContainer">
              {/* <Drawer variant="persistent" open={true}> */}

              {/* </Drawer> */}
              <div
                className={classNames('main_edit_section', {
                  main_edit_section_drawer_open: isDrawerOpen,
                })}
              >
                <div className="procedure_info_container">
                  <ProcedureDetails
                    updateUrl={updateUrl}
                    rowInfo={paramsData}
                  />
                </div>
                <div className="procedure_info_container">
                  <CallInfo />
                </div>
                <div className="fileUpload_info_container">
                  <UploadInformation rowInfo={paramsData} />
                </div>
              </div>
              <div
                className={classNames('rightTab', {
                  right_tab_drawer_open: isDrawerOpen,
                })}
              >
                <TabsPanel
                  rowData={paramsData}
                  setRowInfo={setParamsData}
                  applicationId={4}
                  isLogAdded={isLogAdded}
                />
              </div>
            </div>
          )}
          {loader && <Loader />}
        </div>
      </div>
    </AppLogout>
  );
}

const mapStateToProps = (state) => ({
  priorAuthData: state.priorAuth,
});

const mapDispatchToProps = (dispatch) => ({
  getPriorAuth: (values) => dispatch(getPriorAuth(values)),
  getPriorAuthResponse: (values) => dispatch(getPriorAuthResponse(values)),
  getCache: (values) => dispatch(getCache(values)),
  paHistory: (values) => dispatch(paHistory(values)),
});
EditContainer.propTypes = {
  getPriorAuth: PropTypes.func,
  getPriorAuthResponse: PropTypes.func,
  getCache: PropTypes.func,
  paHistory: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditContainer);
