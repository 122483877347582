import {
  Divider,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { useEffect, useState, useMemo } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import './style.css';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Pagination } from '@material-ui/lab';
import {
  arManagementList,
  coderList,
  eligibilityList,
  priorAuthList,
} from './../../../Redux/CoderApis/main.actions';
import { dateToyyymmdd } from '../../../helper-functions/src/lib/TimeDateTimestamp';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  indicator: {
    display: 'none',
    width: '0',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      // maxWidth: 600,
      width: '100%',
      fontWeight: 'bolder',
      backgroundColor: 'black',
    },
  },
  root: {
    borderTop: 'none',
    borderTopRightRadius: '0px',
    borderTopLeftRadius: '0px',
    paddingLeft: '10px',
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    // height: '75px',
    marginBottom: '0px',
    borderBottom: '1px solid lightGray',
    // borderTop: '1px solid lightGray',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
      // backgroundColor: '#dff5f0',
      border: 'none',
      // borderRadius: '10px',
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export function PatientSelector(props) {
  const [patientList, setPatientList] = useState([]);
  const [value, setValue] = useState(0);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [currentRow, setCurrentRow] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [currentList, setCurrentList] = useState([]);
  const [apptHistory, setapptHistory] = useState({});

  useEffect(() => {
    if (sessionStorage.getItem('currentRow')) {
      const item = JSON.parse(sessionStorage.getItem('currentRow'));
      const selectedItem = JSON.parse(
        sessionStorage.getItem('currentSelection')
      );
      const appt = JSON.parse(sessionStorage.getItem('apptHistory'));
      setapptHistory(appt);
      setCurrentRow(item);
      setSelectedPatient(selectedItem);
    }
  }, [props.rowData]);

  useEffect(() => {
    const f = JSON.parse(localStorage.getItem('filterData'));
    const ownerId = localStorage.getItem('ownerId');
    if (f) {
      // console.log(props.isLogAdded);
      if (props.applicationId === 4) {
        props.priorAuthList({
          startDate: dateToyyymmdd(f.startDate),
          endDate: dateToyyymmdd(f.endDate),
          start: 0,
          limit: 100,
          ownerIds: ownerId.toString(),
        });
      }
      if (props.applicationId === 7) {
        props.coderList({
          startDate: f.startDate,
          endDate: f.endDate,
          start: 0,
          limit: 100,
          ownerId: ownerId.toString(),
        });
      }
      if (props.applicationId === 8) {
        props.arManagementList({
          startDate: f.startDate,
          endDate: f.endDate,
          start: 0,
          limit: 50,
          ownerId: ownerId.toString(),
        });
      }
      if (props.applicationId === 6) {
        props.eligibilityList({
          startDate: f.startDate,
          endDate: f.endDate,
          start: 0,
          limit: 50,
          ownerId: ownerId.toString(),
          clinicId: f.clinicId ? f.clinicId : null,
        });
      }
    }
  }, [props.isLogAdded]);

  useEffect(() => {
    // console.log(props.isLogAdded);
  }, [props.isLogAdded]);

  useEffect(() => {
    if (
      props.coderData.priorAuthList &&
      props.coderData.priorAuthList.response &&
      props.coderData.priorAuthList.response.data
    ) {
      let data = props.coderData.priorAuthList.response.data;
      localStorage.setItem('currentList', JSON.stringify(data.results));
      setCurrentList(data.results);
      updateList(data.results);
      props.coderData.priorAuthList.response = null;
    }
  }, [props.coderData.priorAuthList]);

  useEffect(() => {
    if (
      props.coderData.arManagementList &&
      props.coderData.arManagementList.response &&
      props.coderData.arManagementList.response.data
    ) {
      let data = props.coderData.arManagementList.response.data;
      localStorage.setItem('currentList', JSON.stringify(data.results));
      setCurrentList(data.results);
      updateList(data.results);
      props.coderData.arManagementList.response = null;
    }
  }, [props.coderData.arManagementList]);

  useEffect(() => {
    if (
      props.coderData.eligibilityList &&
      props.coderData.eligibilityList.response &&
      props.coderData.eligibilityList.response.data
    ) {
      let data = props.coderData.eligibilityList.response.data;
      localStorage.setItem('currentList', JSON.stringify(data.results));
      setCurrentList(data.results);
      updateList(data.results);
      // console.log(data.results);
      props.coderData.eligibilityList.response = null;
    }
  }, [props.coderData.eligibilityList]);

  useEffect(() => {
    if (
      props.coderData.listData &&
      props.coderData.listData.response &&
      props.coderData.listData.response.data
    ) {
      let data = props.coderData.listData.response.data;
      localStorage.setItem('currentList', JSON.stringify(data.results));
      setCurrentList(data.results);
      updateList(data.results);
      props.coderData.listData.response = null;
    }
  }, [props.coderData.listData]);

  function splitArray(array) {
    let result = [];
    for (let i = 0; i < array.length; i += 10) {
      result.push(array.slice(i, i + 10));
    }
    return result;
  }

  const removeDuplicates = (array) => {
    if (props.applicationId === 4) {
      const uniqueObjects = array?.filter(
        (obj, index, self) =>
          index === self?.findIndex((o) => o?.priorAuthId === obj?.priorAuthId)
      );
      return uniqueObjects;
    }
    if (props.applicationId === 6) {
      const uniqueObjects = array?.filter(
        (obj, index, self) =>
          index ===
          self?.findIndex((o) => o?.appointmentId === obj?.appointmentId)
      );
      return uniqueObjects;
    }
    if (props.applicationId === 7 || props.applicationId === 8) {
      const uniqueObjects = array.filter(
        (obj, index, self) =>
          index === self.findIndex((o) => o?.claimId === obj?.claimId)
      );
      return uniqueObjects;
    }
  };

  const updateList = (currentList) => {
    if (currentList) {
      let selectedPatient = JSON.parse(sessionStorage.getItem('currentRow'));
      const currentRow = JSON.parse(sessionStorage.getItem('currentRow'));
      const arr = currentList;
      let apptHistoryPatient = JSON.parse(
        sessionStorage.getItem('apptHistory')
      );
      // console.log(selectedPatient);
      arr.unshift(selectedPatient);
      if (apptHistoryPatient) arr.unshift(apptHistoryPatient);
      if (props.applicationId === 4) {
        if (
          parseInt(currentRow.priorAuthId) ===
          parseInt(selectedPatient.priorAuthId)
        ) {
          setCurrentRow(selectedPatient);
        }
      }

      if (props.applicationId === 6) {
        if (
          parseInt(currentRow.appointmentId) ===
          parseInt(selectedPatient.appointmentId)
        ) {
          const data = currentList.filter(
            (x) => x.appointmentId === currentRow.appointmentId
          );
          if (data) {
            sessionStorage.setItem('currentRow', JSON.stringify(data[0]));
            props.setRowData(data[0]);
          }
        }
      }

      if (props.applicationId === 7 || props.applicationId === 8) {
        const data = currentList.filter(
          (x) => x.claimId === currentRow.claimId
        );
        if (data) {
          sessionStorage.setItem('currentRow', JSON.stringify(data[0]));
          props.setRowData(data[0]);
        }
      }
      const uniqueArray = removeDuplicates(arr);
      let newArray = uniqueArray?.map((item) => ({
        ...item,
        name: item?.patientLName + ',' + item?.patientFName?.slice(0, 1),
        fullName: item?.patientLName + ',' + item?.patientFName,
      }));
      let split = splitArray(newArray);
      setCount(split.length);
      setPatientList(split);
    }
  };

  useEffect(() => {
    if (
      sessionStorage.getItem('currentRow') &&
      localStorage.getItem('currentList') &&
      sessionStorage.getItem('currentSelection')
    ) {
      const currentRow = JSON.parse(sessionStorage.getItem('currentRow'));
      const currentList = JSON.parse(localStorage.getItem('currentList'));
      let arr = removeDuplicates(currentList);
      let selectedPatient = JSON.parse(
        sessionStorage.getItem('currentSelection')
      );
      let apptHistoryPatient = JSON.parse(
        sessionStorage.getItem('apptHistory')
      );
      arr.unshift(selectedPatient);
      if (apptHistoryPatient) arr.unshift(apptHistoryPatient);
      if (props.applicationId === 4) {
        if (
          parseInt(currentRow.priorAuthId) ===
          parseInt(selectedPatient.priorAuthId)
        ) {
          // currentRow = selectedPatient;
          setCurrentRow(selectedPatient);
        }
      }
      if (props.applicationId === 6) {
        if (
          parseInt(currentRow.appointmentId) ===
          parseInt(selectedPatient.appointmentId)
        ) {
          setCurrentRow(selectedPatient);
        }
      }
      if (props.applicationId === 7 || props.applicationId === 8) {
        if (
          parseInt(currentRow?.claimId) === parseInt(selectedPatient?.claimId)
        ) {
          setCurrentRow(selectedPatient);
        }
      }
      const uniqueArray = removeDuplicates(arr);
      let newArray = uniqueArray?.map((item) => ({
        ...item,
        name: item?.patientLName + ',' + item?.patientFName?.slice(0, 1),
        fullName: item?.patientLName + ',' + item?.patientFName,
      }));
      let split = splitArray(newArray);
      setCount(split.length);
      setPatientList(split);

      if (props.applicationId === 4) {
        newArray.forEach((element, index) => {
          if (element.priorAuthId === currentRow.priorAuthId) {
            setValue(index % 10);
            const section = Math.ceil((index + 1) / 10);
            if (section === 0) setPage(1);
            else setPage(section);
          }
        });
      }
      if (props.applicationId === 6) {
        newArray.forEach((element, index) => {
          if (element.appointmentId === currentRow.appointmentId) {
            setValue(index % 10);
            const section = Math.ceil((index + 1) / 10);
            if (section === 0) setPage(1);
            else setPage(section);
          }
        });
      }
      if (props.applicationId === 7 || props.applicationId === 8) {
        newArray.forEach((element, index) => {
          if (element?.claimId === currentRow?.claimId) {
            setValue(index % 10);
            const section = Math.ceil((index + 1) / 10);
            if (section === 0) setPage(1);
            else setPage(section);
          }
        });
      }
    }
  }, [props.rowData, sessionStorage.getItem('currentRow')]);

  const handlePaginationChange = (event, newPage) => {
    setPage(newPage);
    setValue(0);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const item = patientList[page - 1];
    props.setRowData(item[newValue]);
    // console.log(isTemplateLiteralToken);
    sessionStorage.setItem('clinicId', item[newValue].clinicId);
    sessionStorage.setItem('currentRow', JSON.stringify(item[newValue]));
    setCurrentRow(item[newValue]);
  };

  function formatDateToShortYear(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
    const year = String(date.getFullYear()).slice(-2); // get last two digits of the year

    return `${month}/${day}/${year}`;
  }

  const checkArray = (patient) => {
    if (props.applicationId === 4) {
      if (currentRow.priorAuthId === patient.priorAuthId) {
        return true;
      }
    }
    if (props.applicationId === 6) {
      if (currentRow.appointmentId === patient.appointmentId) {
        return true;
      }
    }
    if (props.applicationId === 7 || props.applicationId === 8) {
      if (currentRow?.claimId === patient?.claimId) {
        return true;
      }
    }
    return false;
  };

  const handleDrawerOpen = () => {
    props.setIsDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    props.setIsDrawerOpen(false);
  };

  // console.log(patientList,"list");

  return (
    <>
      {/* <div className="open" hidden={!props.isDrawerOpen}> */}
      <div className={props.isDrawerOpen ? 'open' : 'mainSectionHidden'}>
        <div className="patient_selector_heading_container">
          <IconButton onClick={handleDrawerClose}>
            <MenuIcon />
          </IconButton>
          {/* <span>Tasks</span> */}
          <div className="left_tab_pagination">
            <Pagination
              className="pag"
              siblingCount={0}
              shape="round"
              size="small"
              color="primary"
              count={count}
              page={page}
              onChange={handlePaginationChange}
              boundaryCount={1}
            />
          </div>
        </div>
        <Divider variant="middle" />

        <div className="dropdown_header">
          <span className="dropdown_title">My Open Items</span>
        </div>
        <StyledTabs
          orientation="vertical"
          variant="scrollable"
          scrollButtons="off"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs"
          className={'tab_container'}
        >
          {/* {console.log(patientList)} */}
          {patientList[page - 1]?.map((patient, index) => (
            <StyledTab
              label={
                <div
                  className={
                    checkArray(patient) === true ? 'tabRow' : 'active_tab'
                  }
                >
                  <div className="firstRow">
                    <span style={{ width: '85px' }}>
                      {formatDateToShortYear(patient.appointmentTime)}
                    </span>
                    <Tooltip title={patient.fullName}>
                      <span>{patient.name}</span>
                    </Tooltip>
                  </div>
                  <div className="secondRow">
                    <span style={{ width: '85px' }}>
                      {patient?.result && patient.result.length > 9
                        ? patient.result.substring(0, 9) + '...'
                        : patient?.result}
                    </span>

                    <span>
                      {patient?.primaryPayorName &&
                      patient.primaryPayorName.length > 13
                        ? patient.primaryPayorName
                            .toLowerCase()
                            .substring(0, 13) + '...'
                        : patient.primaryPayorName
                        ? patient.primaryPayorName
                        : ' '}
                    </span>
                    <span>
                      {patient?.payorName && patient.payorName.length > 13
                        ? patient.payorName.toLowerCase().substring(0, 13) +
                          '...'
                        : patient.payorName
                        ? patient.payorName
                        : ' '}
                    </span>
                  </div>
                  {/* <Divider variant="middle" /> */}
                </div>
              }
              {...a11yProps(index + 1)}
            />
          ))}
        </StyledTabs>
      </div>
      <div
        className={
          props.isDrawerOpen ? 'collapsed_menu_hidden' : 'collapsed_menu_open'
        }
        // className="menu_hidden"
      >
        <IconButton onClick={handleDrawerOpen}>
          <MenuIcon />
        </IconButton>
      </div>
    </>
  );
}
// export default PatientSelector;
const mapStateToProps = (state) => ({
  coderData: state.coder,
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  priorAuthList: (values) => dispatch(priorAuthList(values)),
  coderList: (values) => dispatch(coderList(values)),
  arManagementList: (values) => dispatch(arManagementList(values)),
  eligibilityList: (values) => dispatch(eligibilityList(values)),
});

PatientSelector.propTypes = {
  priorAuthList: PropTypes.func,
  coderList: PropTypes.func,
  arManagementList: PropTypes.func,
  eligibilityList: PropTypes.func,
};

export const LeftTab = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientSelector);
