import { Button, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

function PolicyInfoSkeleton() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Skeleton height={'50px'} variant="rect" />
      </Grid>
      <Grid item xs={2}>
        <Skeleton height={'50px'} variant="rect" />
      </Grid>
      <Grid item xs={2}>
        <Skeleton height={'50px'} variant="rect" />
      </Grid>
      <Grid item xs={2}>
        <Skeleton height={'50px'} variant="rect" />
      </Grid>
      <Grid item xs={2}>
        <Skeleton height={'50px'} variant="rect" />
      </Grid>
      <Grid item xs={2}>
        <div className="edit_btn_patientInfo">
          <Button
            style={{ marginTop: '10px' }}
            variant={'outlined'}
            color="primary"
          >
            Edit
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export default PolicyInfoSkeleton;
