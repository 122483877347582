import React from 'react';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  getCoderResponse,
  getPatient,
} from '../../../Redux/CoderApis/main.actions';
import { getCache } from '../../../Redux/Appointment/apt.actions';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import PatientInfo from './PatientInfo';
import './style.css';
import { getCoder } from '../../../Redux/CoderApis/main.actions';
import PatientInfoSkeleton from './PatientInfo/PatientInfoSkeleton';
import PolicyHolderSkeleton from './PolicyHolderInfo/PolicyHolderSkeleton';
import PolicyInfoSkeleton from './PolicyInfo/PolicyInfoSkeleton';
import PolicyInfo from './PolicyInfo/index';
import PolicyHolderInfo from './PolicyHolderInfo';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    paddingBottom: '1.6rem !important',
  },
}));

function PatientInformation(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [policy, setPolicy] = useState([
    {
      active: 1,
      activeCoverageCleared: true,
      clarificationPresent: true,
      clinicId: 0,
      groupNo: '',
      id: 0,
      note: '',
      patientId: 0,
      payerAddressId: 0,
      payerId: 0,
      payerName: '',
      plan: '',
      policyHolderId: '0',
      policyHolderName: '',
      policyHolderRelation: 0,
      policyNo: '',
      policyType: 1,
      ranking: 1,
    },
    {
      active: 1,
      activeCoverageCleared: false,
      clarificationPresent: true,
      clinicId: 0,
      groupNo: '',
      id: 0,
      note: '',
      patientId: 0,
      payerAddressId: 0,
      payerId: 0,
      payerName: '',
      plan: '',
      policyHolderId: '0',
      policyHolderName: '',
      policyHolderRelation: 0,
      policyNo: '',
      policyType: 1,
      ranking: 2,
    },
  ]);
  const policyObjects = [
    {
      active: 1,
      activeCoverageCleared: true,
      clarificationPresent: true,
      clinicId: 0,
      groupNo: '',
      id: 0,
      note: '',
      patientId: 0,
      payerAddressId: 0,
      payerId: 0,
      payerName: '',
      plan: '',
      policyHolderId: '0',
      policyHolderName: '',
      policyHolderRelation: 0,
      policyNo: '',
      policyType: 1,
      ranking: 1,
    },
    {
      active: 1,
      activeCoverageCleared: false,
      clarificationPresent: true,
      clinicId: 0,
      groupNo: '',
      id: 0,
      note: '',
      patientId: 0,
      payerAddressId: 0,
      payerId: 0,
      payerName: '',
      plan: '',
      policyHolderId: '0',
      policyHolderName: '',
      policyHolderRelation: 0,
      policyNo: '',
      policyType: 1,
      ranking: 2,
    },
  ];
  const [patientInfo, setPatientInfo] = useState(null);
  const [policyHolderObject, setPolicyHolderObject] = useState(null);
  const classes = useStyles();
  const currentRow = JSON.parse(sessionStorage.getItem('currentRow'));
  const clinicId = currentRow.clinicId;

  useEffect(() => {
    if (props.rowInfo) {
      let data = props.rowInfo;
      setIsLoading(true);
      props.getPatient({
        applicationId: props.applicationId,
        patientId: data.patientId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowInfo]);

  useEffect(() => {
    if (props.coderData.patientInfo && props.coderData.patientInfo.response) {
      let response = props.coderData.patientInfo.response;
      if (response.responseCode === 0) {
        let data = response.data;
        setPatientInfo(data.patientDto);
        // props.patientInfo(data.patient);
        if (data?.policyDtoList?.length === 1) {
          let p = [];
          p.push(data.policyDtoList[0]);
          p.push(policyObjects[1]);
          setPolicy(p);
        } else if (data.policyDtoList?.length >= 2) {
          let p = policy;
          if (data.policyDtoList[0]) {
            p[0] = data.policyDtoList[0];
          }
          if (data.policyDtoList[1]) {
            p[1] = data.policyDtoList[1];
          }
          setPolicy([...p]);
        } else {
          setPolicy(policyObjects);
        }
        if (data.policyHolderDtoList?.length >= 0) {
          setPolicyHolderObject(data.policyHolderDtoList[0]);
        }
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.patientInfo]);

  return (
    <div className={'patient_info_main_div'}>
      <Typography className={classes.heading}>Patient Information</Typography>
      {isLoading ? (
        <PatientInfoSkeleton />
      ) : (
        <PatientInfo
          applicationId={props.applicationId}
          rowInfo={props.rowInfo}
          patientInfo={patientInfo}
          clinicId={clinicId}
        />
      )}
      {isLoading ? (
        <PolicyHolderSkeleton />
      ) : (
        <PolicyHolderInfo
          applicationId={props.applicationId}
          rowInfo={props.rowInfo}
          patientInfo={patientInfo ? patientInfo : ''}
          policyHolderObject={policyHolderObject ? policyHolderObject : null}
          clinicId={clinicId}
        />
      )}
      {policy.map((x, i) => (
        <div>
          {isLoading ? (
            <PolicyInfoSkeleton />
          ) : (
            <PolicyInfo
              clinicId={clinicId}
              setRowInfo={props.setRowInfo}
              key={i}
              index={i}
              applicationId={props.applicationId}
              rowInfo={props.rowInfo}
              currentObject={x}
              policyHolderObject={
                policyHolderObject ? policyHolderObject : null
              }
            />
          )}
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  getCoderResponse: (values) => dispatch(getCoderResponse(values)),
  getCoder: (values) => dispatch(getCoder(values)),
  getPatient: (values) => dispatch(getPatient(values)),
  getCache: (values) => dispatch(getCache(values)),
});
PatientInformation.propTypes = {
  getCoderResponse: PropTypes.func,
  getCoder: PropTypes.func,
  getPatient: PropTypes.func,
  getCache: PropTypes.func,
};
// export default connect(mapStateToProps, mapDispatchToProps)(PatientInformation);
export const PatientData = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientInformation);
