import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    paddingBottom: '1.6rem !important',
  },
}));

function ProviderSkeleton() {
  const classes = useStyles();
  return (
    <div className={'physician_info_main'}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography className={classes.heading}>
            Physician Information
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div className="physician_button">
            <Button variant="outlined" color="primary">
              Edit
            </Button>
            <Button variant="outlined" color="primary">
              Cancel
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Skeleton height={'50px'} variant="rect" />
        </Grid>
        <Grid item xs={3}>
          <Skeleton height={'50px'} variant="rect" />
        </Grid>
        <Grid item xs={3}>
          <Skeleton height={'50px'} variant="rect" />
        </Grid>
        <Grid item xs={3}>
          <Skeleton height={'50px'} variant="rect" />
        </Grid>
        <Grid item xs={3}>
          {/* <PhoneFormat className="disabled_section" label="Phone Number" /> */}
          <Skeleton height={'50px'} variant="rect" />
        </Grid>

        <Grid item xs={3}>
          <Skeleton height={'50px'} variant="rect" />
        </Grid>
        <Grid item xs={3}>
          <Skeleton height={'50px'} variant="rect" />
        </Grid>
        <Grid item xs={3}>
          <Skeleton height={'50px'} variant="rect" />
        </Grid>
        <Grid item xs={3}>
          <Skeleton height={'50px'} variant="rect" />
        </Grid>
        <Grid item xs={3}>
          <Skeleton height={'50px'} variant="rect" />
        </Grid>
        <Grid item xs={3}>
          <Skeleton height={'50px'} variant="rect" />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </div>
  );
}

export default ProviderSkeleton;
