import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { cptSave } from 'Redux/PriorAuthApis/prior.actions';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Dropdown from 'Components/Dropdown';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

function CptDialog(props) {
  const [pos, setPos] = useState([]);
  const [tos, setTos] = useState([]);
  const [form, setForm] = useState({
    cashFee: 0,
    clinicId: '',
    comments: '',
    cptCode: '',
    cptId: 0,
    description: '',
    fee: 0,
    frequencyType: 1,
    modifier: '',
    ndcCode: 0,
    ndcMeasure: 0,
    ndcUnits: 1,
    pos: '',
    tos: '',
    unit: 1,
  });

  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let clinicId = sessionStorage.getItem('clinicId');
    props.cptSave({ ...form, clinicId: clinicId });
  };

  useEffect(() => {
    if (props.priorAuthData.cptSaveResponse) {
      let Response = props.priorAuthData.cptSaveResponse.response;
      if (Response.responseCode === 0) {
        props.updateCpt(Response.data);
        console.log('cpt save res', Response.data);
        props.handleClose();
        toast.success('CPT saved Successfully');
      }
      if (Response.responseCode === 136) {
        toast.error('Clinic CPT already exists');
      }
      props.priorAuthData.cptSaveResponse = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.cptSaveResponse]);

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('cache'));
    let pos = [];
    let tos = [];
    cache.posList.forEach((x) => {
      pos.push({
        name: x.description,
        value: x.value,
      });
    });

    cache.tosList.forEach((x) => {
      tos.push({
        name: x.description,
        value: x.value,
      });
    });
    setPos(pos);
    setTos(tos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.priorAuthData.cptSearchResponse) {
      let response = props.priorAuthData.cptSearchResponse.response;
      if (response.responseCode === 0) {
        let data = props.priorAuthData.cptSearchResponse.response.data;
        if (data.length > 0) {
          let x = data[0];
          setForm({
            cashFee: x.cashFee,
            clinicId: x.clinicId,
            comments: x.comments,
            cptCode: x.cptCode,
            cptId: x.cptId,
            description: x.description,
            fee: x.fee,
            frequencyType: x.frequencyType,
            modifier: x.modifier,
            ndcCode: x.ndcCode,
            ndcMeasure: x.ndcMeasure,
            ndcUnits: x.ndcUnits,
            pos: x.pos,
            tos: x.tos,
            unit: x.unit,
          });
        }
      } else {
        // setForm('');
        setForm({
          cashFee: 0,
          clinicId: '',
          comments: '',
          cptCode: '',
          cptId: 0,
          description: '',
          fee: 0,
          frequencyType: 1,
          modifier: '',
          ndcCode: 0,
          ndcMeasure: 0,
          ndcUnits: 1,
          pos: '',
          tos: '',
          unit: 1,
        });
      }
    }
  }, [props.priorAuthData.cptSearchResponse]);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <div style={{ padding: '1rem' }}>
              <p
                style={{
                  paddingBottom: '20px',
                  fontSize: '20px',
                  fontWeight: '500',
                }}
              >
                Add/Edit CPT
              </p>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    // className="input_material_field"
                    required
                    type="text"
                    label="Code"
                    margin="normal"
                    variant="outlined"
                    onChange={(e) =>
                      setForm({ ...form, cptCode: e.target.value })
                    }
                    value={form.cptCode ? form.cptCode : ''}
                  />
                </Grid>
                {/* <Grid item xs={8}></Grid> */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    // className="input_material_field"
                    required
                    type="text"
                    label="Description"
                    margin="normal"
                    variant="outlined"
                    onChange={(e) =>
                      setForm({ ...form, description: e.target.value })
                    }
                    value={form.description ? form.description : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    // className="input_material_field"
                    type="text"
                    label="Modifier"
                    margin="normal"
                    variant="outlined"
                    inputProps={{ maxLength: 2 }}
                    onChange={(e) =>
                      setForm({ ...form, modifier: e.target.value })
                    }
                    value={form.modifier ? form.modifier : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    // className="input_material_field"
                    type="text"
                    label="NDC Code"
                    margin="normal"
                    variant="outlined"
                    onChange={(e) =>
                      setForm({ ...form, ndcCode: e.target.value })
                    }
                    value={form.ndcCode ? form.ndcCode : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Fee"
                    type="number"
                    onChange={(e) => setForm({ ...form, fee: e.target.value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    value={form.fee ? form.fee : ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    // className="input_material_field"
                    type="number"
                    label="NDC units"
                    margin="normal"
                    variant="outlined"
                    value={form.ndcUnits}
                    onChange={(e) =>
                      setForm({ ...form, ndcUnits: parseInt(e.target.value) })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    onChange={(e) =>
                      setForm({ ...form, cashFee: e.target.value })
                    }
                    label="Cash Fee"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    value={form.cashFee ? form.cashFee : ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    // className="input_material_field"
                    type="number"
                    label="NDC measure"
                    margin="normal"
                    variant="outlined"
                    value={form.ndcMeasure ? form.ndcMeasure : ''}
                    onChange={(e) =>
                      setForm({ ...form, ndcMeasure: parseInt(e.target.value) })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    tabIndex={3}
                    options={pos}
                    label="POS"
                    variant="outlined"
                    onChange={(e) => setForm({ ...form, pos: e.target.value })}
                    value={form.pos ? form.pos : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    tabIndex={3}
                    options={tos}
                    label="TOS"
                    variant="outlined"
                    onChange={(e) => setForm({ ...form, tos: e.target.value })}
                    value={form.tos ? form.tos : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    // className="input_material_field"
                    type="text"
                    label="Comments"
                    margin="normal"
                    value={form.comments ? form.comments : ''}
                    variant="outlined"
                    onChange={(e) =>
                      setForm({ ...form, comments: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  gap: '10px',
                }}
              >
                <Button
                  // onClick={() => props.handleClose()}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  onClick={() => props.handleClose()}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => ({
  priorAuthData: state.priorAuth,
});

const mapDispatchToProps = (dispatch) => ({
  cptSave: (values) => dispatch(cptSave(values)),
});
CptDialog.propTypes = {
  cptSave: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(CptDialog);
