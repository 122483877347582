import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  fileDownload,
  fileDownloadResponse,
  getApptNotes,
  getCoderFiles,
  getNotes,
} from '../../../Redux/CoderApis/main.actions';
import PropTypes from 'prop-types';
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';
import CsvIcon from '../../../assets/csv-icon.svg';
import GetIcon from '../../../assets/download.svg';
import { Button, Dialog, DialogContent, Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    paddingBottom: '1rem !important',
  },

  fileListItem: {
    border: '1px solid #D7E0E9',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    paddingRight: '10px',
  },
  savedFiles: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '16px 0',
    gap: '16px',
  },

  previewError: {
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: '16px',
  },
}));

function Upload(props) {
  const [downloadList, setDownloadList] = useState([]);
  const [currentFile, setCurrentFile] = useState('');
  //preview states
  const [isPreview, setIsPreview] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewFileUrl, setPreviewFileUrl] = useState('');
  const classes = useStyles();

  // Handle file download and preview (dowloadApi)
  const fileDownloadfn = (x, type) => {
    if (type === 'preview') {
      setIsPreview(true);
    } else {
      setIsPreview(false);
    }
    props.fileDownload({
      data: {
        fileId: x.fileId,
        fileName: x.fileName,
        isInternal: x.isInternal,
      },
      applicationId: props.applicationId,
    });
    setCurrentFile(x.fileName);
  };
  // Close the preview modal
  const handlePreviewClose = () => {
    setIsPreviewOpen(false);
    setPreviewFileUrl('');
  };
  // Function to determine the MIME type based on the file extension
  const getMimeType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase(); // Ensure the extension is in lowercase
    console.log(extension);
    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'pdf':
        return 'application/pdf';
      default:
        return 'application/octet-stream';
    }
  };

  //Handle Download
  const downloadFiles = (content, fileName, contentType) => {
    if (!contentType) contentType = 'application/octet-stream';
    var a = document.createElement('a');
    var blob = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    blob = null;
  };
  // Handle file download or preview based on the preview state
  useEffect(() => {
    if (props.coderData.downloadResponse) {
      if (isPreview) {
        const previewFile = props.coderData.downloadResponse.response;
        const blob = new Blob([previewFile], {
          type: getMimeType(currentFile),
        });
        const previewUrl = URL.createObjectURL(blob);
        setIsPreviewOpen(true);
        setPreviewFileUrl(previewUrl);
        props.fileDownloadResponse(null);
        setIsPreview(false);
      } else {
        let blobData = props.coderData.downloadResponse.response;
        let fileName = currentFile;
        let fileType = currentFile.split('.')[1];
        downloadFiles(blobData, fileName, fileType);
        props.fileDownloadResponse(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.downloadResponse]);

  useEffect(() => {
    if (props.rowInfo) {
      const data = props.rowInfo;
      const clinicId = sessionStorage.getItem('clinicId');
      if (props.applicationId === 4 || props.applicationId === 6) {
        props.getApptNotes({
          appointmentId: props.rowInfo.appointmentId,
          applicationId: props.applicationId,
          patientId: props.rowInfo.patientId,
        });
        props.getCoderFiles({
          data: {
            patientId: data.patientId,
            clinicId: clinicId,
          },
          applicationId: props.applicationId,
        });
      }
      if (props.applicationId === 7 || props.applicationId === 8) {
        props.getNotes({
          claimId: props.rowInfo.claimId,
          applicationId: props.applicationId,
          patientId: props.rowInfo.patientId,
        });
        props.getCoderFiles({
          data: {
            patientId: data.patientId,
            clinicId: clinicId,
            claimId: data.claimId,
          },
          applicationId: props.applicationId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowInfo, props.applicationId]);

  useEffect(() => {
    if (props.coderData.files) {
      let response = props.coderData.files.response;
      if (response.responseCode === 0) {
        let info = response.data;
        setDownloadList(info);
      }
    }
  }, [props.coderData.files]);

  // useEffect(() => {
  //   if (localStorage.getItem('currentCoder')) {
  //     let info = JSON.parse(localStorage.getItem('currentCoder'));
  //     setDownloadList(info.codingFileDtoList);
  //   }
  // }, [localStorage.getItem('currentCoder')]);

  return (
    <div className={'uploadList_main_div'}>
      <Typography className={classes.heading}>Files</Typography>
      <div className={classes.dropzoneContainer}>
        <div className={classes.savedFiles}>
          {downloadList.length > 0 &&
            downloadList.map((x, i) => (
              <div
                key={i}
                className={classes.fileListItem}
                style={{ padding: '5px' }}
              >
                <img
                  src={CsvIcon}
                  alt=""
                  style={{
                    color: '#000000',
                    cursor: 'pointer',
                    height: '20px',
                    paddingRight: '5px',
                  }}
                />

                <Typography>{x.fileName}</Typography>

                <img
                  src={GetIcon}
                  onClick={() => fileDownloadfn(x)}
                  alt={'hh'}
                  style={{
                    color: '#000000',
                    cursor: 'pointer',
                    height: '21px',
                    paddingLeft: '5px',
                  }}
                />
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => fileDownloadfn(x, 'preview')}
                >
                  <Tooltip title={'Preview'}>
                    <VisibilityIcon fontSize="small" />
                  </Tooltip>
                </Button>
              </div>
            ))}
        </div>
      </div>
      <Dialog
        className="scrollbarY--custom"
        maxWidth="md"
        fullWidth
        open={isPreviewOpen}
        onClose={handlePreviewClose}
      >
        <DialogContent className="previewDialogContent">
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <div className="preview_closeBtn">
              <Button
                variant="text"
                size="small"
                color="primary"
                onClick={handlePreviewClose}
              >
                <CloseIcon />
              </Button>
            </div>
          </div>
          <div className="preview">
            {getMimeType(currentFile) === 'application/pdf' ? (
              <object
                aria-label="Preview of the selected file"
                menu="false"
                width="100%"
                height="900px"
                data={previewFileUrl}
                type="application/pdf"
              ></object>
            ) : getMimeType(currentFile) === 'image/jpeg' ||
              getMimeType(currentFile) === 'image/png' ? (
              <img
                src={previewFileUrl}
                alt="File preview"
                className={classes.previewImage}
              />
            ) : (
              <div className={classes.previewError}>
                <span>Preview not available for this file type.</span>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  fileDownload: (values) => dispatch(fileDownload(values)),
  getCoderFiles: (values) => dispatch(getCoderFiles(values)),
  // getEligibilityFiles: (values) => dispatch(getEligibilityFiles(values)),
  // getPriorAuthFiles: (values) => dispatch(getPriorAuthFiles(values)),
  fileDownloadResponse: (values) => dispatch(fileDownloadResponse(values)),
  getNotes: (values) => dispatch(getNotes(values)),
  getApptNotes: (values) => dispatch(getApptNotes(values)),
});
Upload.propTypes = {
  fileDownload: PropTypes.func,
  getCoderFiles: PropTypes.func,
  getEligibilityFiles: PropTypes.func,
  getPriorAuthFiles: PropTypes.func,
  fileDownloadResponse: PropTypes.func,
  getNotes: PropTypes.func,
  getApptNotes: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Upload);
