import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { icdSearch } from 'Redux/PriorAuthApis/prior.actions';
import PropTypes from 'prop-types';
import { Grid, Button, Tooltip } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/Remove';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import IcdDialog from '../IcdDialog';
import { IcdKeywordDialog } from '../../../../../../libs/icd-search/src';
import './style.css';
function Diagnosis(props) {
  const [openIcd, setopenIcd] = useState({ status: false, key: '' });
  const [openIcdkeyword, setopenIcdleyword] = useState({
    status: false,
    key: '',
  });
  const [more, setMore] = useState(false);
  const [icdList, setIcdList] = useState([]);
  const [priorAuthIcdId, setPriorAuthIcdId] = useState(0);
  const [priorAuthId, setPriorAuthId] = useState(0);
  const [dx1, setDx1] = useState({ title: '' });
  const [dx2, setDx2] = useState({ title: '' });
  const [dx3, setDx3] = useState({ title: '' });
  const [dx4, setDx4] = useState({ title: '' });
  const [dx5, setDx5] = useState({ title: '' });
  const [dx6, setDx6] = useState({ title: '' });
  const [dx7, setDx7] = useState({ title: '' });
  const [dx8, setDx8] = useState({ title: '' });
  const [icdCount, setIcdCount] = useState('');
  const [icdToEdit, setIcdToEdit] = useState(null);

  useEffect(() => {
    let count = 0;
    let icds = [dx1, dx2, dx3, dx4, dx5, dx6, dx7, dx8];
    icds.forEach((icd) => {
      if (icd.icdCode) ++count;
    });
    setIcdCount(count);
    props.icdInformation({
      icd1Code: dx1 && dx1.icdCode ? dx1.icdCode : '',
      icd1Description: dx1 && dx1.icdDescLong ? dx1.icdDescLong : '',
      icd1Id: dx1 && dx1.icdId ? dx1.icdId : 0,

      icd2Code: dx2 && dx2.icdCode ? dx2.icdCode : '',
      icd2Description: dx2 && dx2.icdDescLong ? dx2.icdDescLong : '',
      icd2Id: dx2 && dx2.icdId ? dx2.icdId : 0,

      icd3Code: dx3 && dx3.icdCode ? dx3.icdCode : '',
      icd3Description: dx3 && dx3.icdDescLong ? dx3.icdDescLong : '',
      icd3Id: dx3 && dx3.icdId ? dx3.icdId : 0,

      icd4Code: dx4 && dx4.icdCode ? dx4.icdCode : '',
      icd4Description: dx4 && dx4.icdDescLong ? dx4.icdDescLong : '',
      icd4Id: dx4 && dx4.icdId ? dx4.icdId : 0,

      icd5Code: dx5 && dx5.icdCode ? dx5.icdCode : '',
      icd5Description: dx5 && dx5.icdDescLong ? dx5.icdDescLong : '',
      icd5Id: dx5 && dx5.icdId ? dx5.icdId : 0,

      icd6Code: dx6 && dx6.icdCode ? dx6.icdCode : '',
      icd6Description: dx6 && dx6.icdDescLong ? dx6.icdDescLong : '',
      icd6Id: dx6 && dx6.icdId ? dx6.icdId : 0,

      icd7Code: dx7 && dx7.icdCode ? dx7.icdCode : '',
      icd7Description: dx7 && dx7.icdDescLong ? dx7.icdDescLong : '',
      icd7Id: dx7 && dx7.icdId ? dx7.icdId : 0,

      icd8Code: dx8 && dx8.icdCode ? dx8.icdCode : '',
      icd8Description: dx8 && dx8.icdDescLong ? dx8.icdDescLong : '',
      icd8Id: dx8 && dx8.icdId ? dx8.icdId : 0,

      priorAuthIcdId: priorAuthIcdId,
      priorAuthId: priorAuthId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dx1, dx2, dx3, dx4, dx5, dx6, dx7, dx8]);

  useEffect(() => {
    if (props.priorAuthData.icdSearchResponse) {
      let Response = props.priorAuthData.icdSearchResponse.response;
      if (Response.responseCode === 0) {
        let ilist = Response.data.map((x) => {
          return {
            title: x.icdCode + ' ' + x.icdDescLong,
            ...x,
          };
        });
        setIcdList(ilist);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.icdSearchResponse]);

  const icdSearchFn = (e) => {
    const clinicId = sessionStorage.getItem('clinicId');
    if (e.target.value.length > 2) {
      props.icdSearch({ s: e.target.value, cid: clinicId });
    }
  };

  useEffect(() => {
    if (props.priorAuthData.icdResponse) {
      let Response = props.priorAuthData.icdResponse.response;
      if (Response.responseCode === 0) {
        setopenIcd({ status: false, key: '' });
        let data = Response.data;
        if (openIcd.key === 'DX1') {
          setDx1({
            icdCode: data.icdCode,
            title: data.icdCode,
            icdDescLong: data.icdDescLong,
            icdId: data.icdId,
          });
        } else if (openIcd.key === 'DX2') {
          setDx2({
            icdCode: data.icdCode,
            icdDescLong: data.icdDescLong,
            title: data.icdCode,
            icdId: data.icdId,
          });
        } else if (openIcd.key === 'DX3') {
          setDx3({
            icdCode: data.icdCode,
            icdDescLong: data.icdDescLong,
            title: data.icdCode,
            icdId: data.icdId,
          });
        } else if (openIcd.key === 'DX4') {
          setDx4({
            icdCode: data.icdCode,
            icdDescLong: data.icdDescLong,
            title: data.icdCode,
            icdId: data.icdId,
          });
        } else if (openIcd.key === 'DX5') {
          setDx5({
            icdCode: data.icdCode,
            icdDescLong: data.icdDescLong,
            title: data.icdCode,
            icdId: data.icdId,
          });
        } else if (openIcd.key === 'DX6') {
          setDx6({
            icdCode: data.icdCode,
            icdDescLong: data.icdDescLong,
            title: data.icdCode,
            icdId: data.icdId,
          });
        } else if (openIcd.key === 'DX7') {
          setDx7({
            icdCode: data.icdCode,
            icdDescLong: data.icdDescLong,
            title: data.icdCode,
            icdId: data.icdId,
          });
        } else if (openIcd.key === 'DX8') {
          setDx8({
            icdCode: data.icdCode,
            title: data.icdCode,
            icdDescLong: data.icdDescLong,
            icdId: data.icdId,
          });
        }
      } else {
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.icdResponse]);

  function countIcdCodes(obj) {
    if (!obj) return 0;
    let count = 0;
    for (let i = 1; i <= 8; i++) {
      const icdCode = obj[`icd${i}Code`];
      if (icdCode && icdCode.trim() !== '') {
        count++;
      }
    }
    return count;
  }

  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (props.priorAuthData.priorAuthInfo) {
      let response = props.priorAuthData.priorAuthInfo.response;
      if (response.responseCode === 0) {
        let data = response.data;
        let icdDto = data.priorAuthIcdDto;
        if (isEmpty(icdDto) === false) {
          let info = data.priorAuthIcdDto;
          setPriorAuthId(info.priorAuthId);
          setPriorAuthIcdId(info.priorAuthIcdId);
          setDx1({
            title: info.icd1Code ? info.icd1Code : '',
            icdCode: info.icd1Code,
            icdId: info.icd1Id,
            icdDescLong: info.icd1Description,
          });
          setDx2({
            title: info.icd2Code ? info.icd2Code : '',
            icdCode: info.icd2Code,
            icdId: info.icd2Id,
            icdDescLong: info.icd2Description,
          });
          setDx3({
            title: info.icd3Code ? info.icd3Code : '',
            icdCode: info.icd3Code,
            icdId: info.icd3Id,
            icdDescLong: info.icd3Description,
          });
          setDx4({
            title: info.icd4Code ? info.icd4Code : '',
            icdCode: info.icd4Code,
            icdId: info.icd4Id,
            icdDescLong: info.icd4Description,
          });
          setDx5({
            title: info.icd5Code ? info.icd5Code : '',
            icdCode: info.icd5Code,
            icdId: info.icd5Id,
            icdDescLong: info.icd5Description,
          });
          setDx6({
            title: info.icd6Code ? info.icd6Code : '',
            icdCode: info.icd6Code,
            icdId: info.icd6Id,
            icdDescLong: info.icd6Description,
          });
          setDx7({
            title: info.icd7Code ? info.icd7Code : '',
            icdCode: info.icd7Code,
            icdId: info.icd7Id,
            icdDescLong: info.icd7Description,
          });
          setDx8({
            title: info.icd8Code ? info.icd8Code : '',
            icdCode: info.icd8Code,
            icdId: info.icd8Id,
            icdDescLong: info.icd8Description,
          });
          if (
            info.icd8Code ||
            info.icd7Code ||
            info.icd6Code ||
            info.icd5Code
          ) {
            setMore(true);
          }
        } else {
          setDx1({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
          setDx2({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
          setDx3({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
          setDx4({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
          setDx5({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
          setDx6({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
          setDx7({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
          setDx8({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.priorAuthInfo]);

  const handleTempSelectedCodes = (data) => {
    let count = 0;
    data.forEach((icd) => {
      if (icd.icdCode) count++;
    });
    if (count > 4) setMore(true);
    if (data) {
      setDx1({
        icdCode: data[0]?.icdCode,
        icdDescLong: data[0]?.icdDescLong,
        title: data[0]?.icdCode,
        icdId: data[0]?.icdId,
      });
      setDx2({
        icdCode: data[1]?.icdCode,
        title: data[1]?.icdCode,
        icdDescLong: data[1]?.icdDescLong,
        icdId: data[1]?.icdId,
      });
      setDx3({
        icdCode: data[2]?.icdCode,
        title: data[2]?.icdCode,
        icdDescLong: data[2]?.icdDescLong,

        icdId: data[2]?.icdId,
      });
      setDx4({
        icdCode: data[3]?.icdCode,
        title: data[3]?.icdCode,
        icdDescLong: data[3]?.icdDescLong,
        icdId: data[3]?.icdId,
      });
      setDx5({
        icdCode: data[4]?.icdCode,
        title: data[4]?.icdCode,
        icdDescLong: data[4]?.icdDescLong,
        icdId: data[4]?.icdId,
      });
      setDx6({
        icdCode: data[5]?.icdCode,
        icdDescLong: data[5]?.icdDescLong,
        title: data[5]?.icdCode,
        icdId: data[5]?.icdId,
      });
      setDx7({
        icdCode: data[6]?.icdCode,
        title: data[6]?.icdCode,
        icdDescLong: data[6]?.icdDescLong,
        icdId: data[6]?.icdId,
      });
      setDx8({
        icdCode: data[7]?.icdCode,
        title: data[7]?.icdCode,
        icdDescLong: data[7]?.icdDescLong,
        icdId: data[7]?.icdId,
      });
    }
    // });
  };

  const handleKeywordValues = (data) => {
    if (openIcdkeyword.key === 'DX1') {
      setopenIcdleyword({ status: true, key: 'DX2' });
      setDx1({
        icdCode: data.icdCode,
        title: data.icdCode,
        icdId: data.icdId,
      });
    } else if (openIcdkeyword.key === 'DX2') {
      setopenIcdleyword({ status: true, key: 'DX3' });
      setDx2({
        icdCode: data.icdCode,
        title: data.icdCode,
        icdId: data.icdId,
      });
    } else if (openIcdkeyword.key === 'DX3') {
      setDx3({
        icdCode: data.icdCode,
        title: data.icdCode,
        icdId: data.icdId,
      });
      setopenIcdleyword({ status: true, key: 'DX4' });
    } else if (openIcdkeyword.key === 'DX4') {
      setDx4({
        icdCode: data.icdCode,
        title: data.icdCode,
        icdId: data.icdId,
      });
      setopenIcdleyword({ status: true, key: 'DX5' });
    } else if (openIcdkeyword.key === 'DX5') {
      setDx5({
        icdCode: data.icdCode,
        title: data.icdCode,
        icdId: data.icdId,
      });
      setopenIcdleyword({ status: true, key: 'DX6' });
    } else if (openIcdkeyword.key === 'DX6') {
      setDx6({
        icdCode: data.icdCode,
        title: data.icdCode,
        icdId: data.icdId,
      });
      setopenIcdleyword({ status: true, key: 'DX7' });
    } else if (openIcdkeyword.key === 'DX7') {
      setDx7({
        icdCode: data.icdCode,
        title: data.icdCode,
        icdId: data.icdId,
      });
      setopenIcdleyword({ status: true, key: 'DX8' });
    } else if (openIcdkeyword.key === 'DX8') {
      setDx8({
        icdCode: data.icdCode,
        title: data.icdCode,
        icdId: data.icdId,
      });
    }
  };

  const openIcdDialog = (key, icd) => {
    setIcdToEdit(icd);
    setopenIcd({ status: true, key: key });
  };

  const handleDeleteIcd = (icd) => {
    if (icd.icdId === dx1?.icdId) {
      setDx1(null);
    } else if (icd.icdId === dx2?.icdId) {
      setDx2(null);
    } else if (icd.icdId === dx3?.icdId) {
      setDx3(null);
    } else if (icd.icdId === dx4?.icdId) {
      setDx4(null);
    } else if (icd.icdId === dx5?.icdId) {
      setDx5(null);
    } else if (icd.icdId === dx6?.icdId) {
      setDx6(null);
    } else if (icd.icdId === dx7?.icdId) {
      setDx7(null);
    } else if (icd.icdId === dx8?.icdId) {
      setDx8(null);
    } else {
      null;
    }
  };

  const handleIcd = (param, data) => {
    setIcdList([]);
    if (param === 'dx1') {
      setDx1({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
    if (param === 'dx2') {
      setDx2({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
    if (param === 'dx3') {
      setDx3({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
    if (param === 'dx4') {
      setDx4({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
    if (param === 'dx5') {
      setDx5({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
    if (param === 'dx6') {
      setDx6({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
    if (param === 'dx7') {
      setDx7({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
    if (param === 'dx8') {
      setDx8({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
  };

  const checkDuplicateIcd = (option) => {
    const icdArray = [
      dx1?.icdCode,
      dx2?.icdCode,
      dx3?.icdCode,
      dx4?.icdCode,
      dx5?.icdCode,
      dx6?.icdCode,
      dx7?.icdCode,
      dx8?.icdCode,
    ];
    return icdArray.includes(option.icdCode);
  };

  const openIcdPopup = (x) => {
    setopenIcdleyword(x);
  };

  return (
    <div className="diagnosis_main_div">
      {/* <p className="diagnosis_heading">Diagnosis</p> */}
      <div className="diagnosis_container">
        <Grid container spacing={1} className="diagnosis_grid">
          <Grid item xs={3}>
            <div className="dx_field_set">
              <Autocomplete
                fullWidth
                options={icdList}
                onChange={(e, x) => handleIcd('dx1', x)}
                value={dx1}
                getOptionDisabled={(option) => checkDuplicateIcd(option)}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField {...params} label="DX1" onChange={icdSearchFn} />
                )}
              />
              <Tooltip title="Add/Edit ICD">
                <AddCircleOutlineIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_addicon'}
                  onClick={() => openIcdDialog('DX1', dx1)}
                />
              </Tooltip>
              <CloseIcon
                color="primary"
                fontSize="small"
                className={'dx_closeIcon'}
                onClick={() =>
                  setDx1({
                    title: '',
                    icdCode: '',
                    icdId: 0,
                    icdDescLong: '',
                  })
                }
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="dx_field_set">
              <Autocomplete
                fullWidth
                options={icdList}
                onChange={(e, x) => handleIcd('dx2', x)}
                value={dx2}
                getOptionDisabled={(option) => checkDuplicateIcd(option)}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField {...params} label="DX2" onChange={icdSearchFn} />
                )}
              />
              <Tooltip title="Add/Edit ICD">
                <AddCircleOutlineIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_addicon'}
                  // onClick={() => setopenIcd({ status: true, key: openIcd.key })}
                  onClick={() => openIcdDialog('DX2', dx2)}
                />
              </Tooltip>
              <CloseIcon
                color="primary"
                fontSize="small"
                className={'dx_closeIcon'}
                onClick={() =>
                  setDx2({ title: '', icdCode: '', icdId: 0, icdDescLong: '' })
                }
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="dx_field_set">
              <Autocomplete
                fullWidth
                options={icdList}
                onChange={(e, x) => handleIcd('dx3', x)}
                value={dx3}
                getOptionDisabled={(option) => checkDuplicateIcd(option)}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField {...params} label="DX3" onChange={icdSearchFn} />
                )}
              />
              <Tooltip title="Add/Edit ICD">
                <AddCircleOutlineIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_addicon'}
                  // onClick={() => setopenIcd({ status: true, key: 'DX3' })}
                  onClick={() => openIcdDialog('DX3', dx3)}
                />
              </Tooltip>
              <CloseIcon
                color="primary"
                fontSize="small"
                className={'dx_closeIcon'}
                onClick={() =>
                  setDx3({ title: '', icdCode: '', icdId: 0, icdDescLong: '' })
                }
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="dx_field_set">
              <Autocomplete
                fullWidth
                options={icdList}
                onChange={(e, x) => handleIcd('dx4', x)}
                value={dx4}
                getOptionDisabled={(option) => checkDuplicateIcd(option)}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField {...params} label="DX4" onChange={icdSearchFn} />
                )}
              />
              <Tooltip title="Add/Edit ICD">
                <AddCircleOutlineIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_addicon'}
                  // onClick={() => setopenIcd({ status: true, key: 'DX4' })}
                  onClick={() => openIcdDialog('DX4', dx4)}
                />
              </Tooltip>
              <CloseIcon
                color="primary"
                fontSize="small"
                className={'dx_closeIcon'}
                onClick={() =>
                  setDx4({ title: '', icdCode: '', icdId: 0, icdDescLong: '' })
                }
              />
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            marginLeft: '10px',
            gap: '0.7rem',
            padding: '10px',
            paddingRight: '0',
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            startIcon={
              more ? (
                <RemoveIcon color={'primary'} />
              ) : (
                <AddCircleOutlineIcon color={'primary'} />
              )
            }
            onClick={() => setMore(!more)}
          >
            {more ? 'Less' : 'Add'}
          </Button>
          <Button
            color="primary"
            onClick={() => openIcdPopup({ status: true, key: icdCount })}
            size="small"
            variant="outlined"
            startIcon={<SearchIcon color="primary" />}
          >
            Search
          </Button>
        </div>
      </div>
      {/* Next Block*/}
      <div>
        <div hidden={!more}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div className="dx_field_set">
                <Autocomplete
                  fullWidth
                  options={icdList}
                  onChange={(e, x) => handleIcd('dx5', x)}
                  value={dx5}
                  getOptionDisabled={(option) => checkDuplicateIcd(option)}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField {...params} label="DX5" onChange={icdSearchFn} />
                  )}
                />
                <Tooltip title="Add/Edit ICD">
                  <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className={'dx_addicon'}
                    onClick={() => openIcdDialog('DX5', dx5)}
                  />
                </Tooltip>
                <CloseIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_closeIcon'}
                  onClick={() =>
                    setDx5({
                      title: '',
                      icdCode: '',
                      icdId: 0,
                      icdDescLong: '',
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="dx_field_set">
                <Autocomplete
                  fullWidth
                  options={icdList}
                  onChange={(e, x) => handleIcd('dx6', x)}
                  value={dx6}
                  getOptionDisabled={(option) => checkDuplicateIcd(option)}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField {...params} label="DX6" onChange={icdSearchFn} />
                  )}
                />
                <Tooltip title="Add/Edit ICD">
                  <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className={'dx_addicon'}
                    // onClick={() => setopenIcd({ status: true, key: 'DX6' })}
                    onClick={() => openIcdDialog('DX6', dx6)}
                  />
                </Tooltip>
                <CloseIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_closeIcon'}
                  onClick={() =>
                    setDx6({
                      title: '',
                      icdCode: '',
                      icdId: 0,
                      icdDescLong: '',
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="dx_field_set">
                <Autocomplete
                  fullWidth
                  options={icdList}
                  onChange={(e, x) => handleIcd('dx7', x)}
                  value={dx7}
                  getOptionDisabled={(option) => checkDuplicateIcd(option)}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField {...params} label="DX7" onChange={icdSearchFn} />
                  )}
                />
                <Tooltip title="Add/Edit ICD">
                  <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className={'dx_addicon'}
                    // onClick={() => setopenIcd({ status: true, key: 'DX7' })}
                    onClick={() => openIcdDialog('DX7', dx7)}
                  />
                </Tooltip>
                <CloseIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_closeIcon'}
                  onClick={() =>
                    setDx7({
                      title: '',
                      icdCode: '',
                      icdId: 0,
                      icdDescLong: '',
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="dx_field_set">
                <Autocomplete
                  fullWidth
                  options={icdList}
                  onChange={(e, x) => handleIcd('dx8', x)}
                  value={dx8}
                  getOptionDisabled={(option) => checkDuplicateIcd(option)}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField {...params} label="DX8" onChange={icdSearchFn} />
                  )}
                />
                <Tooltip title="Add/Edit ICD">
                  <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className={'dx_addicon'}
                    // onClick={() => setopenIcd({ status: true, key: 'DX8' })}
                    onClick={() => openIcdDialog('DX8', dx8)}
                  />
                </Tooltip>
                <CloseIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_closeIcon'}
                  onClick={() =>
                    setDx8({
                      title: '',
                      icdCode: '',
                      icdId: 0,
                      icdDescLong: '',
                    })
                  }
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid xs={12}></Grid>
      </div>
      <IcdDialog
        open={openIcd.status}
        handleClose={() => setopenIcd({ status: false })}
        applicationId={props.applicationId}
        icd={icdToEdit}
        rowInfo={props.rowInfo}
      />
      <IcdKeywordDialog
        open={openIcdkeyword.status}
        current={icdCount}
        rowInfo={props.rowInfo}
        handleClose={() => setopenIcdleyword(false)}
        handleNextIcd={setopenIcdleyword}
        keywordList={handleKeywordValues}
        applicationId={4}
        handleTempSelectedCodes={handleTempSelectedCodes}
        icdArray={[dx1, dx2, dx3, dx4, dx5, dx6, dx7, dx8]}
        handleDeleteIcd={handleDeleteIcd}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  priorAuthData: state.priorAuth,
});

const mapDispatchToProps = (dispatch) => ({
  icdSearch: (values) => dispatch(icdSearch(values)),
});
Diagnosis.propTypes = {
  icdSearch: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Diagnosis);
