import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { DobPicker } from '../../../../dob-picker/src/index';
import { Dropdown } from '../../../../drop-down/src/index';
import Input from '../../../../Input';
// import states from 'HelperFunctions/states.json';
import toast from 'react-hot-toast';
import {
  getCoder,
  getPatient,
  policyHolderSave,
} from '../../../../Redux/CoderApis/main.actions';
// import { dateTommddyyy } from 'HelperFunctions/TimeDateTimestamp';
import states from '../../../../helper-functions/src/lib/states.json';
import { dateTommddyyy, dateToyyymmdd } from '../../../../helper-functions/src/lib/TimeDateTimestamp';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './style.css';
// import { InputAdornment } from '@material-ui/core/InputAdornment';

const relation = [
  {
    name: 'FATHER',
    value: 1,
  },
  {
    name: 'MOTHER',
    value: 2,
  },
  {
    name: 'SPOUSE',
    value: 3,
  },
  {
    name: 'BROTHER',
    value: 4,
  },
  {
    name: 'SISTER',
    value: 5,
  },
  {
    name: 'OTHER',
    value: 6,
  },
  {
    name: 'SELF',
    value: 7,
  },
  {
    name: 'GUARANTOR',
    value: 8,
  },
  {
    name: 'CHILD',
    value: 9,
  },
  {
    name: 'EMPLOYEE',
    value: 10,
  },
  {
    name: 'UNKNOWN',
    value: 11,
  },
  {
    name: 'ORGANDONOR',
    value: 12,
  },
  {
    name: 'CADAVERDONOR',
    value: 13,
  },
  {
    name: 'LIFEPARTNER',
    value: 14,
  },
];

function PolicyHolderInfo(props) {
  let currentInfo = JSON.parse(localStorage.getItem('currentCoder'));
  const [mode, setMode] = useState('ViewMode');
  const [values, setValues] = useState({
    active: 1,
    id: 0,
    firstName: '',
    lastName: '',
    middleName: '',
    clinicId: 0,
    dob: '',
    patientId: '',
    phone: '',
    relation: 7,
    sex: 0,
    sdob: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    country: 'USA',
  });
  const [state, setState] = useState('');
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    zip: '',
    dob:''
  });

  // const mmddyyyyToDate = (dateString) => {
  //   let d = dateString.split("/");
  //   let day = parseInt(d[1]);
  //   let month = parseInt(d[[0]]) - 1;
  //   let year = parseInt(d[2]);
  //   let date = new Date(year, month, day);
  //   return date;
  // };

  function formatDateToShortYear(inputDate) {
    if (inputDate !== undefined) {
      const [day, month, year] = inputDate.split('/');
      const shortYear = year.slice(-2);
      return `${day}/${month}/${shortYear}`;
    }
  }

  const concatStrings = (first, second, third, fourth, fifth) => {
    let resultString = first ? first : '';
    if (second) resultString += ', ' + second;
    if (third) resultString += ', ' + third;
    if (fourth) resultString += ', ' + fourth;
    if (fifth) resultString += ', ' + fifth;
    return resultString;
  };

  const handleRelation = (event) => {
    if (event.target.value === 7) {
      setValues({
        ...values,
        firstName: props.patientInfo.firstName,
        middleName: props.patientInfo.middleName,
        lastName: props.patientInfo.lastName,
        addressLine1: props.patientInfo.addressLine1,
        addressLine2: props.patientInfo.addressLine2,
        city: props.patientInfo.city,
        state: props.patientInfo.state,
        zip: props.patientInfo.zip,
        country: props.patientInfo.country,
        sdob: props.patientInfo.sdob,
        dob: props.patientInfo.dob,
        relation: 7,
      });
      setState(props.patientInfo.state);
    } else {
      setValues({
        ...values,
        firstName: '',
        middleName: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        sdob: '',
        dob: '',
        relation: event.target.value,
      });
      setState('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mode === 'ViewMode') {
      setMode('EditMode');
    } else {
      let er = Object.keys(errors).find((x) => errors[x] !== '');
      if (er === undefined) {
        props.policyHolderSave({
          data: {
            ...values,
            id: props.policyHolderObject.id,
            clinicId: props.clinicId,
            // dob: dateToyyymmdd(values.sdob),
            dob: dateToyyymmdd(values.sdob) + 'T12:00:00.000Z',
            sdob: dateTommddyyy(values.sdob),
          },
          applicationId: props.applicationId,
        });
        // console.log(currentCoder)
      }
    }
  };
  useEffect(() => {
    if (props.coderData.HolderResponse) {
      let response = props.coderData.HolderResponse.response;
      if (response.responseCode === 0) {
        setMode('ViewMode');
        toast.success('Policy Holder updated Successfully');
        props.coderData.HolderResponse = null;
        let data = JSON.parse(localStorage.getItem('currentCoder'));
        props.getPatient({
          applicationId: props.applicationId,
          patientId: props.rowInfo.patientId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.HolderResponse]);
  const validateFirstName = (value) => {
    if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
      setErrors({ ...errors, firstName: 'Enter a valid Name' });
    } else {
      setErrors({ ...errors, firstName: '' });
    }
  };
  const validateLastName = (value) => {
    if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
      setErrors({ ...errors, lastName: 'Enter a valid Name' });
    } else {
      setErrors({ ...errors, lastName: '' });
    }
  };
  // const validateMiddleName = (value) => {
  //   if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value) && value !== "") {
  //     setErrors({ ...errors, middleName: "Enter a valid Name" });
  //   } else {
  //     setErrors({ ...errors, middleName: "" });
  //   }
  // };
  const validateCity = (value) => {
    if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
      setErrors({ ...errors, city: 'Enter a City' });
    } else {
      setErrors({ ...errors, city: '' });
    }
  };
  const validateAddress1 = (value) => {
    if (!/^[a-zA-Z-0-9 -'#&@,]+$/.test(value)) {
      setErrors({ ...errors, addressLine1: 'Enter a valid Address' });
    } else {
      setErrors({ ...errors, addressLine1: '' });
    }
  };
  const validateAddress2 = (value) => {
    if (!/^[a-zA-Z-0-9 -'#&@,]+$/.test(value) && value !== '') {
      setErrors({ ...errors, addressLine2: 'Enter a valid Address' });
    } else {
      setErrors({ ...errors, addressLine2: '' });
    }
  };
  const validateZip = (value) => {
    if (!/^[0-9]{5}(?:-[0-9]{4})?$/.test(value)) {
      setErrors({ ...errors, zip: 'Enter a valid Zip' });
    } else {
      setErrors({ ...errors, zip: '' });
    }
  };
  const validateDateOfBirth = (dob) => {
    const currentDate = new Date();
    const [month, day, year] = dob.split('/').map(Number);

    const inputDate = new Date(year, month - 1, day);

    if (
      !/^\d{2}\/\d{2}\/\d{4}$/.test(dob) ||
      inputDate > currentDate ||
      inputDate.getFullYear() !== year ||
      inputDate.getMonth() !== month - 1 ||
      inputDate.getDate() !== day
    ) {
      setErrors({ ...errors, dob: 'Enter a valid Date of Birth' });
    } else {
      setErrors({ ...errors, dob: '' });
    }
  };

  useEffect(() => {
    if (props.policyHolderObject) {
      setValues({
        ...values,
        ...props.policyHolderObject,
        sdob: props.policyHolderObject.sdob,
      });
      setState(props.policyHolderObject.state);
    } else {
      if (props.patientInfo) {
        setValues({
          ...values,
          firstName: props.patientInfo.firstName,
          middleName: props.patientInfo.middleName,
          lastName: props.patientInfo.lastName,
          addressLine1: props.patientInfo.addressLine1,
          addressLine2: props.patientInfo.addressLine2,
          city: props.patientInfo.city,
          state: props.patientInfo.state,
          zip: props.patientInfo.zip,
          country: props.patientInfo.country,
          sdob: props.patientInfo.sdob,
          relation: 7,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.policyHolderObject, props.patientInfo]);

  const handleCancel = () => {
    setMode('ViewMode');
  };

  const handleStateChange = (v) => {
    // console.log(v);
    setValues({ ...values, state: v.value });
  };

  return (
    <div>
      {mode === 'EditMode' && (
        <div className={'EditMode'}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Input
                  // className="input_material_field"
                  variant="outlined"
                  type="text"
                  label="First Name"
                  margin="normal"
                  onChange={(e) =>
                    setValues({ ...values, firstName: e.target.value })
                  }
                  value={values.firstName}
                  onBlur={(e) => validateFirstName(e.target.value)}
                  error={errors.firstName ? true : false}
                  helperText={errors.firstName}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  // className="input_material_field"
                  variant="outlined"
                  type="text"
                  label="Last Name"
                  margin="normal"
                  onChange={(e) =>
                    setValues({ ...values, lastName: e.target.value })
                  }
                  value={values.lastName}
                  onBlur={(e) => validateLastName(e.target.value)}
                  error={errors.lastName ? true : false}
                  helperText={errors.lastName}
                />
              </Grid>
              <Grid item xs={4}>
                <DobPicker
                  required
                  label="DOB"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      // sdob: dateTommddyyy(e.target.value),
                      sdob: e.target.value,
                      dob: e.target.value,
                    })
                  }
                  value={values.sdob}
                  maxDate={new Date()}
                  onBlur={(e) => validateDateOfBirth(e.target.value)}
                  error={errors.dob ? true : false}
                  helperText={errors.dob}
                />
              </Grid>
              <Grid item xs={3}>
                <Dropdown
                  options={relation}
                  label="Relation"
                  variant="outlined"
                  onChange={(e) => {
                    setValues({ ...values, relation: e.target.value });
                  }}
                  value={values.relation}
                  onClick={handleRelation}
                />
              </Grid>
              <Grid item xs={5}>
                <Input
                  // className="input_material_field"
                  variant="outlined"
                  type="text"
                  label="AddressLine 1"
                  margin="normal"
                  onChange={(e) =>
                    setValues({ ...values, addressLine1: e.target.value })
                  }
                  value={values.addressLine1}
                  onBlur={(e) => validateAddress1(e.target.value)}
                  error={errors.addressLine1 ? true : false}
                  helperText={errors.addressLine1}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  // className="input_material_field"
                  variant="outlined"
                  type="text"
                  label="AddressLine 2"
                  margin="normal"
                  onChange={(e) =>
                    setValues({ ...values, addressLine2: e.target.value })
                  }
                  value={values.addressLine2}
                  onBlur={(e) => validateAddress2(e.target.value)}
                  error={errors.addressLine2 ? true : false}
                  helperText={errors.addressLine2}
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  // className="input_material_field"
                  variant="outlined"
                  type="text"
                  label="City"
                  margin="normal"
                  onChange={(e) =>
                    setValues({ ...values, city: e.target.value })
                  }
                  value={values.city}
                  onBlur={(e) => validateCity(e.target.value)}
                  error={errors.city ? true : false}
                  helperText={errors.city}
                />
              </Grid>
              <Grid item xs={2}>
                <Dropdown
                  options={states}
                  label="State"
                  variant="outlined"
                  onChange={(e) =>
                    setValues({ ...values, state: e.target.value })
                  }
                  value={values.state}
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  // className="input_material_field"
                  variant="outlined"
                  type="text"
                  label="Zip"
                  margin="normal"
                  onChange={(e) =>
                    setValues({ ...values, zip: e.target.value })
                  }
                  value={values.zip}
                  onBlur={(e) => validateZip(e.target.value)}
                  error={errors.zip ? true : false}
                  helperText={errors.zip}
                />
              </Grid>
              <Grid item xs={2}>
                <Dropdown
                  options={[
                    { name: 'USA', value: 'US' },
                    { name: 'MEXICO', value: 'MEX' },
                  ]}
                  label="Country"
                  variant="outlined"
                  onChange={(e) =>
                    setValues({ ...values, country: e.target.value })
                  }
                  value={values.country ? values.country : 'US'}
                />
              </Grid>
              <Grid item xs={2}>
                {/* <div className="edit_btn_patientInfo">
                  <Button
                    style={{ marginTop: '10px' }}
                    onClick={handleCancel}
                    variant="contained"
                    color="primary"
                  >
                    Cancel
                  </Button>
                </div> */}
                <div className="edit_btn_group">
                  <Button
                    style={{ marginTop: '10px' }}
                    onClick={handleCancel}
                    variant="contained"
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <div className="sae_btn_patientInfo">
                    <Button
                      style={{ marginTop: '10px' }}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Grid>
              {/* <Grid item xs={1}>
                <div>
                  <Button
                    style={{ marginTop: '10px' }}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </Grid> */}
            </Grid>
          </form>
        </div>
      )}
      {mode === 'ViewMode' && (
        <div className={'ViewMode'}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div className="input_field_view_mode">
                  <span className="label">Policy Holder Name</span>
                  <div className="input_field_content_wrap">
                    <span className="input_field_content">
                      {values.firstName.charAt(0).toUpperCase() +
                        values.firstName.slice(1) +
                        ' ' +
                        values.middleName +
                        ' ' +
                        values.lastName}
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className="input_field_view_mode">
                  <span className="label">DOB</span>
                  <div className="input_field_content_wrap">
                    <span className="input_field_content">
                      {values.sdob ? dateTommddyyy(values.sdob) : null}
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className="input_field_view_mode">
                  <span className="label">Relation</span>
                  <div className="input_field_content_wrap">
                    <span className="input_field_content">
                      {
                        relation.find((rel) => rel.value === values.relation)
                          .name
                      }
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                {/* <div className="addressLine"> */}
                <div className="input_field_view_mode">
                  <span className="label">Patient Address</span>
                  <div className="input_field_content_wrap">
                    <span className="input_field_content">
                      {concatStrings(
                        values.addressLine1,
                        values.addressLine2,
                        values.city,
                        values.state,
                        values.zip
                      )}
                    </span>
                  </div>
                </div>
                {/* </div> */}
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{ marginTop: '10px' }}
                  className="edit_btn_patientInfo"
                >
                  <Button variant="outlined" color="primary" type="submit">
                    {mode === 'ViewMode' ? 'Edit' : 'Save'}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  policyHolderSave: (values) => dispatch(policyHolderSave(values)),
  getCoder: (values) => dispatch(getCoder(values)),
  getPatient: (values) => dispatch(getPatient(values)),
});
PolicyHolderInfo.propTypes = {
  policyHolderSave: PropTypes.func,
  getCoder: PropTypes.func,
  getPatient: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(PolicyHolderInfo);
