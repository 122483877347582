import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { payerSave } from '../../../../Redux/Appointment/apt.actions';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
// import Dropdown from '../../../../drop-down/src/index';
import { Dropdown } from '../../../../drop-down/src';
import PhoneFormat from '../../../../PhoneFormat';
import Input from '../../../../Input';
import Switch from '../../../../Switch';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { toast } from 'react-hot-toast';
import './style.css';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '600 !important',
    paddingBottom: '1rem !important',
  },
  cellinfo: {
    fontSize: '12px !important',
    padding: '12px !important',
    cursor: 'pointer',
  },
  cellhead: {
    fontSize: '14px !important',
    padding: '12px !important',
    color: '#808080 !important',
  },
}));

const types = [
  { name: 'Regular Insurance', value: 1 },
  { name: 'Workers Comp', value: 2 },
  { name: 'Personal Injury', value: 3 },
  { name: 'Captitated', value: 4 },
  { name: 'Other', value: 5 },
];

function PayerDialog(props) {
  const classes = useStyles();
  const [payorOpen, setPayorOpen] = useState(false);
  let currentRow = JSON.parse(sessionStorage.getItem('currentRow'));
  const [errors, setErrors] = useState({
    email: '',
    payerName: '',
    phone: '',
  });

  const [values, setValues] = useState({
    active: 1,
    alias: '',
    contactName: '',
    email: '',
    ext: '',
    fax: '',
    id: 0,
    masterPayerId: 0,
    payerName: '',
    payerType: '',
    phone: '',
  });

  const validatePayerName = (value) => {
    if (!/^[^-\s][A-Za-z ]*$/.test(value)) {
      setErrors({ ...errors, payerName: 'Enter a valid Payer Name' });
    } else {
      setErrors({ ...errors, payerName: '' });
    }
  };
  const validatePayerAlias = (value) => {
    if (!/^[^-\s][A-Za-z ]*$/.test(value)) {
      setErrors({ ...errors, alias: 'Enter a valid Alias Name' });
    } else {
      setErrors({ ...errors, alias: '' });
    }
  };
  const validatePhone = (value) => {
    if (!/^[ 0-9()-]*$/.test(value)) {
      setErrors({ ...errors, phone: 'Enter a valid Phone' });
    } else {
      setErrors({ ...errors, phone: '' });
    }
  };

  //   Calling save payer API
  const handleSubmit = (e) => {
    e.preventDefault();
    if (errors.email === '' && errors.payerName === '' && errors.phone === '') {
      props.payerSave({
        ...values,
        active: values.active ? 1 : 0,
        clinicId: currentRow.clinicId,
      });
    }
  };

  // Response of save payer
  useEffect(() => {
    if (props.AppointmentData.payerSaveResponse) {
      let response = props.AppointmentData.payerSaveResponse.response;
      if (response.responseCode === 0) {
        toast.success('Payor details added successfully');
        let data = response.data;
        props.savedPayerInfo({
          value: data.id,
          title: data.name,
        });
        props.AppointmentData.payerSaveResponse = null;
        props.onClose();
      }
      props.AppointmentData.payerSaveResponse = null;
    }
  }, [props.AppointmentData.payerSaveResponse]);

  useEffect(() => {
    setValues({
      active: 1,
      alias: '',
      contactName: '',
      email: '',
      ext: '',
      fax: '',
      id: 0,
      masterPayerId: 0,
      payerName: '',
      payerType: '',
      phone: '',
    });
  }, [props.open]);

  return (
    <div>
      {/* <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={payorOpen}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
        className="new_payer_dialog"
      > */}
      <form onSubmit={handleSubmit}>
        <div className="new_payer_container">
          <Typography className={classes.heading}>Create New Payor</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                values={values.payerName}
                onChange={(e) =>
                  setValues({ ...values, payerName: e.target.value })
                }
                onBlur={(e) => validatePayerName(e.target.value)}
                error={errors.payerName ? true : false}
                helperText={errors.payerName}
                variant="outlined"
                type="text"
                label="Payor Name"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                value={values.alias}
                onChange={(e) =>
                  setValues({ ...values, alias: e.target.value })
                }
                variant="outlined"
                type="text"
                label="Alias"
                onBlur={(e) => validatePayerAlias(e.target.value)}
                error={errors.alias ? true : false}
                helperText={errors.alias}
              />
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                required
                value={values.payerType}
                onChange={(e) =>
                  setValues({ ...values, payerType: e.target.value })
                }
                options={types}
                label="Type"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <PhoneFormat
                value={values.phone}
                onChange={(e) =>
                  setValues({ ...values, phone: e.target.value })
                }
                label="Phone"
                variant="outlined"
                onBlur={(e) => validatePhone(e.target.value)}
                error={errors.phone ? true : false}
                helperText={errors.phone}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                value={values.fax}
                onChange={(e) => setValues({ ...values, fax: e.target.value })}
                variant="outlined"
                type="text"
                label="Fax"
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                value={values.ext}
                onChange={(e) => setValues({ ...values, ext: e.target.value })}
                variant="outlined"
                type="text"
                label="Ext"
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                value={values.contactName}
                onChange={(e) =>
                  setValues({ ...values, contactName: e.target.value })
                }
                variant="outlined"
                type="text"
                label="Contact Name"
              />
            </Grid>
            {/* <Grid item xs={6}>
                <Input
                  value={values.email}
                  onChange={(e) =>
                    setValues({ ...values, email: e.target.value })
                  }
                  variant="outlined"
                  type="text"
                  label="Email"
                  required
                />
              </Grid> */}
            <Grid item xs={6}>
              <Switch
                value={values.active}
                label="Status"
                onChange={(e) =>
                  setValues({ ...values, active: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <div className="create_prior_auth_btn2">
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
                <Button
                  onClick={props.onClose}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </form>
      {/* </Dialog> */}
    </div>
  );
}
const mapStateToProps = (state) => ({
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  payerSave: (values) => dispatch(payerSave(values)),
});
PayerDialog.propTypes = {
  payerSave: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(PayerDialog);
