import React, { useEffect } from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import { Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import "./style.css";

function LogDialog(props) {
  const [log, setLog] = useState([]);

  useEffect(() => {
    if (props.priorAuthData.logList) {
      let response = props.priorAuthData.logList.response;
      if (response.responseCode === 0) {
        let lg = [];
        response.data.forEach((x) => {
          lg.push({
            note: x.note,
            date: x.date,
          });
        });
        setLog(lg.reverse());
        // console.log(lg);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.logList]);

  // useEffect(() => {
  //   if (props.priorAuthData.tableData) {
  //     let response = props.priorAuthData.tableData.response;
  //     if (response.responseCode === 0) {
  //       console.log(response.data.results);
  //       // setRows(response.data.results);
  //       let date=[]
  //       response.data.results.forEach((x)=>{
  //         date.push({
  //           date:x.appointmentTime
  //         })
  //       })
  //       setRows(date)
  //       console.log(date);
  //     }
  //   }
  // }, [props.priorAuthData.tableData]);

  // useEffect(() => {
  //   if (props.priorAuthData.priorAuthInfo) {
  //     let response = props.priorAuthData.priorAuthInfo.response;
  //     // let data = props.priorAuthData.priorAuthInfo.response.data;
  //     console.log(response);
  //   }},[props.priorAuthData.priorAuthInfo])

  return (
    <Dialog
      onClose={() => {
        setLog([]);
        props.handleClose();
      }}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      className="log_dialog"
    >
      <div className="log_content">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <div className="log_title_container">
              <p className="log_title">Log</p>
              <AssignmentIcon fontSize="small" color="primary" />
            </div>
          </Grid>
          <Grid item>
            <CloseIcon
              className="close_icon"
              onClick={() => {
                setLog([]);
                props.handleClose();
              }}
            />
          </Grid>
        </Grid>

        <div className="main_info">
          <div className="Patient_info">
            <div className="name_info">Patient Name :</div>
            <div className="name_info">{props.name}</div>
            <div className="name_info">, DOS :</div>
            <div className="name_info">{props.dos}</div>
          </div>
          {log.map((x) => (
            <div className="log_info">
              {/* <div className="date_div">{x.date.substring(0, 10)}</div>- */}
              <div className="text_div">{x.note}</div>
            </div>
          ))}
          {log.length === 0 &&
            [1, 2, 3, 4, 5, 3, 3].map((x) => (
              <div className="log_info_loder">
                <div className="load1">
                  {" "}
                  <Skeleton height={30} />{" "}
                </div>
                <div className="load2">
                  {" "}
                  <Skeleton height={30} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  priorAuthData: state.priorAuth,
});

const mapDispatchToProps = (dispatch) => ({});
LogDialog.propTypes = {};
export default connect(mapStateToProps, mapDispatchToProps)(LogDialog);
