import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { icdSave } from 'Redux/PriorAuthApis/prior.actions';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

function IcdDialog(props) {
  const rowData = JSON.parse(sessionStorage.getItem('currentRow'));
  const clinicId = rowData.clinicId;
  const [form, setForm] = useState({
    icdCode: '',
    icdDescLong: '',
    clinicId: 0,
    editable: true,
    icdDescShort: '',
    icdId: 0,
    icdVersion: 1,
    markAsDelete: 0,
  });

  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let clinicId = sessionStorage.getItem('clinicId');
    props.icdSave({ ...form, clinicId: clinicId });
  };

  useEffect(() => {
    if (props.icd) {
      const data = props.icd;
      setForm({
        icdCode: data.icdCode,
        icdDescLong: data.icdDescLong ? data.icdDescLong : '',
        clinicId: clinicId,
        editable: true,
        icdDescShort: data.icdDescShort ? data.icdDescShort : '',
        icdId: data.icdId,
        icdVersion: 1,
        markAsDelete: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.icd]);

  useEffect(() => {
    if (props.priorAuthData.icdResponse) {
      let Response = props.priorAuthData.icdResponse.response;
      if (Response.responseCode === 0) {
        toast.success('ICD saved');
      }
      if (Response.responseCode === 138) {
        toast.error('Clinic ICD Code already exists');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.icdResponse]);
  const closeDialog = () => {
    // setForm({
    //   icdCode: '',
    //   icdDescLong: '',
    //   clinicId: 0,
    //   editable: true,
    //   icdDescShort: '',
    //   icdId: 0,
    //   icdVersion: 1,
    //   markAsDelete: 0,
    // });
    props.handleClose();
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => closeDialog()}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <div style={{ padding: '1rem' }}>
              <p
                style={{
                  paddingBottom: '20px',
                  fontSize: '20px',
                  fontWeight: '500',
                }}
              >
                Add/Edit ICD
              </p>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    // className="input_material_field"
                    required
                    type="text"
                    label="Code"
                    margin="normal"
                    variant="outlined"
                    onChange={(e) =>
                      setForm({ ...form, icdCode: e.target.value })
                    }
                    value={form.icdCode}
                  />
                </Grid>
                {/* <Grid item xs={6}></Grid> */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    // className="input_material_field"
                    required
                    type="text"
                    label="Description"
                    margin="normal"
                    variant="outlined"
                    onChange={(e) =>
                      setForm({ ...form, icdDescLong: e.target.value })
                    }
                    value={form.icdDescLong}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  gap: '10px',
                }}
              >
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
                <Button
                  onClick={() => closeDialog()}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  priorAuthData: state.priorAuth,
});

const mapDispatchToProps = (dispatch) => ({
  icdSave: (values) => dispatch(icdSave(values)),
});
IcdDialog.propTypes = {
  icdSave: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(IcdDialog);
