import React from 'react';
import { Provider } from 'react-redux';
import store from '../../Redux/store';
import Upload from './lib/upload-list';

function app(props) {
  return (
    <Provider store={store}>
      <Upload rowInfo={props.rowInfo} applicationId={props.applicationId} />
    </Provider>
  );
}

export const UploadList = app;
