import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getCoder,
  fileDownload,
  getCoderFiles,
} from '../../../Redux/CoderApis/main.actions';
import PropTypes from 'prop-types';
import CloudUpload from '../../../assets/cloud.svg';
import CsvIcon from '../../../assets/csv-icon.svg';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDropzone } from 'react-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-hot-toast';
import { fileUpload } from './../../../Redux/CoderApis/main.actions';
// import '../../GlobalStyles.css';
import './style.css';
import { object } from 'dot-object';

const useStyles = makeStyles({
  dropzoneContainer: {
    margin: '16px 0',
  },
  h6: {
    fontSize: '1.2rem !important',
    textAlign: 'center',
  },
  fileListItem: {
    border: '1px solid #D7E0E9',
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    // gap: "1rem",
    borderRadius: '8px',
    paddingRight: '10px',
    // cursor: 'pointer',
  },
  fileListItemContainer: {
    display: 'flex',
    // justifyContent: "space-between",
    alignItems: 'center',
    margin: '16px 0',
    gap: '5px',
  },
  savedFiles: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0',
    gap: '5px',
  },
  buttonDiv: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingTop: '10px',
  },
  emrdiv: {
    width: '173px',
    marginRight: '10px',
  },
  iconContainer: {
    height: 110,
  },
  heading: {
    fontSize: '20px',
    fontWeight: '500 !important',
  },
});

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '35px',
  borderWidth: 2,
  borderColor: '#808080',
  borderStyle: 'dashed',
  backgroundColor: 'rgba(208, 239, 239, 0.27)',
  color: 'var(--color-grey-900)',
  fontWeight: '500',
  transition: 'border .3s ease-in-out',
  borderRadius: '6px',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: 'var(--Secondary-green)',
  backgroundColor: 'rgba(208, 239, 239, 0.8)',
};

const rejectStyle = {
  borderColor: 'rgba(255,85, 85, 0.7)',
  backgroundColor: 'rgba(255,0, 13, 0.03)',
};

function FileUploader(props) {
  const [status, setStatus] = useState('view');
  const [files, setFiles] = useState([]);
  const classes = useStyles();
  const currentRow = JSON.parse(sessionStorage.getItem('currentRow'));
  const clinicId = currentRow.clinicId;

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    // accept: { 'text/csv': ['.csv'] },
    minSize: 0,
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  function getFileExtension(fileName) {
    return fileName.split('.').pop();
  }

  const triggerUpload = () => {
    if (files.length > 0) {
      setStatus('uploading');
      const clinicInfo = props.rowInfo;
      const fileType = getFileExtension(files[0].name);
      const obj = {
        clinicId: clinicId,
        patientId: clinicInfo.patientId,
        fileName: files[0].name,
        fileType: fileType,
        claimId: props.rowInfo.claimId ? props.rowInfo.claimId : 0,
        appointmentId: props.rowInfo.appointmentId
          ? props.rowInfo.appointmentId
          : 0,
      };
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('fileDto', JSON.stringify(obj));
      props.fileUpload({ data: formData, applicationId: props.applicationId });
    }
  };

  useEffect(() => {
    setFiles([]);
    setStatus('view');
  }, [props.rowInfo]);

  useEffect(() => {
    if (props.coderData.fileUploadResponse) {
      const data = props.rowInfo;
      // const clinicId = sessionStorage.getItem('clinicId');
      let response = props.coderData.fileUploadResponse.response;
      if (response.responseCode === 0) {
        if (props.applicationId === 4 || props.applicationId === 6) {
          props.getCoderFiles({
            data: {
              patientId: data.patientId,
              clinicId: clinicId,
            },
            applicationId: props.applicationId,
          });
        }
        if (props.applicationId === 7 || props.applicationId === 8) {
          props.getCoderFiles({
            data: {
              patientId: data.patientId,
              clinicId: clinicId,
              claimId: data.claimId,
            },
            applicationId: props.applicationId,
          });
        }
        toast.success('File Upload Done!');
        props.coderData.fileUploadResponse = null;
        setStatus('view');
        setFiles([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.fileUploadResponse]);

  return (
    <div className={classes.dropzoneContainer}>
      <div className="upload_button">
        <Typography className={classes.heading}>Upload Files</Typography>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={triggerUpload}
        >
          Upload
        </Button>
      </div>
      <div className={classes.savedFiles}>
        {files.length > 0 &&
          files.map((x, i) => (
            <div key={i} className={classes.fileListItem}>
              <img
                src={CsvIcon}
                alt=""
                style={{ color: '#000000', cursor: 'pointer', height: '35px' }}
              />

              <Typography>{x.name}</Typography>
              <DeleteIcon onClick={() => setFiles([])} />
            </div>
          ))}
      </div>
      {status === 'view' && (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p> Drag and drop an image or click to upload files</p>
          <div className={classes.iconContainer}>
            <img
              src={CloudUpload}
              alt=""
              style={{ height: '100%', width: '100%' }}
            />
          </div>
          {!isDragActive && <div></div>}
          {isDragActive && !isDragReject && 'Drop the file here'}
          {isDragReject && 'File type not accepted! Please upload a .CSV file.'}
        </div>
      )}
      {status === 'uploading' && (
        <div className="upload_progression">
          <CircularProgress />
          <p>loading</p>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  fileUpload: (values) => dispatch(fileUpload(values)),
  getCoder: (values) => dispatch(getCoder(values)),
  getCoderFiles: (values) => dispatch(getCoderFiles(values)),
  fileDownload: (values) => dispatch(fileDownload(values)),
});
FileUploader.propTypes = {
  fileUpload: PropTypes.func,
  getCoder: PropTypes.func,
  getCoderFiles: PropTypes.func,
  fileDownload: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);
