import React from 'react';
import { Provider } from 'react-redux';
import store from '../../Redux/store';
import AppointmentPopup from './lib/index';

function app(props) {
  return (
    <Provider store={store}>
      <AppointmentPopup
        handleClose={props.handleClose}
        open={props.open}
        patientData={props.patientData}
        setPatientData={props.setPatientData}
        refreshHomeScreen={props.refreshHomeScreen}
        applicationId={props.applicationId}
      />
    </Provider>
  );
}

export const Appointment = app;
