import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  setFilter,
  bulkUpdate,
  priorAuthList,
} from 'Redux/PriorAuthApis/prior.actions';
import {
  getCache,
  exportData,
  exportDataResponse,
} from 'Redux/Appointment/apt.actions';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import DobPicker from 'Components/dobPicker';
import Dropdown from 'Components/Dropdown';
import MultipleSelect from 'Components/MultiDropdown';
import { useEffect } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import { dateToyyymmdd } from 'HelperFunctions/TimeDateTimestamp';
import DataTable from 'Components/DataTable';
import './styles.css';

/**
 * @fileOverview Manages Routing.
 * @component
 * @author Akhil Francis <akhil@tensaw.email>
 * @author Ridhik Govind <ridhik@tensaw.email>
 * @return {JSXElement}
 * @example
 * return(
 * <Header/>
 * )
 */

const DataTableFilter = (props) => {
  const [clinicList, setClinicList] = useState([]);
  const [provider, setProvider] = useState([]);
  const [status, setStatus] = useState([]);
  const [result, setResult] = useState([]);
  const [owner, setOwner] = useState([]);
  const [payorList, setPayorList] = useState([]);
  const [checkBoxDto, setCheckboxDto] = useState([]);
  const [forms, setForms] = useState({
    clinic: null,
    provider: null,
    status: [],
    result: [],
    startDate: new Date(),
    endDate: addDays(new Date(), 14),
    payorId: null,
    owner: [],
    dueDate: null,
    patientId: [],
  });
  const [actionForm, setActionForm] = useState({
    status: null,
    result: null,
    owner: null,
  });
  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('cache'));
    let clinicList = [];
    let status = [];
    let result = [];
    let owner = [];
    cache.clinicDtoList.forEach((x, i) => {
      if (i === 0) {
        clinicList.push({
          name: 'All Clinic',
          value: null,
        });
      }
      clinicList.push({
        name: x.name,
        value: x.clinicId,
      });
    });

    cache.priorAuthStatusDtoList.forEach((x, i) => {
      if (i === 0) {
        status.push({
          name: 'No Status',
          value: '0',
        });
      }
      status.push({
        name: x.description,
        value: parseInt(x.value),
      });
    });
    cache.priorAuthResultDtoList.forEach((x, i) => {
      if (i === 0) {
        result.push({
          name: 'No Result',
          value: '0',
        });
      }
      result.push({
        name: x.description,
        value: parseInt(x.value),
      });
    });
    cache.userDtoList.forEach((x, i) => {
      if (i === 0) {
        owner.push({
          name: 'Unassigned',
          value: '0',
        });
      }
      owner.push({
        name: x.fullName,
        value: x.userId,
      });
    });
    // Sort the owner array alphabetically by name
    owner.sort((a, b) => a.name.localeCompare(b.name));
    cache.payorList.forEach((x) => {
      payorList.push({
        name: x.payerName,
        value: x.id,
      });
    });
    setClinicList(clinicList);
    setStatus(status);
    setResult(result);
    setOwner(owner);
    console.log('owner', owner);
    setPayorList(payorList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.checkBoxArray && props.checkBoxArray.length > 0) {
      let a = [];
      props.checkBoxArray.forEach((x) => {
        if (x.isSelected === true) {
          a.push({
            appointmentId: x.appointmentId,
            owner: '',
            ownerId: 0,
            priorAuthId: x.priorAuthId,
            resultId: 0,
            statusId: 0,
          });
        }
      });
      setCheckboxDto(a);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checkBoxArray]);

  const handleCheckbox = () => {
    let a = [];
    checkBoxDto.forEach((x) => {
      a.push({
        appointmentId: x.appointmentId,
        owner: actionForm.owner
          ? owner.find((x) => x.value === actionForm.owner).name
          : '',
        ownerId: actionForm.owner,
        priorAuthId: x.priorAuthId,
        resultId: actionForm.result,
        statusId: actionForm.status,
      });
    });
    props.bulkUpdate(a);
    setActionForm('');
  };
  useEffect(() => {
    if (props.AppointmentData.cache && props.AppointmentData.cache.response) {
      let Response = props.AppointmentData.cache.response;
      if (Response.responseCode === 0) {
        let data = Response.data;
        let provider = [];
        data.providerDtoList.forEach((x, i) => {
          if (i === 0) {
            provider.push({
              name: 'All Providers',
              value: null,
            });
          }
          provider.push({
            name: x.fullName,
            value: x.id,
          });
        });
        if (forms.clinic) {
          setProvider(provider);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppointmentData.cache]);

  useEffect(() => {
    if (props.priorAuthData.filterForm) {
      setForms({
        ...props.priorAuthData.filterForm,
      });
      if (props.priorAuthData.filterForm.clinic) {
        props.getCache(props.priorAuthData.filterForm.clinic);
      }
      // console.log(props.priorAuthData.filterForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priorAuthData.filterForm]);

  const clinicChange = (value) => {
    setForms({ ...forms, clinic: value });
    if (value) {
      props.getCache(value);
    }
  };
  
  const filterFunction = () => {
    props.setFilter({
      ...forms,
      patientId: null,
      statusContainer: status,
      resultContainer: result,
      ownerContainer: owner,
    });
  };

  const downloadFiles = (content, fileName, contentType) => {
    if (!contentType) contentType = 'application/octet-stream';
    var a = document.createElement('a');
    var blob = new Blob([content], { type: 'application/vnd.ms-excel' });
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    blob = null;
  };

  useEffect(() => {
    if (props.AppointmentData.exportFile) {
      let blobData = props.AppointmentData.exportFile.response;
      let fileName = 'Priorauth_info';
      let fileType = '.jpeg';
      downloadFiles(blobData, fileName, fileType);
      props.exportDataResponse(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppointmentData.exportFile]);

  const exportFunction = () => {
    let f = forms;
    let statusFilter = [];
    let ownerFilter = [];
    let resultFilter = [];
    f.status.forEach((x) => {
      statusFilter.push(status.find((y) => y.name === x)['value']);
    });
    f.result.forEach((x) => {
      resultFilter.push(result.find((y) => y.name === x)['value']);
    });
    f.owner.forEach((x) => {
      ownerFilter.push(owner.find((y) => y.name === x)['value']);
    });
    props.exportData({
      startDate: dateToyyymmdd(f.startDate),
      endDate: dateToyyymmdd(f.endDate),
      dueDate: f.dueDate ? dateToyyymmdd(f.dueDate) : '',
      ownerIds: ownerFilter.toString(),
      providerId: f.provider ? f.provider : '',
      resultIds: f.result ? resultFilter.toString() : '',
      statusIds: f.status ? statusFilter.toString() : '',
      clinicId: f.clinic ? f.clinic : '',
      payorId: f.payorId ? f.payorId : '',
    });
  };

  const handleSubmit = () => {
    //
  };

  return (
    <div>
      <div className="filter_container">
        <div className="filter_block1">
          <div className="filter_field filter">
            <Dropdown
              tabIndex={3}
              options={[...clinicList]}
              label="Clinic"
              onChange={(e) => clinicChange(e.target.value)}
              value={forms.clinic}
            />
          </div>
          <div className="filter_field filter">
            <Dropdown
              tabIndex={3}
              options={[...provider]}
              label="Provider"
              onChange={(e) => setForms({ ...forms, provider: e.target.value })}
              value={forms.provider}
            />
          </div>
          <div className="filter_field ">
            <MultipleSelect
              tabIndex={3}
              options={status}
              label="Status"
              onChange={(e) => setForms({ ...forms, status: e.target.value })}
              value={forms.status}
            />
          </div>
          <div className="filter_field">
            <MultipleSelect
              tabIndex={3}
              options={result}
              label="Result"
              onChange={(e) => setForms({ ...forms, result: e.target.value })}
              value={forms.result}
            />
          </div>
          <div className="filter_field_date">
            <DobPicker
              label="DOS Start"
              inputVariant={'standard'}
              onChange={(e) =>
                setForms({ ...forms, startDate: e.target.value })
              }
              value={forms.startDate}
            />
          </div>
          <div className="filter_field_date">
            <DobPicker
              label="DOS End"
              inputVariant={'standard'}
              onChange={(e) => setForms({ ...forms, endDate: e.target.value })}
              value={forms.endDate}
              minDate={forms.startDate}
            />
          </div>
          <div className="filter_field filter">
            <Dropdown
              tabIndex={3}
              options={[{ name: 'All Payor', value: null }, ...payorList]}
              label="Payor"
              value={forms.payorId}
              onChange={(e) => setForms({ ...forms, payorId: e.target.value })}
            />
          </div>
          <div className="filter_field">
            <MultipleSelect
              tabIndex={3}
              options={owner}
              label="Owner"
              value={forms.owner}
              onChange={(e) => setForms({ ...forms, owner: e.target.value })}
            />
          </div>
          <div className="filter_field_date">
            <DobPicker
              label="Due Date"
              inputVariant={'standard'}
              onChange={(e) => setForms({ ...forms, dueDate: e.target.value })}
              value={forms.dueDate}
            />
          </div>
          <div className="filter_field_btn filter_btn">
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => filterFunction()}
              startIcon={<SearchIcon className="icon_filter" />}
            >
              FILTER
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<GetAppIcon className="icon_filter" />}
              onClick={() => exportFunction()}
            >
              EXPORT
            </Button>
          </div>
        </div>
        <div className="filter_block2" onSubmit={handleSubmit}>
          <div className="filter_field">
            <Dropdown
              tabIndex={3}
              options={[{ name: 'All Owner', value: null }, ...owner]}
              label="Owner"
              value={actionForm.owner}
              onChange={(e) =>
                setActionForm({ ...actionForm, owner: e.target.value })
              }
            />
          </div>
          <div className="filter_field">
            <Dropdown
              tabIndex={3}
              options={[{ name: 'All Status', value: null }, ...status]}
              label="Status"
              value={actionForm.status}
              onChange={(e) =>
                setActionForm({ ...actionForm, status: e.target.value })
              }
            />
          </div>
          <div className="filter_field">
            <Dropdown
              tabIndex={3}
              options={[{ name: 'All Result', value: null }, ...result]}
              label="Result"
              value={actionForm.result}
              onChange={(e) =>
                setActionForm({ ...actionForm, result: e.target.value })
              }
            />
          </div>
          <div className="filter_field_btn">
            <Button
              disabled={checkBoxDto.length > 0 ? false : true}
              variant="contained"
              color="primary"
              onClick={handleCheckbox}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  priorAuthData: state.priorAuth,
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (values) => dispatch(setFilter(values)),
  priorAuthList: (values) => dispatch(priorAuthList(values)),
  bulkUpdate: (values) => dispatch(bulkUpdate(values)),
  getCache: (values) => dispatch(getCache(values)),
  exportData: (values) => dispatch(exportData(values)),
  exportDataResponse: (values) => dispatch(exportDataResponse(values)),
});
DataTableFilter.propTypes = {
  setFilter: PropTypes.func,
  priorAuthList: PropTypes.func,
  bulkUpdate: PropTypes.func,
  getCache: PropTypes.func,
  exportData: PropTypes.func,
  exportDataResponse: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(DataTableFilter);
