import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { Skeleton } from '@material-ui/lab';

const PolicyHolderSkeleton = () => {
  return (
    <div>
      <div className={'ViewMode'}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Skeleton height={'50px'} variant="rect" />
            </Grid>
            <Grid item xs={2}>
              <Skeleton height={'50px'} variant="rect" />
            </Grid>
            <Grid item xs={2}>
              <Skeleton height={'50px'} variant="rect" />
            </Grid>
            <Grid item xs={3}>
              <Skeleton height={'50px'} variant="rect" />
            </Grid>
            <Grid item xs={2}>
              <div
                style={{ marginTop: '10px' }}
                className="edit_btn_patientInfo"
              >
                <Button variant="outlined" color="primary" type="submit">
                  Edit
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default PolicyHolderSkeleton;
