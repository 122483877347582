import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  icdkeywordSearch,
  icdChild,
  icdCodeSearch,
  icdSave,
} from '../../../Redux/CoderApis/main.actions';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NodataFound from '../../../assets/nodata-found.png';
import Dialog from '@material-ui/core/Dialog';

import {
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import './style.css';

import { toast } from 'react-hot-toast';
import Input from './../../../Input/index';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import Checkbox from '@material-ui/core/Checkbox';

function IcdKeyword(props) {
  const [searchParam, setSearchParam] = useState('');
  const [searchBy, setsearchBy] = useState('text');
  const [icdList, setIcdList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentList, setCurrentList] = useState([]);
  const [icdCount, setIcdCount] = useState(0);
  const [searchAttempts, setSearchAttempts] = useState(0);
  const currentRow = JSON.parse(sessionStorage.getItem('currentRow'));
  const clinicId = currentRow.clinicId;

  const handleSubmit = () => {
    setSearchParam('');
    props.handleTemp(currentList);
    props.handleClose();
  };

  const handleSubmitKeyword = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsLoading(true);
    props.icdkeywordSearch({
      data: searchParam,
      applicationId: props.applicationId,
      searchType: 'sLongDescription',
    });
    // }
  };

  useEffect(() => {
    if (searchBy === 'category') {
      setIcdList([]);
      props.icdChild({ data: 0, applicationId: props.applicationId });
    }
    if (searchBy === 'text') {
      setIcdList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy]);

  useEffect(() => {
    if (props.coderData.icdKeywordList) {
      let Response = props.coderData.icdKeywordList.response;
      if (Response.responseCode === 0) {
        if (Response.data.length === 0 && !searchAttempts) {
          setIsLoading(true);
          props.icdkeywordSearch({
            data: searchParam,
            applicationId: props.applicationId,
            searchType: 'sCode',
          });
          setSearchAttempts(1);
        } else {
          setSearchAttempts(0);
          setIsLoading(false);
          let data = Response.data;
          let data2 = [];
          data.forEach((x) => {
            data2.push({
              finalField: 1,
              name: `${x.icdCode}  -  ${
                x.icdDescShort ? x.icdDescShort : x.icdDescLong
              }`,
              icdCode: x.icdCode,
              icdDescLong: x.icdDescLong,
              icdDescShort: x.icdDescShort,
              icdId: x.icdId,
              rangeId: null,
              level: 0,
              checked: false,
            });
            data2.push({
              status: 0,
              finalField: 0,
              name: `${x.groupCode ? x.groupCode : x.icdCode} - ${
                x.groupDiscription ? x.groupDiscription : x.icdDescLong
              }`,
              icdCode: x.icdCode,
              icdDescLong: x.icdDescLong,
              icdDescShort: x.icdDescShort,
              icdId: x.icdId ? x.icdId : 0,
              rangeId: x.rangeId,
              level: 0,
            });
          });
          setIcdList(data2);
          markAsChecked(data2);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.icdKeywordList]);

  useEffect(() => {
    setIcdList([]);
  }, [props.open]);

  const handleTree = (data, index) => {
    if (data.rangeId) {
      setCurrentIndex(index);
      if (data.status === 0) {
        props.icdChild({
          data: data.rangeId,
          applicationId: props.applicationId,
        });
        let ar = icdList;
        ar[index].status = 1;
        setIcdList([...ar]);
      } else {
        let ar = [];
        icdList.forEach((x) => {
          if (data.rangeId !== x.parentId) {
            ar.push(x);
          }
        });
        ar[index].status = 0;
        setIcdList([...ar]);
      }
    }
  };

  useEffect(() => {
    if (props.coderData.icdChildResponse) {
      let Response = props.coderData.icdChildResponse.response;
      if (Response.responseCode === 0) {
        let data = Response.data;
        let icdCopy = icdList;
        let mergedArray = [];
        let i;
        for (i = 0; i <= currentIndex; i++) {
          if (icdCopy[i]) mergedArray.push(icdCopy[i]);
        }
        data.forEach((x, i) => {
          if (x.finalField === 1) {
            mergedArray.push({
              status: 0,
              finalField: x.finalField,
              name: `${x.startCode} - ${x.description}`,
              icdCode: x.startCode,
              icdDescLong: x.description,
              icdDescShort: x.description,
              icdId: x.icdId ? x.icdId : 0,
              rangeId: x.rangeId,
              parentId: x.parentId,
              level: x.level,
              checked: false,
            });
          } else {
            mergedArray.push({
              status: 0,
              finalField: x.finalField,
              name: `${x.startCode} - ${x.description}`,
              icdCode: x.startCode,
              icdDescLong: x.description,
              icdDescShort: x.description,
              icdId: x.icdId ? x.icdId : 0,
              rangeId: x.rangeId,
              parentId: x.parentId,
              level: x.level,
            });
          }
        });
        for (i = currentIndex + 1; i < icdCopy.length; i++) {
          mergedArray.push(icdCopy[i]);
        }
        setIcdList([...mergedArray]);
        markAsChecked([...mergedArray]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.icdChildResponse]);

  const setIcdPosition = () => {
    if (props.current >= 0) {
      setIcdCount(parseInt(props.current));
    }
  };

  useEffect(() => {
    setIcdPosition();
  }, [props.current]);

  const insertElement = (arr, element) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].icdCode === '') {
        arr[i] = element;
        setIcdCount(icdCount + 1);
        return arr;
      }
    }
    return arr;
  };

  const findAndReplace = (data, element) => {
    let icdCode = element.icdCode;
    data.forEach((item, index) => {
      if (item.icdCode === icdCode) {
        data[index] = {
          title: '',
          icdCode: '',
          icdId: 0,
          icdDescLong: '',
          index: index,
        };
      }
    });
    return data;
  };

  const checkDuplicateIcd = (list, element) => {
    const isCptCodePresent = list.some(
      (obj) => obj.icdCode === element.icdCode
    );
    return isCptCodePresent;
  };

  const markAsChecked = (list) => {
    list.forEach((x, i) => {
      currentList.forEach((icd) => {
        if (x.icdCode === icd.icdCode) {
          x.checked = true;
        }
      });
    });
    setIcdList(list);
  };

  const handleCheckBox = (e, data, index) => {
    let main = [];
    if (icdCount === 8) toast.error('Maximum number of ICD codes reached');
    if (e.target.checked === true) {
      icdList.forEach((x, i) => {
        if (index === i && icdCount <= 7) {
          const isCptCodePresent = checkDuplicateIcd(currentList, x);
          if (isCptCodePresent) {
            toast.error('ICD code already present');
            main.push({ ...x });
            return;
          }
          main.push({ ...x, checked: true });
          let arr = [...currentList];
          const newArr = insertElement(arr, x);
          setCurrentList(newArr);
        } else {
          main.push({ ...x });
        }
      });
      setIcdList([...main]);
    } else {
      if (icdCount > 0) {
        setIcdCount(icdCount - 1);
      }
      const list = [...currentList];
      icdList.forEach((x, i) => {
        if (index === i) {
          const updatedList = findAndReplace(list, x);
          setCurrentList(updatedList);
        }
      });
      main = icdList;
      main[index].checked = false;
      setIcdList([...main]);
    }
  };

  const handleIcdChipDelete = (icd, index) => {
    const updatedList = currentList.filter((_, i) => i !== index);
    updatedList.push({
      title: '',
      icdCode: '',
      icdId: 0,
      icdDescLong: '',
      index: index + 1,
    });
    setCurrentList(updatedList);
    icdList.forEach((item) => {
      if (item.icdCode === icd.icdCode && item.checked === true)
        item.checked = false;
    });
    if (icdCount > 0) setIcdCount(icdCount - 1);
  };

  const handleChange = (event) => {
    setsearchBy(event.target.value);
  };

  const handleExit = () => {
    setIcdPosition();
    setsearchBy('text');
  };

  const handleEnter = () => {
    const arr = props.icdArray.map((icd, index) => {
      return {
        ...icd,
        index: index + 1,
        isTemp: false,
      };
    });
    // .filter((icd) => icd.icdCode);
    setCurrentList([...arr]);
  };

  const onClear = () => {
    if (searchBy === 'text') {
      setSearchParam('');
      setIcdList([]);
    }
    if (searchBy === 'category') {
      icdList.forEach((x, index) => {
        if (x.checked === true) x.checked = false;
      });
    }
    let arr = Array(8)
      .fill()
      .map((_, index) => ({
        title: '',
        icdCode: '',
        icdId: 0,
        icdDescLong: '',
        index: index + 1,
      }));
    setCurrentList(arr);
    setIcdCount(1);
  };

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        onExit={handleExit}
        onEnter={handleEnter}
        open={props.open}
        onClose={() => {
          props.handleClose();
        }}
        aria-labelledby="form-dialog-title"
      >
        <div className="icd_search_wrap">
          <div className="header_container">
            <div className="header">
              <p
                style={{
                  paddingBottom: '20px',
                  fontSize: '22px',
                  fontWeight: '600',
                  marginTop: '20px',
                }}
              >
                ICD Search
              </p>
              <div style={{ marginRight: '10px', cursor: 'pointer' }}>
                <CloseIcon onClick={props.handleClose} fontSize="large" />
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid className="chipListContainer" item xs={8}>
                {props.icdArray && (
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      fontSize: '20px',
                      fontWeight: '500',
                    }}
                  >
                    Current List -
                  </span>
                )}
                <div className="chipList">
                  {currentList.map(
                    (icd, index) =>
                      icd?.icdCode && (
                        <Chip
                          color="primary"
                          className="icd_chip"
                          variant={
                            props.current === icd?.icdCode
                              ? 'default'
                              : 'outlined'
                          }
                          label={icd?.icdCode}
                          onDelete={() => handleIcdChipDelete(icd, index)}
                        />
                      )
                  )}
                </div>
              </Grid>
              <Grid item xs={4}>
                {/* {icdList.length > 1 && ( */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    gap: '10px',
                    alignItems: 'center',
                    marginRight: '30px',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  <Button onClick={onClear} variant="outlined" color="primary">
                    Clear
                  </Button>
                </div>
                {/* )} */}
              </Grid>
            </Grid>
          </div>
          {searchBy === 'text' && (
            <form onSubmit={handleSubmitKeyword}>
              <div style={{ padding: '0rem 2rem 2rem 2rem', height: '840px' }}>
                <div className="main_container">
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <div className="search_select_container">
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <FormControl color="primary" component="fieldset">
                              <RadioGroup
                                aria-label="searchby"
                                name="searchBy"
                                className={'radioButtons'}
                                value={searchBy}
                                onChange={handleChange}
                              >
                                <FormControlLabel
                                  value="category"
                                  control={<Radio color="primary" />}
                                  label="By Category"
                                />
                                <FormControlLabel
                                  value="text"
                                  color="primary"
                                  control={
                                    <Radio className="hey" color="primary" />
                                  }
                                  label="Text Search"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>

                    <Grid item xs={5}>
                      <div className="searchField">
                        <Input
                          fullWidth={true}
                          className="icd_search_bar"
                          value={searchParam}
                          label="Search using ICD code or description"
                          variant="standard"
                          onChange={(e) => setSearchParam(e.target.value)}
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton type="submit">
                                  <SearchIcon />
                                </IconButton>
                                <CloseIcon
                                  onClick={() => setSearchParam('')}
                                  style={{ cursor: 'pointer' }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {/* <Divider variant="middle" /> */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      gap: '10px',
                    }}
                  ></div>
                  <div className="result_container">
                    {icdList.length > 0 ? (
                      icdList?.map((x, i) => (
                        <div
                          className="TreeItem_conatianer"
                          style={
                            x?.level === 3
                              ? { paddingLeft: '10px' }
                              : x.level === 4
                              ? { paddingLeft: '15px' }
                              : x.level === 5
                              ? { paddingLeft: '25px' }
                              : x.level === 6
                              ? { paddingLeft: '35px' }
                              : null
                          }
                        >
                          {x.finalField === 1 && (
                            <Checkbox
                              checked={x.checked}
                              color="primary"
                              // disabled={icdCount === 8}
                              size="small"
                              onChange={(e) => {
                                handleCheckBox(e, x, i);
                              }}
                            />
                          )}
                          {x.finalField === 0 && x.status === 0 && (
                            <ChevronRightIcon
                              onClick={() => handleTree(x, i)}
                              style={{ marginLeft: '7px', cursor: 'pointer' }}
                            />
                          )}
                          {x.finalField === 0 && x.status === 1 && (
                            <ExpandMoreIcon
                              onClick={() => handleTree(x, i)}
                              style={{ marginLeft: '7px', cursor: 'pointer' }}
                            />
                          )}
                          <div
                            style={{ fontWeight: '500', width: '95%' }}
                            onClick={() => handleTree(x, i)}
                          >
                            <div
                              className={
                                x.finalField === 1 ? 'item' : 'finalField'
                              }
                            >
                              {x.name
                                .toLowerCase()
                                .split(' ')
                                .map((word, index) => (
                                  <span
                                    key={index}
                                    style={{
                                      color:
                                        word.toLowerCase() ===
                                        searchParam.toLowerCase()
                                          ? '#139696'
                                          : 'inherit',
                                      textTransform:
                                        index === 0 ? 'uppercase' : 'none',
                                    }}
                                  >
                                    {word}{' '}
                                  </span>
                                ))}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="no_data_found">
                        <img src={NodataFound} alt="No Data Found" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          )}
          {searchBy === 'category' && (
            <div className="category_main_container">
              <div className="main_container">
                <div
                  style={{ marginBottom: '16px' }}
                  className="search_select_container"
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          className={'radioButtons'}
                          value={searchBy}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="category"
                            control={<Radio color="primary" />}
                            label="By Category"
                            color="primary"
                          />
                          <FormControlLabel
                            value="text"
                            control={<Radio color="primary" />}
                            label="Text Search"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </div>

              <div
                style={{
                  padding: '0rem 2rem 2rem 0rem',
                  height: '840px',
                  color: 'black',
                  // position: 'absolute',
                }}
              >
                <div className="result_container">
                  {icdList ? (
                    icdList.map((x, i) => (
                      <div
                        className="TreeItem_conatianer"
                        style={
                          x.level === 2
                            ? { paddingLeft: '20px' }
                            : x.level === 3
                            ? { paddingLeft: '40px' }
                            : x.level === 4
                            ? { paddingLeft: '60px' }
                            : x.level === 5
                            ? { paddingLeft: '80px' }
                            : x.level === 6
                            ? { paddingLeft: '100px' }
                            : null
                        }
                      >
                        {x.finalField === 1 && (
                          <Checkbox
                            checked={x.checked}
                            color="primary"
                            size="small"
                            onChange={(e) => {
                              handleCheckBox(e, x, i);
                            }}
                          />
                        )}
                        {x.finalField === 0 && x.status === 0 && (
                          <ChevronRightIcon
                            onClick={() => handleTree(x, i)}
                            style={{ marginLeft: '7px', cursor: 'pointer' }}
                          />
                        )}
                        {x.finalField === 0 && x.status === 1 && (
                          <ExpandMoreIcon
                            onClick={() => handleTree(x, i)}
                            style={{ marginLeft: '7px', cursor: 'pointer' }}
                          />
                        )}
                        <p
                          style={{ fontWeight: '500' }}
                          onClick={() => handleTree(x, i)}
                        >
                          <div
                            className={
                              x.finalField === 1 ? 'item' : 'finalField'
                            }
                          >
                            <span className="item"> {x.name}</span>
                          </div>
                        </p>
                      </div>
                    ))
                  ) : (
                    <div className="no_data_found">
                      <img src={NodataFound} alt="No Data Found" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  icdkeywordSearch: (values) => dispatch(icdkeywordSearch(values)),
  icdCodeSearch: (values) => dispatch(icdCodeSearch(values)),
  icdChild: (values) => dispatch(icdChild(values)),
  icdSave: (values) => dispatch(icdSave(values)),
});
IcdKeyword.propTypes = {
  icdkeywordSearch: PropTypes.func,
  icdCodeSearch: PropTypes.func,
  icdChild: PropTypes.func,
  icdSave: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(IcdKeyword);
