import { combineEpics } from "redux-observable";
import PriorAuthEpic from "./PriorAuthApis/prior.epic";
import AppointmentEpic from "./Appointment/apt.epic";

/** RootEpic
 * @fileOverview Combine all the APIs calls w.r.t action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 */
const rootEpic = combineEpics(
  PriorAuthEpic.loginApi,
  PriorAuthEpic.priorAuthListApi,
  PriorAuthEpic.getPriorAuthApi,
  PriorAuthEpic.icdSearchApi,
  PriorAuthEpic.cptSearchApi,
  PriorAuthEpic.logInfoSaveApi,
  PriorAuthEpic.icdSaveApi,
  PriorAuthEpic.cptSaveApi,
  PriorAuthEpic.priorAuthSaveApi,
  PriorAuthEpic.loginInfoApi,
  PriorAuthEpic.fileUploadApi,
  PriorAuthEpic.downloadFileApi,
  PriorAuthEpic.logListApi,
  PriorAuthEpic.bulkUpdateApi,
  PriorAuthEpic.rowUpdateApi,
  PriorAuthEpic.icdKeywordSearchApi,
  PriorAuthEpic.cptDeleteApi,
  PriorAuthEpic.icdChildApi,
  PriorAuthEpic.paHistoryApi,
  PriorAuthEpic.searchPayorApi,
  PriorAuthEpic.patientSaveApi,
  PriorAuthEpic.policyHolderSaveApi,
  PriorAuthEpic.policySaveApi,
  PriorAuthEpic.mappingListApi,
  PriorAuthEpic.saveMappingApi,
  AppointmentEpic.searchPatientApi,
  AppointmentEpic.cacheApi,
  AppointmentEpic.AppointmentSaveApi,
  AppointmentEpic.payerSaveApi,
  AppointmentEpic.providerSaveApi,
  AppointmentEpic.providerListApi,
  AppointmentEpic.exportDownloadApi
 
);
export default rootEpic;
