import {
  BULK_UPDATE_RES,
  CPT_DELETE_RES,
  CPT_SAVE_RES,
  CPT_SEARCH_RES,
  DOWNLOAD_RES,
  FILE_UPLOAD_RES,
  GET_CODER_RES,
  ICD_CHILD_RES,
  ICD_KEYWORD_SEARCH_RES,
  ICD_SAVE_RES,
  ICD_SEARCH_RES,
  LOGIN_INFO_RES,
  LOGIN_RES,
  LOGLIST_RES,
  LOG_INFO_SAVE_RES,
  PATIENT_SAVE_RES,
  PA_HISTORY_RES,
  POLICYHOLDER_SAVE_RES,
  POLICY_SAVE_RES,
  CODER_LIST_RES,
  CODER_SAVE_RES,
  SEARCH_PAYOR_RES,
  SET_FILTER,
  LIST_RES,
  GET_PATIENT_RES,
  GET_PROVIDER_RES,
  GET_CLAIM_LIST_RES,
  CLAIM_DOWNLOAD_RES,
  GET_PROCEDURE_RES,
  GET_NOTES_RES,
  GET_APPT_NOTES_RES,
  GET_CODER_FILES_LIST_RES,
  GET_ELIGIBILITY_FILES_LIST_RES,
  GET_PRIORAUTH_FILES_LIST_RES,
  GET_PAYMENT_SUMMARY_RES,
  PROVIDER_SAVE_RES,
  SAVE_MAPPING_RES,
  MAPPING_LIST_RES,
  GET_CLAIM_SUMMARY_RES,
  ICD_CODE_SEARCH_RES,
  PRIORAUTH_LIST_RES,
  WRITEOFF_RES,
  GET_LEDGER_RES,
  ARMGMT_LIST_RES,
  ELIGIBILITY_LIST_RES,
  CPT_EDIT_RES,
  ICD_EDIT_RES,
  CLAIM_SUMMARY_SAVE_RES,
} from './main.types';
/**
 * @fileOverview Manages the response of action
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

const INITIAL_STATE = {
  loginResponse: null,
  listData: null,
  priorAuthList: null,
  arManagementList: null,
  eligibilityList: null,
  coderInfo: null,
  filterForm: {
    clinic: null,
    provider: null,
    status: null,
    result: null,
    startDate: null,
    endDate: null,
    owner: null,
  },
  icdSearchResponse: null,
  cptSearchResponse: null,
  cptEditResponse: null,
  logInfoResponse: null,
  icdResponse: null,
  cptSaveResponse: null,
  coderSaveResponse: null,
  cache: null,
  fileUploadResponse: null,
  downloadResponse: null,
  logList: null,
  bulkUpdateResponse: null,
  icdKeywordList: null,
  icdCodeSearchResponse: null,
  cptDeleteResponse: null,
  paHistory: null,
  payorlist: null,
  patientInfo: null,
  providerInfo: null,
  procedureInfo: null,
  claimList: null,
  claimFile: null,
  notes: null,
  files: null,
  paymentSUmmary: null,
  saveMappingResponse: null,
  mappingListResponse: null,
  claimSummary: null,
  writeOffInfo: null,
  ledgerInfo: null,
  claimPreview: null,
  editIcdResponse: null,
  claimSummarySave: null,
};

/**
 *
 * @param state - global state management
 * @param action - contains type and payload
 * @returns {{loginResponse: {}, login: boolean, status: boolean}|{loginResponse: *, login: boolean, status: boolean}|{loginResponse: {}, login: boolean, status: *}}
 */

const mainReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_RES: {
      return { ...state, ...{ loginResponse: action.payload } };
    }
    case LIST_RES: {
      return { ...state, ...{ listData: action.payload } };
    }
    case GET_CODER_RES: {
      return { ...state, ...{ coderInfo: action.payload } };
    }
    case PRIORAUTH_LIST_RES: {
      return { ...state, ...{ priorAuthList: action.payload } };
    }
    case ARMGMT_LIST_RES: {
      return { ...state, ...{ arManagementList: action.payload } };
    }
    case ELIGIBILITY_LIST_RES: {
      return { ...state, ...{ eligibilityList: action.payload } };
    }
    case GET_PATIENT_RES: {
      return { ...state, ...{ patientInfo: action.payload } };
    }
    case GET_PROVIDER_RES: {
      return { ...state, ...{ providerInfo: action.payload } };
    }
    case GET_PROCEDURE_RES: {
      return { ...state, ...{ procedureInfo: action.payload } };
    }
    case GET_NOTES_RES: {
      return { ...state, ...{ notes: action.payload } };
    }
    case WRITEOFF_RES: {
      return { ...state, ...{ writeOffInfo: action.payload } };
    }
    case GET_LEDGER_RES: {
      return { ...state, ...{ ledgerInfo: action.payload } };
    }
    case GET_CLAIM_SUMMARY_RES: {
      return { ...state, ...{ claimSummary: action.payload } };
    }
    case GET_APPT_NOTES_RES: {
      return { ...state, ...{ notes: action.payload } };
    }
    case GET_CODER_FILES_LIST_RES: {
      return { ...state, ...{ files: action.payload } };
    }
    case GET_ELIGIBILITY_FILES_LIST_RES: {
      return { ...state, ...{ files: action.payload } };
    }
    case GET_PRIORAUTH_FILES_LIST_RES: {
      return { ...state, ...{ files: action.payload } };
    }
    case SET_FILTER: {
      return { ...state, ...{ filterForm: action.payload } };
    }
    case ICD_SEARCH_RES: {
      return { ...state, ...{ icdSearchResponse: action.payload } };
    }
    case ICD_SAVE_RES: {
      return { ...state, ...{ icdResponse: action.payload } };
    }
    case CPT_SEARCH_RES: {
      return { ...state, ...{ cptSearchResponse: action.payload } };
    }
    case CPT_EDIT_RES: {
      return { ...state, ...{ cptEditResponse: action.payload } };
    }
    case LOG_INFO_SAVE_RES: {
      return { ...state, ...{ logInfoResponse: action.payload } };
    }
    case CPT_SAVE_RES: {
      return { ...state, ...{ cptSaveResponse: action.payload } };
    }
    case CODER_SAVE_RES: {
      return { ...state, ...{ coderSaveResponse: action.payload } };
    }
    case LOGIN_INFO_RES: {
      return { ...state, ...{ cache: action.payload } };
    }
    case FILE_UPLOAD_RES: {
      return { ...state, ...{ fileUploadResponse: action.payload } };
    }
    case DOWNLOAD_RES: {
      return { ...state, ...{ downloadResponse: action.payload } };
    }
    case LOGLIST_RES: {
      return { ...state, ...{ logList: action.payload } };
    }
    case BULK_UPDATE_RES: {
      return { ...state, ...{ bulkUpdateResponse: action.payload } };
    }
    case ICD_KEYWORD_SEARCH_RES: {
      return { ...state, ...{ icdKeywordList: action.payload } };
    }
    case ICD_CODE_SEARCH_RES: {
      return { ...state, ...{ icdCodeSearchResponse: action.payload } };
    }
    case CPT_DELETE_RES: {
      return { ...state, ...{ cptDeleteResponse: action.payload } };
    }
    case ICD_CHILD_RES: {
      return { ...state, ...{ icdChildResponse: action.payload } };
    }
    case PA_HISTORY_RES: {
      return { ...state, ...{ paHistory: action.payload } };
    }
    case SEARCH_PAYOR_RES: {
      return { ...state, ...{ payorlist: action.payload } };
    }
    case PATIENT_SAVE_RES: {
      return { ...state, ...{ patientSaveResponse: action.payload } };
    }
    case POLICYHOLDER_SAVE_RES: {
      return { ...state, ...{ HolderResponse: action.payload } };
    }
    case POLICY_SAVE_RES: {
      return { ...state, ...{ policySaveResponse: action.payload } };
    }
    case PROVIDER_SAVE_RES: {
      return { ...state, ...{ providerSaveResponse: action.payload } };
    }
    case GET_CLAIM_LIST_RES: {
      return { ...state, ...{ claimList: action.payload } };
    }
    case GET_PAYMENT_SUMMARY_RES: {
      return { ...state, ...{ paymentSummary: action.payload } };
    }
    case MAPPING_LIST_RES: {
      return { ...state, ...{ mappingListResponse: action.payload } };
    }
    case SAVE_MAPPING_RES: {
      return { ...state, ...{ saveMappingResponse: action.payload } };
    }
    case ICD_EDIT_RES: {
      return { ...state, ...{ editIcdResponse: action.payload } };
    }
    case CLAIM_SUMMARY_SAVE_RES: {
      return { ...state, ...{ claimSummarySave: action.payload } };
    }
    case CLAIM_DOWNLOAD_RES: {
      return {
        ...state,
        ...{ claimFile: action.payload },
      };
    }

    default:
      return state;
  }
};
export default mainReducer;
