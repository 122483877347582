import React from 'react';
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FileUploaderDropzone } from '../../../../../libs/file-uploader-drop-zone/src/index';
// import FileUploaderDropzone from "Components/FileUploaderDropzone/FileUploaderDropzone";
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
  },
}));

function UploadInformation(props) {
  const classes = useStyles();
  return (
    <div className="upload_main_div">
      <FileUploaderDropzone rowInfo={props.rowInfo} applicationId={4} />
    </div>
  );
}

export default UploadInformation;
