import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect(props) {
  const handleInput = (event) => {
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div>
      {/* <FormControl>
        <InputLabel id="demo-mutiple-name-label">{props.label}</InputLabel>
        <Select
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"
          multiple
          value={props.value}
          onChange={handleInput}
          input={<Input />}
          MenuProps={MenuProps}
        >
          {props.options &&
            props.options.map((x) => (
              <MenuItem key={x.value} value={x.value}>
                {x.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl> */}

      <FormControl>
        <InputLabel id="demo-mutiple-checkbox-label">{props.label}</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={props.value}
          onChange={handleInput}
          input={<Input />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {props.options &&
            props.options.map((x) => (
              <MenuItem key={x.name} value={x.name}>
                <Checkbox checked={props.value.indexOf(x.name) > -1} />
                <ListItemText primary={x.name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
