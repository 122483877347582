import React from 'react';
import { Provider } from 'react-redux';
import store from '../../Redux/store';
import { SearchPatient } from './lib/patient-search.jsx';

function app(props) {
  return (
    <Provider store={store}>
      <SearchPatient searchSelect={props.searchSelect} />
    </Provider>
  );
}

export const PatientSearch = app;
