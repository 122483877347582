import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from '../../Redux/store';
import IcdKeyword from './lib/index';

function App(props) {
  return (
    <Provider store={store}>
      <IcdKeyword
        open={props.open}
        rowInfo={props.rowInfo}
        current={props.current}
        handleClose={props.handleClose}
        keywordList={props.keywordList}
        icdArray={props.icdArray}
        handleDeleteIcd={props.handleDeleteIcd}
        applicationId={props.applicationId}
        handleTemp={props.handleTempSelectedCodes}
      />
    </Provider>
  );
}

export const IcdKeywordDialog = App;
