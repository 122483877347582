import { combineEpics } from 'redux-observable';
import MainEpic from './CoderApis/main.epic';
// import AppointmentEpic
import AppointmentEpic from './Appointment/apt.epic';

/** RootEpic
 * @fileOverview Combine all the APIs calls w.r.t action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 */
const rootEpic = combineEpics(
  MainEpic.loginApi,
  MainEpic.coderListApi,
  MainEpic.priorAuthListApi,
  MainEpic.getCoderApi,
  MainEpic.getPatientApi,
  MainEpic.getProviderApi,
  MainEpic.icdSearchApi,
  MainEpic.cptSearchApi,
  MainEpic.icdEditApi,
  MainEpic.logInfoSaveApi,
  MainEpic.icdSaveApi,
  MainEpic.cptSaveApi,
  MainEpic.procedureInfoSaveApi,
  MainEpic.loginInfoApi,
  MainEpic.cptEditApi,
  MainEpic.getLedgerApi,
  MainEpic.fileUploadApi,
  MainEpic.eligibilityListApi,
  MainEpic.writeOffApi,
  MainEpic.downloadFileApi,
  MainEpic.arManagementListApi,
  MainEpic.logListApi,
  MainEpic.bulkUpdateApi,
  MainEpic.claimPreviewApi,
  MainEpic.rowUpdateApi,
  MainEpic.icdKeywordSearchApi,
  MainEpic.icdCodeSearchApi,
  MainEpic.cptDeleteApi,
  MainEpic.icdChildApi,
  MainEpic.paHistoryApi,
  MainEpic.searchPayorApi,
  MainEpic.patientSaveApi,
  MainEpic.policyHolderSaveApi,
  MainEpic.policySaveApi,
  MainEpic.getClaimListApi,
  MainEpic.getClaimSummaryApi,
  MainEpic.claimDownloadApi,
  MainEpic.getProcedureInfoApi,
  MainEpic.getNotesListApi,
  MainEpic.getApptNotesListApi,
  MainEpic.getCoderFilesListApi,
  MainEpic.saveClaimSummaryApi,
  MainEpic.getPaymentSummaryApi,
  MainEpic.providerSave,
  MainEpic.mappingListApi,
  MainEpic.saveMappingApi,
  AppointmentEpic.searchPatientApi,
  AppointmentEpic.cacheApi,
  AppointmentEpic.AppointmentSaveApi,
  AppointmentEpic.payerSaveApi,
  AppointmentEpic.getProviderListApi
);
export default rootEpic;
