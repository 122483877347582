import {
  BULK_UPDATE,
  BULK_UPDATE_RES,
  LOG_INFO_SAVE,
  LOG_INFO_SAVE_RES,
  CPT_SAVE,
  CPT_SAVE_RES,
  CPT_SEARCH,
  CPT_SEARCH_RES,
  DOWNLOAD,
  DOWNLOAD_RES,
  FILE_UPLOAD,
  FILE_UPLOAD_RES,
  GET_CODER,
  GET_CODER_RES,
  ICD_SAVE,
  ICD_SAVE_RES,
  ICD_SEARCH,
  ICD_SEARCH_RES,
  LOGIN,
  LOGIN_INFO,
  LOGIN_INFO_RES,
  LOGIN_RES,
  LOGLIST,
  LOGLIST_RES,
  CODER_LIST,
  CODER_LIST_RES,
  CODER_SAVE,
  CODER_SAVE_RES,
  ROW_UPDATE,
  ROW_UPDATE_RES,
  SET_FILTER,
  ICD_KEYWORD_SEARCH,
  ICD_KEYWORD_SEARCH_RES,
  CPT_DELETE,
  CPT_DELETE_RES,
  ICD_CHILD,
  ICD_CHILD_RES,
  PA_HISTORY,
  PA_HISTORY_RES,
  SEARCH_PAYOR,
  SEARCH_PAYOR_RES,
  PATIENT_SAVE,
  PATIENT_SAVE_RES,
  POLICYHOLDER_SAVE,
  POLICYHOLDER_SAVE_RES,
  POLICY_SAVE,
  POLICY_SAVE_RES,
  LIST,
  LIST_RES,
  GET_PATIENT,
  GET_PATIENT_RES,
  GET_PROVIDER,
  GET_PROVIDER_RES,
  GET_CLAIM_LIST,
  GET_CLAIM_LIST_RES,
  CLAIM_DOWNLOAD,
  CLAIM_DOWNLOAD_RES,
  GET_PROCEDURE,
  GET_PROCEDURE_RES,
  GET_NOTES,
  GET_NOTES_RES,
  GET_APPT_NOTES,
  GET_APPT_NOTES_RES,
  GET_CODER_FILES_LIST,
  GET_CODER_FILES_LIST_RES,
  GET_ELIGIBILITY_FILES_LIST,
  GET_ELIGIBILITY_FILES_LIST_RES,
  GET_PRIORAUTH_FILES_LIST,
  GET_PRIORAUTH_FILES_LIST_RES,
  GET_PAYMENT_SUMMARY,
  GET_PAYMENT_SUMMARY_RES,
  PROVIDER_SAVE,
  PROVIDER_SAVE_RES,
  MAPPING_LIST,
  MAPPING_LIST_RES,
  SAVE_MAPPING,
  SAVE_MAPPING_RES,
  GET_CLAIM_SUMMARY,
  GET_CLAIM_SUMMARY_RES,
  ICD_CODE_SEARCH,
  ICD_CODE_SEARCH_RES,
  PRIORAUTH_LIST,
  PRIORAUTH_LIST_RES,
  WRITEOFF,
  WRITEOFF_RES,
  GET_LEDGER,
  GET_LEDGER_RES,
  ARMGMT_LIST,
  ARMGMT_LIST_RES,
  ELIGIBILITY_LIST,
  ELIGIBILITY_LIST_RES,
  CLAIM_PREVIEW,
  CLAIM_PREVIEW_RES,
  CPT_EDIT,
  CPT_EDIT_RES,
  ICD_EDIT,
  ICD_EDIT_RES,
  CLAIM_SUMMARY_SAVE,
  CLAIM_SUMMARY_SAVE_RES,
} from './main.types';

/**
 * @fileOverview Manages the action w.r.t types in the redux
 * @author Akhil Francis<akhil@trillium-technologies.com>

 * return(
 type and payload
 * )
 * @example export const actionName = (params) => ({
  type: Type of the action from login.type.js,
  payload: object - contains params,
})
 */

/**
 * clinicSave-Action w.r.t CLINIC_SAVE(type) in the redux
 * @description - Action used to call the clinic save API
 * @param logData
 * @returns {{payload: *, type: string}}
 */
export const login = (data) => ({
  type: LOGIN,
  payload: data,
});
/**
 * clinicSaveResponse - Action w.r.t CLINIC_SAVE_RES(type) in the redux
 * @description - Action used to get the response of clinic save API
 * @param user
 * @returns {{payload: *, type: string}}
 */
export const loginResponse = (res) => ({
  type: LOGIN_RES,
  payload: res,
});
export const coderList = (data) => ({
  type: LIST,
  payload: data,
});
export const coderListResponse = (data) => ({
  type: LIST_RES,
  payload: data,
});
export const getCoder = (data) => ({
  type: GET_CODER,
  payload: data,
});
export const getCoderResponse = (data) => ({
  type: GET_CODER_RES,
  payload: data,
});
export const getPatient = (data) => ({
  type: GET_PATIENT,
  payload: data,
});
export const getPatientResponse = (data) => ({
  type: GET_PATIENT_RES,
  payload: data,
});
export const getProcedureInfo = (data) => ({
  type: GET_PROCEDURE,
  payload: data,
});
export const getProcedureInfoResponse = (data) => ({
  type: GET_PROCEDURE_RES,
  payload: data,
});
export const getClaimSummary = (data) => ({
  type: GET_CLAIM_SUMMARY,
  payload: data,
});
export const getClaimSummaryResponse = (data) => ({
  type: GET_CLAIM_SUMMARY_RES,
  payload: data,
});
export const getNotes = (data) => ({
  type: GET_NOTES,
  payload: data,
});
export const getNotesResponse = (data) => ({
  type: GET_NOTES_RES,
  payload: data,
});
export const getApptNotes = (data) => ({
  type: GET_APPT_NOTES,
  payload: data,
});
export const getApptNotesResponse = (data) => ({
  type: GET_APPT_NOTES_RES,
  payload: data,
});
export const getProvider = (data) => ({
  type: GET_PROVIDER,
  payload: data,
});
export const getProviderResponse = (data) => ({
  type: GET_PROVIDER_RES,
  payload: data,
});
export const setFilter = (data) => ({
  type: SET_FILTER,
  payload: data,
});

export const icdSearch = (data) => ({
  type: ICD_SEARCH,
  payload: data,
});
export const icdSearchResponse = (data) => ({
  type: ICD_SEARCH_RES,
  payload: data,
});
export const cptSearch = (data) => ({
  type: CPT_SEARCH,
  payload: data,
});
export const cptSearchResponse = (data) => ({
  type: CPT_SEARCH_RES,
  payload: data,
});
export const icdSave = (data) => ({
  type: ICD_SAVE,
  payload: data,
});
export const icdSaveResponse = (data) => ({
  type: ICD_SAVE_RES,
  payload: data,
});
export const logInfoSave = (data) => ({
  type: LOG_INFO_SAVE,
  payload: data,
});
export const logInfoSaveResponse = (data) => ({
  type: LOG_INFO_SAVE_RES,
  payload: data,
});
export const cptSave = (data) => ({
  type: CPT_SAVE,
  payload: data,
});
export const cptSaveResponse = (data) => ({
  type: CPT_SAVE_RES,
  payload: data,
});
export const cptEdit = (data) => ({
  type: CPT_EDIT,
  payload: data,
});
export const cptEditResponse = (data) => ({
  type: CPT_EDIT_RES,
  payload: data,
});

export const procedureInfoSave = (data) => ({
  type: CODER_SAVE,
  payload: data,
});
export const procedureInfoSaveRes = (data) => ({
  type: CODER_SAVE_RES,
  payload: data,
});
export const loginInfo = (data) => ({
  type: LOGIN_INFO,
  payload: data,
});
export const loginInfoResponse = (data) => ({
  type: LOGIN_INFO_RES,
  payload: data,
});
export const fileUpload = (data) => ({
  type: FILE_UPLOAD,
  payload: data,
});
export const fileUploadResponse = (data) => ({
  type: FILE_UPLOAD_RES,
  payload: data,
});
export const fileDownload = (data) => ({
  type: DOWNLOAD,
  payload: data,
});
export const fileDownloadResponse = (data) => ({
  type: DOWNLOAD_RES,
  payload: data,
});
export const logList = (data) => ({
  type: LOGLIST,
  payload: data,
});
export const loglistResponse = (data) => ({
  type: LOGLIST_RES,
  payload: data,
});
export const bulkUpdate = (data) => ({
  type: BULK_UPDATE,
  payload: data,
});
export const bulkUpdateResponse = (data) => ({
  type: BULK_UPDATE_RES,
  payload: data,
});
export const rowUpdate = (data) => ({
  type: ROW_UPDATE,
  payload: data,
});
export const rowUpdateResponse = (data) => ({
  type: ROW_UPDATE_RES,
  payload: data,
});
export const icdkeywordSearch = (data) => ({
  type: ICD_KEYWORD_SEARCH,
  payload: data,
});
export const icdkeywordResponse = (data) => ({
  type: ICD_KEYWORD_SEARCH_RES,
  payload: data,
});
export const icdCodeSearch = (data) => ({
  type: ICD_CODE_SEARCH,
  payload: data,
});
export const icdCodeResponse = (data) => ({
  type: ICD_CODE_SEARCH_RES,
  payload: data,
});
export const cptDelete = (data) => ({
  type: CPT_DELETE,
  payload: data,
});
export const cptDeleteResponse = (data) => ({
  type: CPT_DELETE_RES,
  payload: data,
});
export const icdChild = (data) => ({
  type: ICD_CHILD,
  payload: data,
});
export const icdChildResponse = (data) => ({
  type: ICD_CHILD_RES,
  payload: data,
});
export const paHistory = (data) => ({
  type: PA_HISTORY,
  payload: data,
});
export const paHistoryRes = (data) => ({
  type: PA_HISTORY_RES,
  payload: data,
});
export const searchPayor = (data) => ({
  type: SEARCH_PAYOR,
  payload: data,
});
export const searchPayoRes = (data) => ({
  type: SEARCH_PAYOR_RES,
  payload: data,
});
export const patientSave = (data) => ({
  type: PATIENT_SAVE,
  payload: data,
});
export const patientSaveRes = (data) => ({
  type: PATIENT_SAVE_RES,
  payload: data,
});
export const policyHolderSave = (data) => ({
  type: POLICYHOLDER_SAVE,
  payload: data,
});
export const policyHolderSaveRes = (data) => ({
  type: POLICYHOLDER_SAVE_RES,
  payload: data,
});
export const policySave = (data) => ({
  type: POLICY_SAVE,
  payload: data,
});
export const policySaveRes = (data) => ({
  type: POLICY_SAVE_RES,
  payload: data,
});
export const getClaimList = (data) => ({
  type: GET_CLAIM_LIST,
  payload: data,
});
export const getClaimListResponse = (data) => ({
  type: GET_CLAIM_LIST_RES,
  payload: data,
});
export const claimDownload = (value) => ({
  type: CLAIM_DOWNLOAD,
  payload: value,
});
export const claimDownloadRes = (value) => ({
  type: CLAIM_DOWNLOAD_RES,
  payload: value,
});
export const getCoderFiles = (value) => ({
  type: GET_CODER_FILES_LIST,
  payload: value,
});
export const getCoderFilesResponse = (value) => ({
  type: GET_CODER_FILES_LIST_RES,
  payload: value,
});
export const getEligibilityFiles = (value) => ({
  type: GET_ELIGIBILITY_FILES_LIST,
  payload: value,
});
export const getEligiblityFilesResponse = (value) => ({
  type: GET_ELIGIBILITY_FILES_LIST_RES,
  payload: value,
});
export const getPriorAuthFiles = (value) => ({
  type: GET_PRIORAUTH_FILES_LIST,
  payload: value,
});
export const getPriorAuthFilesResponse = (value) => ({
  type: GET_PRIORAUTH_FILES_LIST_RES,
  payload: value,
});
export const getPaymentSummary = (value) => ({
  type: GET_PAYMENT_SUMMARY,
  payload: value,
});
export const getPaymentSummaryResponse = (value) => ({
  type: GET_PAYMENT_SUMMARY_RES,
  payload: value,
});
export const providerSave = (value) => ({
  type: PROVIDER_SAVE,
  payload: value,
});
export const providerSaveResponse = (value) => ({
  type: PROVIDER_SAVE_RES,
  payload: value,
});
export const mappingList = (data) => ({
  type: MAPPING_LIST,
  payload: data,
});
export const mappingListRes = (data) => ({
  type: MAPPING_LIST_RES,
  payload: data,
});
export const saveMapping = (data) => ({
  type: SAVE_MAPPING,
  payload: data,
});
export const saveMappingRes = (data) => ({
  type: SAVE_MAPPING_RES,
  payload: data,
});
export const priorAuthList = (data) => ({
  type: PRIORAUTH_LIST,
  payload: data,
});
export const priorAuthListResponse = (data) => ({
  type: PRIORAUTH_LIST_RES,
  payload: data,
});
export const arManagementList = (data) => ({
  type: ARMGMT_LIST,
  payload: data,
});
export const arManagementListResponse = (data) => ({
  type: ARMGMT_LIST_RES,
  payload: data,
});
export const eligibilityList = (data) => ({
  type: ELIGIBILITY_LIST,
  payload: data,
});
export const eligibilityListResponse = (data) => ({
  type: ELIGIBILITY_LIST_RES,
  payload: data,
});
export const writeOff = (data) => ({
  type: WRITEOFF,
  payload: data,
});
export const writeOffResponse = (data) => ({
  type: WRITEOFF_RES,
  payload: data,
});
export const getLedger = (data) => ({
  type: GET_LEDGER,
  payload: data,
});
export const getLedgerResponse = (data) => ({
  type: GET_LEDGER_RES,
  payload: data,
});
export const getClaimPreview = (data) => ({
  type: CLAIM_PREVIEW,
  payload: data,
});
export const getClaimPreviewResponse = (data) => ({
  type: CLAIM_PREVIEW_RES,
  payload: data,
});
export const editIcd = (data) => ({
  type: ICD_EDIT,
  payload: data,
});
export const editIcdResponse = (data) => ({
  type: ICD_EDIT_RES,
  payload: data,
});
export const claimSummarySave = (data) => ({
  type: CLAIM_SUMMARY_SAVE,
  payload: data,
});
export const claimSummarySaveResponse = (data) => ({
  type: CLAIM_SUMMARY_SAVE_RES,
  payload: data,
});
