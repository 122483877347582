import React from 'react';
import Payment from './lib';
import { Provider } from 'react-redux';
import store from '../../Redux/store';
import Context from '../../context/AppContextProvider';
import { ContextProvider } from '../../context/contextProvider';

function app(props) {
  return (
    <Provider store={store}>
      <Context components={[ContextProvider]}>
        <Payment rowInfo={props.rowInfo} applicationId={props.applicationId} />
      </Context>
    </Provider>
  );
}

export const PaymentSummary = app;
